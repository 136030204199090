import { Grid, List } from '@zitcha/component-library';
import React, { ReactNode } from 'react';

export const Column = ({ children, bordered }: { children: ReactNode; bordered?: boolean }) => {
  return (
    <Grid item xs height={240} borderLeft={bordered ? '1px solid #E0E0E0' : 'none'}>
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          minWidth: 360,
          bgcolor: 'background.paper',
          overflow: 'auto',
          maxHeight: 240,
          '& ul': { padding: 0 },
        }}
      >
        {children}
      </List>
    </Grid>
  );
};
