import React from 'react';
import { Modal, Box, Typography, Button } from '@zitcha/component-library';
import { Notification } from './ProcessingContext';

export const ModalNotification: React.FC<{
  notification: Notification;
  onClose: () => void;
}> = ({ notification, onClose }) => {
  const notificationTitle =
    notification.title || notification.type.charAt(0).toUpperCase() + notification.type.slice(1);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => {};
  return (
    <Modal open={true} size='small' onClose={notification.dismissable ? onClose : noop} title={notificationTitle}>
      <Box display='flex' flexDirection='column' justifyContent='start' gap={1} mt={2} minHeight={100}>
        <Typography variant='body1' sx={{ mt: 2, whiteSpace: 'pre-line' }}>
          {notification.message}
        </Typography>
        {notification.dismissable && (
          <Button onClick={onClose} sx={{ mt: 2 }}>
            Close
          </Button>
        )}
      </Box>
    </Modal>
  );
};
