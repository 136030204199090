import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Box, TextField, EditIcon, DoneIcon, CloseIcon } from '@zitcha/component-library';

interface EditableFieldProps {
  initialValue: string;
  onChange?: (value: string) => void;
  editable?: boolean;
}

export const EditableField = ({ initialValue, onChange, editable = false }: EditableFieldProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>(initialValue);

  const handleSave = () => {
    if (onChange) {
      onChange(newValue);
    }
    setEditMode(false);
  };
  const handleCancel = () => {
    setNewValue(initialValue);
    setEditMode(false);
  };
  const enableEdit = () => {
    if (editable) setEditMode(true);
  };

  useEffect(() => {
    setNewValue(initialValue);
  }, [initialValue]);

  return (
    <Box display='flex'>
      {!editMode ? (
        <div onClick={enableEdit} data-testid='edit-parent'>
          <Typography variant='h4' data-testid='edit-value'>
            {newValue || 'Untitled'}
            <IconButton
              color='default'
              ariaLabel='edit'
              onClick={enableEdit}
              sx={{ marginLeft: '5px', visibility: editable ? 'visible' : 'hidden' }}
            >
              <EditIcon />
            </IconButton>
          </Typography>
        </div>
      ) : (
        <Box display='flex' alignItems='center' flexDirection='row'>
          <TextField
            variant='standard'
            size='medium'
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            inputProps={{ 'data-testid': 'editable-field' }}
            sx={{ '& input': { fontSize: '2.2rem' } }}
          />
          <IconButton onClick={handleSave} color='success' ariaLabel='save edit'>
            <DoneIcon />
          </IconButton>
          <IconButton onClick={handleCancel} color='error' ariaLabel='cancel edit'>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
