/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Zitcha Access Management API
 * This is the API endpoint to manage access.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  GetOrderPermissionsParams,
  GetOrdersParams,
  GetPermissionSetsParams,
  GetPlanPermissionsParams,
  GetPlansParams,
  GetProductSetsParams,
  GetRolesParams,
  GetScopedOrganisationsParams,
  GetScopedProductSetsParams,
  GetTeamsParams,
  GetUsersParams,
  RequestInviteUser,
  RequestRole,
  RequestSearchRoles,
  RequestSearchTeams,
  RequestSearchUsers,
  RequestTeamCreate,
  RequestTeamUpdate,
  RequestUpdateUser,
  Response400BadRequestResponse,
  Response403ForbiddenResponse,
  Response404NotFoundResponse,
  ResponseOrderList,
  ResponseOrganisationList,
  ResponsePermissionSetList,
  ResponsePermissions,
  ResponsePlanList,
  ResponseProductSetList,
  ResponseRoleItem,
  ResponseRoleList,
  ResponseScopedProductSetList,
  ResponseTeam,
  ResponseTeamItem,
  ResponseTeamList,
  ResponseTeamSimpleList,
  ResponseUser,
  ResponseUserInvite,
  ResponseUserList,
  TeamsListScopedParams,
} from '../model';
import { customInstance } from '../../../axios/axiosMainInstance';
import type { ErrorType, BodyType } from '../../../axios/axiosMainInstance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get a list of Teams
 */
export const getTeams = (
  params?: GetTeamsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponseTeamList>(
    { url: `/access-management/v1/teams`, method: 'GET', params, signal },
    options
  );
};

export const getGetTeamsQueryKey = (params?: GetTeamsParams) => {
  return [`/access-management/v1/teams`, ...(params ? [params] : [])] as const;
};

export const getGetTeamsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTeams>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetTeamsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTeamsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeams>>> = ({ signal }) =>
    getTeams(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTeams>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getTeams>>>;
export type GetTeamsQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Get a list of Teams
 */
export const useGetTeams = <
  TData = Awaited<ReturnType<typeof getTeams>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetTeamsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTeamsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create a Team
 */
export const createTeam = (
  requestTeamCreate: BodyType<RequestTeamCreate>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ResponseTeamItem>(
    {
      url: `/access-management/v1/teams`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestTeamCreate,
    },
    options
  );
};

export const getCreateTeamMutationOptions = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTeam>>,
    TError,
    { data: BodyType<RequestTeamCreate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTeam>>,
  TError,
  { data: BodyType<RequestTeamCreate> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTeam>>, { data: BodyType<RequestTeamCreate> }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createTeam(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTeamMutationResult = NonNullable<Awaited<ReturnType<typeof createTeam>>>;
export type CreateTeamMutationBody = BodyType<RequestTeamCreate>;
export type CreateTeamMutationError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse>;

/**
 * @summary Create a Team
 */
export const useCreateTeam = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTeam>>,
    TError,
    { data: BodyType<RequestTeamCreate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTeam>>,
  TError,
  { data: BodyType<RequestTeamCreate> },
  TContext
> => {
  const mutationOptions = getCreateTeamMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search Teams
 */
export const searchTeams = (
  requestSearchTeams: BodyType<RequestSearchTeams>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ResponseTeamList>(
    {
      url: `/access-management/v1/teams/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestSearchTeams,
    },
    options
  );
};

export const getSearchTeamsQueryKey = (requestSearchTeams: RequestSearchTeams) => {
  return [`/access-management/v1/teams/search`, requestSearchTeams] as const;
};

export const getSearchTeamsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchTeams>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  requestSearchTeams: RequestSearchTeams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchTeams>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchTeamsQueryKey(requestSearchTeams);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchTeams>>> = ({ signal }) =>
    searchTeams(requestSearchTeams, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchTeams>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof searchTeams>>>;
export type SearchTeamsQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Search Teams
 */
export const useSearchTeams = <
  TData = Awaited<ReturnType<typeof searchTeams>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  requestSearchTeams: RequestSearchTeams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchTeams>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchTeamsQueryOptions(requestSearchTeams, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get list of scoped teams
 */
export const teamsListScoped = (
  params?: TeamsListScopedParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponseTeamSimpleList>(
    { url: `/access-management/v1/teams/scoped`, method: 'GET', params, signal },
    options
  );
};

export const getTeamsListScopedQueryKey = (params?: TeamsListScopedParams) => {
  return [`/access-management/v1/teams/scoped`, ...(params ? [params] : [])] as const;
};

export const getTeamsListScopedQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsListScoped>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: TeamsListScopedParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsListScoped>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamsListScopedQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamsListScoped>>> = ({ signal }) =>
    teamsListScoped(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsListScoped>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsListScopedQueryResult = NonNullable<Awaited<ReturnType<typeof teamsListScoped>>>;
export type TeamsListScopedQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Get list of scoped teams
 */
export const useTeamsListScoped = <
  TData = Awaited<ReturnType<typeof teamsListScoped>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: TeamsListScopedParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof teamsListScoped>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsListScopedQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a Team by ID
 */
export const getTeam = (teamId: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<ResponseTeamItem>(
    { url: `/access-management/v1/teams/${teamId}`, method: 'GET', signal },
    options
  );
};

export const getGetTeamQueryKey = (teamId: number) => {
  return [`/access-management/v1/teams/${teamId}`] as const;
};

export const getGetTeamQueryOptions = <
  TData = Awaited<ReturnType<typeof getTeam>>,
  TError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>,
>(
  teamId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTeamQueryKey(teamId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeam>>> = ({ signal }) =>
    getTeam(teamId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!teamId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTeam>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTeamQueryResult = NonNullable<Awaited<ReturnType<typeof getTeam>>>;
export type GetTeamQueryError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>;

/**
 * @summary Get a Team by ID
 */
export const useGetTeam = <
  TData = Awaited<ReturnType<typeof getTeam>>,
  TError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>,
>(
  teamId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTeam>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTeamQueryOptions(teamId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete a Team
 */
export const deleteTeam = (teamId: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/access-management/v1/teams/${teamId}`, method: 'DELETE' }, options);
};

export const getDeleteTeamMutationOptions = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteTeam>>, TError, { teamId: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteTeam>>, TError, { teamId: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTeam>>, { teamId: number }> = (props) => {
    const { teamId } = props ?? {};

    return deleteTeam(teamId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTeamMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTeam>>>;

export type DeleteTeamMutationError = ErrorType<
  Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse
>;

/**
 * @summary Delete a Team
 */
export const useDeleteTeam = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteTeam>>, TError, { teamId: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteTeam>>, TError, { teamId: number }, TContext> => {
  const mutationOptions = getDeleteTeamMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update a Team
 */
export const updateTeam = (
  teamId: number,
  requestTeamUpdate: BodyType<RequestTeamUpdate>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ResponseTeam>(
    {
      url: `/access-management/v1/teams/${teamId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: requestTeamUpdate,
    },
    options
  );
};

export const getUpdateTeamMutationOptions = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTeam>>,
    TError,
    { teamId: number; data: BodyType<RequestTeamUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTeam>>,
  TError,
  { teamId: number; data: BodyType<RequestTeamUpdate> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTeam>>,
    { teamId: number; data: BodyType<RequestTeamUpdate> }
  > = (props) => {
    const { teamId, data } = props ?? {};

    return updateTeam(teamId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTeamMutationResult = NonNullable<Awaited<ReturnType<typeof updateTeam>>>;
export type UpdateTeamMutationBody = BodyType<RequestTeamUpdate>;
export type UpdateTeamMutationError = ErrorType<
  Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse
>;

/**
 * @summary Update a Team
 */
export const useUpdateTeam = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTeam>>,
    TError,
    { teamId: number; data: BodyType<RequestTeamUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTeam>>,
  TError,
  { teamId: number; data: BodyType<RequestTeamUpdate> },
  TContext
> => {
  const mutationOptions = getUpdateTeamMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get a list of Roles
 */
export const getRoles = (
  params?: GetRolesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponseRoleList>(
    { url: `/access-management/v1/roles`, method: 'GET', params, signal },
    options
  );
};

export const getGetRolesQueryKey = (params?: GetRolesParams) => {
  return [`/access-management/v1/roles`, ...(params ? [params] : [])] as const;
};

export const getGetRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof getRoles>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetRolesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRoles>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRolesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoles>>> = ({ signal }) =>
    getRoles(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getRoles>>>;
export type GetRolesQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Get a list of Roles
 */
export const useGetRoles = <
  TData = Awaited<ReturnType<typeof getRoles>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetRolesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRoles>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRolesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create a Role
 */
export const createRole = (requestRole: BodyType<RequestRole>, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<ResponseRoleItem>(
    {
      url: `/access-management/v1/roles`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestRole,
    },
    options
  );
};

export const getCreateRoleMutationOptions = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createRole>>,
    TError,
    { data: BodyType<RequestRole> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof createRole>>, TError, { data: BodyType<RequestRole> }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRole>>, { data: BodyType<RequestRole> }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createRole(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateRoleMutationResult = NonNullable<Awaited<ReturnType<typeof createRole>>>;
export type CreateRoleMutationBody = BodyType<RequestRole>;
export type CreateRoleMutationError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse>;

/**
 * @summary Create a Role
 */
export const useCreateRole = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createRole>>,
    TError,
    { data: BodyType<RequestRole> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof createRole>>, TError, { data: BodyType<RequestRole> }, TContext> => {
  const mutationOptions = getCreateRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search Roles
 */
export const searchRoles = (
  requestSearchRoles: BodyType<RequestSearchRoles>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ResponseRoleList>(
    {
      url: `/access-management/v1/roles/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestSearchRoles,
    },
    options
  );
};

export const getSearchRolesQueryKey = (requestSearchRoles: RequestSearchRoles) => {
  return [`/access-management/v1/roles/search`, requestSearchRoles] as const;
};

export const getSearchRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchRoles>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  requestSearchRoles: RequestSearchRoles,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchRoles>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchRolesQueryKey(requestSearchRoles);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchRoles>>> = ({ signal }) =>
    searchRoles(requestSearchRoles, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchRolesQueryResult = NonNullable<Awaited<ReturnType<typeof searchRoles>>>;
export type SearchRolesQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Search Roles
 */
export const useSearchRoles = <
  TData = Awaited<ReturnType<typeof searchRoles>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  requestSearchRoles: RequestSearchRoles,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchRoles>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchRolesQueryOptions(requestSearchRoles, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a Role by ID
 */
export const getRole = (roleId: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<ResponseRoleItem>(
    { url: `/access-management/v1/roles/${roleId}`, method: 'GET', signal },
    options
  );
};

export const getGetRoleQueryKey = (roleId: number) => {
  return [`/access-management/v1/roles/${roleId}`] as const;
};

export const getGetRoleQueryOptions = <
  TData = Awaited<ReturnType<typeof getRole>>,
  TError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>,
>(
  roleId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRole>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRoleQueryKey(roleId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRole>>> = ({ signal }) =>
    getRole(roleId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!roleId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRole>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRoleQueryResult = NonNullable<Awaited<ReturnType<typeof getRole>>>;
export type GetRoleQueryError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>;

/**
 * @summary Get a Role by ID
 */
export const useGetRole = <
  TData = Awaited<ReturnType<typeof getRole>>,
  TError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>,
>(
  roleId: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRole>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRoleQueryOptions(roleId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update a Role
 */
export const updateRole = (
  roleId: number,
  requestRole: BodyType<RequestRole>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ResponseRoleItem>(
    {
      url: `/access-management/v1/roles/${roleId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: requestRole,
    },
    options
  );
};

export const getUpdateRoleMutationOptions = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRole>>,
    TError,
    { roleId: number; data: BodyType<RequestRole> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateRole>>,
  TError,
  { roleId: number; data: BodyType<RequestRole> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateRole>>,
    { roleId: number; data: BodyType<RequestRole> }
  > = (props) => {
    const { roleId, data } = props ?? {};

    return updateRole(roleId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateRoleMutationResult = NonNullable<Awaited<ReturnType<typeof updateRole>>>;
export type UpdateRoleMutationBody = BodyType<RequestRole>;
export type UpdateRoleMutationError = ErrorType<
  Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse
>;

/**
 * @summary Update a Role
 */
export const useUpdateRole = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRole>>,
    TError,
    { roleId: number; data: BodyType<RequestRole> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateRole>>,
  TError,
  { roleId: number; data: BodyType<RequestRole> },
  TContext
> => {
  const mutationOptions = getUpdateRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete a Role
 */
export const deleteRole = (roleId: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/access-management/v1/roles/${roleId}`, method: 'DELETE' }, options);
};

export const getDeleteRoleMutationOptions = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteRole>>, TError, { roleId: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteRole>>, TError, { roleId: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRole>>, { roleId: number }> = (props) => {
    const { roleId } = props ?? {};

    return deleteRole(roleId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteRoleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRole>>>;

export type DeleteRoleMutationError = ErrorType<
  Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse
>;

/**
 * @summary Delete a Role
 */
export const useDeleteRole = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteRole>>, TError, { roleId: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteRole>>, TError, { roleId: number }, TContext> => {
  const mutationOptions = getDeleteRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get List of Users
 */
export const getUsers = (
  params?: GetUsersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponseUserList>(
    { url: `/access-management/v1/users`, method: 'GET', params, signal },
    options
  );
};

export const getGetUsersQueryKey = (params?: GetUsersParams) => {
  return [`/access-management/v1/users`, ...(params ? [params] : [])] as const;
};

export const getGetUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetUsersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) =>
    getUsers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>;
export type GetUsersQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Get List of Users
 */
export const useGetUsers = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetUsersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Invite a User
 */
export const inviteUser = (
  requestInviteUser: BodyType<RequestInviteUser>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ResponseUserInvite>(
    {
      url: `/access-management/v1/users`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestInviteUser,
    },
    options
  );
};

export const getInviteUserMutationOptions = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteUser>>,
    TError,
    { data: BodyType<RequestInviteUser> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inviteUser>>,
  TError,
  { data: BodyType<RequestInviteUser> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof inviteUser>>, { data: BodyType<RequestInviteUser> }> = (
    props
  ) => {
    const { data } = props ?? {};

    return inviteUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InviteUserMutationResult = NonNullable<Awaited<ReturnType<typeof inviteUser>>>;
export type InviteUserMutationBody = BodyType<RequestInviteUser>;
export type InviteUserMutationError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse>;

/**
 * @summary Invite a User
 */
export const useInviteUser = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteUser>>,
    TError,
    { data: BodyType<RequestInviteUser> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof inviteUser>>,
  TError,
  { data: BodyType<RequestInviteUser> },
  TContext
> => {
  const mutationOptions = getInviteUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search Users
 */
export const searchUsers = (
  requestSearchUsers: BodyType<RequestSearchUsers>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ResponseUserList>(
    {
      url: `/access-management/v1/users/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestSearchUsers,
    },
    options
  );
};

export const getSearchUsersQueryKey = (requestSearchUsers: RequestSearchUsers) => {
  return [`/access-management/v1/users/search`, requestSearchUsers] as const;
};

export const getSearchUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof searchUsers>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  requestSearchUsers: RequestSearchUsers,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsers>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchUsersQueryKey(requestSearchUsers);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchUsers>>> = ({ signal }) =>
    searchUsers(requestSearchUsers, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchUsersQueryResult = NonNullable<Awaited<ReturnType<typeof searchUsers>>>;
export type SearchUsersQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Search Users
 */
export const useSearchUsers = <
  TData = Awaited<ReturnType<typeof searchUsers>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  requestSearchUsers: RequestSearchUsers,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsers>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchUsersQueryOptions(requestSearchUsers, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a User by ID
 */
export const getUser = (userId: string, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<ResponseUser>({ url: `/access-management/v1/users/${userId}`, method: 'GET', signal }, options);
};

export const getGetUserQueryKey = (userId: string) => {
  return [`/access-management/v1/users/${userId}`] as const;
};

export const getGetUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>,
>(
  userId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) =>
    getUser(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>;
export type GetUserQueryError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>;

/**
 * @summary Get a User by ID
 */
export const useGetUser = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>,
>(
  userId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update a User
 */
export const updateUser = (
  userId: string,
  requestUpdateUser: BodyType<RequestUpdateUser>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ResponseUser>(
    {
      url: `/access-management/v1/users/${userId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateUser,
    },
    options
  );
};

export const getUpdateUserMutationOptions = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { userId: string; data: BodyType<RequestUpdateUser> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { userId: string; data: BodyType<RequestUpdateUser> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { userId: string; data: BodyType<RequestUpdateUser> }
  > = (props) => {
    const { userId, data } = props ?? {};

    return updateUser(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>;
export type UpdateUserMutationBody = BodyType<RequestUpdateUser>;
export type UpdateUserMutationError = ErrorType<
  Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse
>;

/**
 * @summary Update a User
 */
export const useUpdateUser = <
  TError = ErrorType<Response400BadRequestResponse | Response403ForbiddenResponse | Response404NotFoundResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { userId: string; data: BodyType<RequestUpdateUser> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { userId: string; data: BodyType<RequestUpdateUser> },
  TContext
> => {
  const mutationOptions = getUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get a list of Product Sets
 */
export const getProductSets = (
  params?: GetProductSetsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponseProductSetList>(
    { url: `/access-management/v1/product-sets`, method: 'GET', params, signal },
    options
  );
};

export const getGetProductSetsQueryKey = (params?: GetProductSetsParams) => {
  return [`/access-management/v1/product-sets`, ...(params ? [params] : [])] as const;
};

export const getGetProductSetsQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductSets>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetProductSetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductSets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductSetsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductSets>>> = ({ signal }) =>
    getProductSets(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProductSets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductSetsQueryResult = NonNullable<Awaited<ReturnType<typeof getProductSets>>>;
export type GetProductSetsQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Get a list of Product Sets
 */
export const useGetProductSets = <
  TData = Awaited<ReturnType<typeof getProductSets>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetProductSetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductSets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductSetsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a list of scoped Product Sets
 */
export const getScopedProductSets = (
  params: GetScopedProductSetsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponseScopedProductSetList>(
    { url: `/access-management/v1/product-sets/scoped`, method: 'GET', params, signal },
    options
  );
};

export const getGetScopedProductSetsQueryKey = (params: GetScopedProductSetsParams) => {
  return [`/access-management/v1/product-sets/scoped`, ...(params ? [params] : [])] as const;
};

export const getGetScopedProductSetsQueryOptions = <
  TData = Awaited<ReturnType<typeof getScopedProductSets>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params: GetScopedProductSetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScopedProductSets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetScopedProductSetsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getScopedProductSets>>> = ({ signal }) =>
    getScopedProductSets(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getScopedProductSets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetScopedProductSetsQueryResult = NonNullable<Awaited<ReturnType<typeof getScopedProductSets>>>;
export type GetScopedProductSetsQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Get a list of scoped Product Sets
 */
export const useGetScopedProductSets = <
  TData = Awaited<ReturnType<typeof getScopedProductSets>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params: GetScopedProductSetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScopedProductSets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetScopedProductSetsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a list of Permission Sets
 */
export const getPermissionSets = (
  params?: GetPermissionSetsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponsePermissionSetList>(
    { url: `/access-management/v1/permission-sets`, method: 'GET', params, signal },
    options
  );
};

export const getGetPermissionSetsQueryKey = (params?: GetPermissionSetsParams) => {
  return [`/access-management/v1/permission-sets`, ...(params ? [params] : [])] as const;
};

export const getGetPermissionSetsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPermissionSets>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetPermissionSetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPermissionSets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPermissionSetsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermissionSets>>> = ({ signal }) =>
    getPermissionSets(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPermissionSets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPermissionSetsQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissionSets>>>;
export type GetPermissionSetsQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Get a list of Permission Sets
 */
export const useGetPermissionSets = <
  TData = Awaited<ReturnType<typeof getPermissionSets>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetPermissionSetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPermissionSets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPermissionSetsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get list of scoped Organisations
 */
export const getScopedOrganisations = (
  params?: GetScopedOrganisationsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponseOrganisationList>(
    { url: `/access-management/v1/organisations/scoped`, method: 'GET', params, signal },
    options
  );
};

export const getGetScopedOrganisationsQueryKey = (params?: GetScopedOrganisationsParams) => {
  return [`/access-management/v1/organisations/scoped`, ...(params ? [params] : [])] as const;
};

export const getGetScopedOrganisationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getScopedOrganisations>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetScopedOrganisationsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScopedOrganisations>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetScopedOrganisationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getScopedOrganisations>>> = ({ signal }) =>
    getScopedOrganisations(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getScopedOrganisations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetScopedOrganisationsQueryResult = NonNullable<Awaited<ReturnType<typeof getScopedOrganisations>>>;
export type GetScopedOrganisationsQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Get list of scoped Organisations
 */
export const useGetScopedOrganisations = <
  TData = Awaited<ReturnType<typeof getScopedOrganisations>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetScopedOrganisationsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScopedOrganisations>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetScopedOrganisationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a list of Orders
 */
export const getOrders = (
  params?: GetOrdersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponseOrderList>(
    { url: `/access-management/v1/orders`, method: 'GET', params, signal },
    options
  );
};

export const getGetOrdersQueryKey = (params?: GetOrdersParams) => {
  return [`/access-management/v1/orders`, ...(params ? [params] : [])] as const;
};

export const getGetOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrders>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetOrdersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrders>>> = ({ signal }) =>
    getOrders(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getOrders>>>;
export type GetOrdersQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Get a list of Orders
 */
export const useGetOrders = <
  TData = Awaited<ReturnType<typeof getOrders>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetOrdersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrdersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a list of Permissions for an Order
 */
export const getOrderPermissions = (
  params: GetOrderPermissionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponsePermissions>(
    { url: `/access-management/v1/orders/permissions`, method: 'GET', params, signal },
    options
  );
};

export const getGetOrderPermissionsQueryKey = (params: GetOrderPermissionsParams) => {
  return [`/access-management/v1/orders/permissions`, ...(params ? [params] : [])] as const;
};

export const getGetOrderPermissionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrderPermissions>>,
  TError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>,
>(
  params: GetOrderPermissionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderPermissions>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrderPermissionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderPermissions>>> = ({ signal }) =>
    getOrderPermissions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderPermissions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrderPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrderPermissions>>>;
export type GetOrderPermissionsQueryError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>;

/**
 * @summary Get a list of Permissions for an Order
 */
export const useGetOrderPermissions = <
  TData = Awaited<ReturnType<typeof getOrderPermissions>>,
  TError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>,
>(
  params: GetOrderPermissionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderPermissions>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrderPermissionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a list of Plans
 */
export const getPlans = (
  params?: GetPlansParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponsePlanList>(
    { url: `/access-management/v1/plans`, method: 'GET', params, signal },
    options
  );
};

export const getGetPlansQueryKey = (params?: GetPlansParams) => {
  return [`/access-management/v1/plans`, ...(params ? [params] : [])] as const;
};

export const getGetPlansQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlans>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetPlansParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlans>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlansQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlans>>> = ({ signal }) =>
    getPlans(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlans>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlansQueryResult = NonNullable<Awaited<ReturnType<typeof getPlans>>>;
export type GetPlansQueryError = ErrorType<Response403ForbiddenResponse>;

/**
 * @summary Get a list of Plans
 */
export const useGetPlans = <
  TData = Awaited<ReturnType<typeof getPlans>>,
  TError = ErrorType<Response403ForbiddenResponse>,
>(
  params?: GetPlansParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlans>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlansQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a list of Permissions for a Plan
 */
export const getPlanPermissions = (
  params: GetPlanPermissionsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponsePermissions>(
    { url: `/access-management/v1/plans/permissions`, method: 'GET', params, signal },
    options
  );
};

export const getGetPlanPermissionsQueryKey = (params: GetPlanPermissionsParams) => {
  return [`/access-management/v1/plans/permissions`, ...(params ? [params] : [])] as const;
};

export const getGetPlanPermissionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlanPermissions>>,
  TError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>,
>(
  params: GetPlanPermissionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlanPermissions>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlanPermissionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlanPermissions>>> = ({ signal }) =>
    getPlanPermissions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlanPermissions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlanPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getPlanPermissions>>>;
export type GetPlanPermissionsQueryError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>;

/**
 * @summary Get a list of Permissions for a Plan
 */
export const useGetPlanPermissions = <
  TData = Awaited<ReturnType<typeof getPlanPermissions>>,
  TError = ErrorType<Response403ForbiddenResponse | Response404NotFoundResponse>,
>(
  params: GetPlanPermissionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlanPermissions>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlanPermissionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
