import React from 'react';
import { Chip, GridRenderCellParams } from '@zitcha/component-library';
import {
  AdSet,
  AdSetStatusEnum,
  PlanningActionTypeItem,
  useGetBundle,
  useGetPlanningActionTypes,
} from 'v2/lib/api/ad-management';
import { formatDisplayDate } from 'helpers/DateHelpers';
import { actionColors, actionLabels, actionVariants, EllipsisTypography } from '../../TableCellRenderers';
import { CenteredTableCellContainer } from '../../NewPlanAdSetsCellRenders';

export const RenderAdSetActions = (params: GridRenderCellParams) => {
  type AdSetAction = {
    count: number;
    label: string;
  };
  type AdSetActionMessage = {
    entity_type: string;
    field: string;
    id: string;
    message: string;
    type: string;
  };
  const { data: organisationActionTypeData, isLoading: loading } = useGetPlanningActionTypes(
    { organisation_id: params?.row?.ownerId },
    { query: { select: (response) => response.data as Array<PlanningActionTypeItem> } }
  );
  const organisationActionTypes =
    organisationActionTypeData?.map((action: PlanningActionTypeItem) => action.name) ?? [];
  const isRetailer = params?.row?.isRetailer;
  const AdSet: AdSet = params?.row;

  //Grouping into same keys
  const counts = params.value
    ?.filter((action: AdSetActionMessage) => organisationActionTypes.includes(action.type))
    .reduce((prop: Record<string, number>, action: AdSetActionMessage) => {
      const name = action.type;
      if (!prop.hasOwnProperty(name)) {
        prop[name] = 0;
      }
      prop[name]++;
      return prop;
    }, {});

  const status = AdSet?.status || AdSetStatusEnum.new;
  let actionsList = counts ? Object.keys(counts).map((k) => ({ label: k, count: counts[k] })) : [];

  if (isRetailer || (!isRetailer && AdSet?.status !== AdSetStatusEnum.clashed)) {
    actionsList = [{ label: status, count: 1 }, ...actionsList];
  }

  const actions: Array<AdSetAction> = actionsList;

  // Get the width of the column, default to 400 if undefined
  const columnWidth = params.colDef.width || 400;

  // Calculate the width of each chip based on the length of the label and count
  const chipWidths = actions.map((action) => (action.count.toString().length + action.label.length) * 8 + 6);

  // Calculate the maximum number of Chips that can be displayed
  let totalWidth = 0;
  let maxChips = 0;
  for (const width of chipWidths) {
    if (totalWidth + width > columnWidth) break;
    totalWidth += width;
    maxChips++;
  }

  // Split the actions into those that will be displayed and those that won't
  const displayedActions = actions.slice(0, maxChips);
  const hiddenActions = actions.slice(maxChips);

  // Calculate the total count of the hidden actions
  const hiddenCount = hiddenActions.reduce((total, action) => total + action.count, 0);
  return (
    <>
      {!loading && (
        <>
          {displayedActions.map((action, index) => (
            <Chip
              sx={{ cursor: 'pointer', marginRight: '4px' }}
              key={index}
              label={
                action.count > 1 ? actionLabels(`${action.count} ${action.label}`) : actionLabels(`${action.label}`)
              }
              variant={actionVariants[action.label] || 'filled'}
              color={actionColors[action.label] || 'default'}
              onClick={() => {
                //eslint-disable-next-line no-console
              }}
            />
          ))}
          {hiddenActions.length > 0 && (
            <Chip
              sx={{ cursor: 'pointer' }}
              label={`+${hiddenCount}`}
              variant='outlined'
              color='default'
              onClick={() => {
                //eslint-disable-next-line no-console
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export const RenderSchedule: React.FC<{ bundleIds: Array<string> }> = ({ bundleIds }) => {
  const firstBundleId = bundleIds?.[0];
  const {
    data: bundleData,
    isLoading: isBundleLoading,
    error: bundleError,
  } = useGetBundle(firstBundleId, { query: { refetchOnMount: false } });
  if (isBundleLoading || bundleError || !bundleData || !firstBundleId) {
    return null;
  }

  const calendarPeriod = bundleData.data.calendarPeriod;
  const calendarPeriodName = calendarPeriod?.name;
  const calendarPeriodStartAt = calendarPeriod?.startAt;
  const calendarPeriodEndAt = calendarPeriod?.endAt;

  return (
    <CenteredTableCellContainer>
      <EllipsisTypography>
        {calendarPeriodName} ({formatDisplayDate(calendarPeriodStartAt)} - {formatDisplayDate(calendarPeriodEndAt)})
      </EllipsisTypography>
    </CenteredTableCellContainer>
  );
};
