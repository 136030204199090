import { ListItemButton } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { LocationType, useGetLocationTypes } from 'v2/lib/api/ad-management';
import { Column } from './Column';
import { LoadingIndicator } from './LoadingIndicator';
import { NoSearchResults } from './NoSearchResults';
import { ErrorState } from './ErrorState';
import { EmptyState } from './EmptyState';
import { PlacementSelectorContext } from '../context/PlacementSelectorProvider';
import { useGetLocationTypesByMediaSpace } from 'v2/lib/api/inventory';

export const FilteredLocationTypeList = ({ mediaSpaceId }: { mediaSpaceId: string | undefined }) => {
  const { retailerId, locationType, setLocationType, searchLocationTypeTerm, locations } =
    useContext(PlacementSelectorContext);
  const {
    data: locationTypesWithoutMediaSpace,
    isLoading: locationTypesWithoutMediaSpaceIsLoading,
    isError: locationTypesWithoutMediaSpaceIsError,
  } = useGetLocationTypes({ organisation_id: retailerId, per_page: 100 }, { query: { enabled: !mediaSpaceId } });
  const {
    data: locationTypesWithMediaSpace,
    isError: locationTypesWithMediaSpaceIsError,
    isLoading: locationTypesWithMediaSpaceIsLoading,
  } = useGetLocationTypesByMediaSpace(String(mediaSpaceId), {
    query: {
      enabled: !!mediaSpaceId,
    },
  });
  const locationTypes = mediaSpaceId ? locationTypesWithMediaSpace : locationTypesWithoutMediaSpace;
  const isLoading = mediaSpaceId ? locationTypesWithMediaSpaceIsLoading : locationTypesWithoutMediaSpaceIsLoading;
  const isError = mediaSpaceId ? locationTypesWithMediaSpaceIsError : locationTypesWithoutMediaSpaceIsError;
  const filteredLocationTypes = useMemo(() => {
    return locationTypes?.data.filter((type: LocationType) =>
      type?.name?.toLowerCase().includes(searchLocationTypeTerm.toLowerCase())
    );
  }, [locationTypes, searchLocationTypeTerm]);

  if (isLoading) return <LoadingIndicator />;
  if (isError) return <ErrorState errorFor='locations types' />;
  if (searchLocationTypeTerm.trim() !== '' && filteredLocationTypes?.length === 0)
    return <NoSearchResults searchTerm={searchLocationTypeTerm} bordered={false} />;
  if (!isLoading && !isError && filteredLocationTypes?.length === 0 && searchLocationTypeTerm.trim() === '')
    return <EmptyState />;
  return (
    <Column>
      {filteredLocationTypes?.map((type: LocationType) => (
        <ListItemButton selected={locationType?.id === type.id} key={type.id} onClick={() => setLocationType(type)}>
          {type.name}
          {locations?.some((location) => location.locationType?.id === type.id) && ' (Selected)'}
        </ListItemButton>
      ))}
    </Column>
  );
};
