import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { RadioGroup } from '@zitcha/component-library';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface RadioOptionsComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    options?: string;
    defaultSelected?: string;
    label?: string;
  };
  fieldName?: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  value: string;
  isReadOnly: boolean;
  error?: boolean;
  helperText?: string;
}

export const RadioOptionsComponent = ({
  properties,
  fieldName = '',
  onChange,
  onBlur,
  value,
  isReadOnly,
  error,
  helperText,
}: RadioOptionsComponentProps) => {
  const choices = properties.options?.split(',').map((option) => option.trim()) || [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <FormControl
      component='fieldset'
      error={error}
      disabled={isReadOnly}
      data-testid={`${fieldName}-radio-options-component`}
      onBlur={onBlur}
    >
      <RadioGroup
        fieldName={fieldName}
        choices={choices}
        defaultChoice={properties.defaultSelected}
        value={value}
        onChange={handleChange}
        disabled={isReadOnly}
        size='medium'
        error={error}
      />
      {helperText && <FormHelperText style={{ whiteSpace: 'pre-line' }}>{helperText}</FormHelperText>}
    </FormControl>
  );
};
