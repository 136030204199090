import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useApproveAdSet, useTransitionAdSet, useUpdateAdSet } from 'v2/lib/api/ad-management';
import { AdSet, AdSetStatusEnum, UpdateAdSetBody } from 'v2/lib/api/ad-management/model';
import { useProcessingContext } from 'v2/features/GlobalNotifications/ProcessingContext';
import { AxiosError } from 'axios';

export const useAdSetActions = (usePlanAdSetsPage?: number, usePlanAdSetsPerPage?: number) => {
  const [failedAdsetActionsIds, setFailedAdsetActionsIds] = React.useState<Array<string>>([]);
  const transitionAdSet = useTransitionAdSet();
  const updateAdSetMutation = useUpdateAdSet();
  const approveAdSetMutation = useApproveAdSet();
  const queryClient = useQueryClient();
  const { startProcess, finishProcess } = useProcessingContext();

  const updateAdSetStatus = async (adSetId: string, status: AdSetStatusEnum) => {
    const processId = startProcess(`Updating ad set status to ${status}`);
    const previousAdSets = queryClient.getQueryData(['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage]);

    queryClient.setQueryData(
      ['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage],
      (old: { data: Array<AdSet> }) => {
        if (!old || !Array.isArray(old.data)) {
          return old;
        }

        return {
          ...old,
          data: old.data.map((adSet: AdSet) => {
            if (adSet.id === adSetId) {
              return { ...adSet, status };
            }
            return adSet;
          }),
        };
      }
    );

    try {
      await transitionAdSet.mutateAsync(
        { id: adSetId, data: { status } },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage],
            });
            finishProcess(processId, { success: true, message: `Ad set status updated to ${status}` });
          },
        }
      );
    } catch (error) {
      queryClient.setQueryData(['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage], previousAdSets);
      finishProcess(processId, { success: false, message: `Failed to update ad set status to ${status}` });
    }
  };

  const proposeAdSet = async (adSetId: string) => {
    const processId = startProcess('Proposing ad set');
    try {
      await transitionAdSet.mutateAsync({ id: adSetId, data: { status: AdSetStatusEnum.pending_approval } });
      finishProcess(processId, { success: true, message: 'Ad set proposed' });
    } catch (error) {
      finishProcess(processId, { success: false, message: 'Failed to propose ad set' });
    }
  };

  const approveAdSet = (adSetId: string) => {
    const processId = startProcess('Approving ad set');
    approveAdSetMutation.mutate(
      { adSet: adSetId },
      {
        onSuccess: () => finishProcess(processId, { success: true, message: 'Ad set approved' }),
        onError: () => finishProcess(processId, { success: false, message: 'Failed to approve ad set' }),
      }
    );
  };

  const rejectAdSet = (adSetId: string) => updateAdSetStatus(adSetId, AdSetStatusEnum.draft);

  const approveAdSetAsync = async (adSetId: string) => {
    const processId = startProcess('Approving ad set');
    try {
      await approveAdSetMutation.mutateAsync({ adSet: adSetId });
      finishProcess(processId, { success: true, message: 'Ad set approved' });
      return { success: true };
    } catch (error) {
      finishProcess(processId, { success: false, message: 'Failed to approve ad set' });
      return { success: false, error };
    }
  };

  const rejectAdSetAsync = async (adSetId: string) => {
    const processId = startProcess('Rejecting ad set');
    try {
      await transitionAdSet.mutateAsync({ id: adSetId, data: { status: AdSetStatusEnum.draft } });
      finishProcess(processId, { success: true, message: 'Ad set rejected' });
      return { success: true };
    } catch (error) {
      finishProcess(processId, { success: false, message: 'Failed to reject ad set' });
      return { success: false, error };
    }
  };

  const updateAdSetStatuses = async (adSetIds: Array<string>, status: AdSetStatusEnum) => {
    const processId = startProcess(`Updating ${adSetIds.length} ad set(s) status to ${status}`);
    const previousAdSets = queryClient.getQueryData(['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage]);

    queryClient.setQueryData(
      ['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage],
      (old: { data: Array<AdSet> }) => {
        if (!old || !Array.isArray(old.data)) {
          return old;
        }

        return {
          ...old,
          data: old.data.map((adSet: AdSet) => {
            if (adSetIds.includes(adSet.id as string)) {
              return { ...adSet, status };
            }
            return adSet;
          }),
        };
      }
    );

    try {
      const updatePromises = adSetIds.map(async (adSetId) => {
        try {
          const response = await transitionAdSet.mutateAsync({ id: adSetId, data: { status } });
          return { status, response };
        } catch (error) {
          return { adSetId, error }; // Return the error along with the adSetId
        }
      });

      const results = (await Promise.allSettled(updatePromises)) as Array<{
        status: 'fulfilled' | 'rejected';
        value?: { status: AdSetStatusEnum; adSetId?: string; error?: AxiosError };
      }>;
      const failedIds = results
        .filter((result) => result?.value?.error)
        .map((result) => result?.value?.adSetId)
        .filter((id): id is string => id !== undefined);
      const successfulUpdates = results.filter((result) => result.status === 'fulfilled' && !result.value?.error);

      if (failedIds.length > 0) {
        setFailedAdsetActionsIds(failedIds);
        throw new Error(`Failed to update ${failedIds.length} ad set(s) to ${status}`);
      }

      queryClient.invalidateQueries({
        queryKey: ['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage],
      });

      finishProcess(processId, {
        success: true,
        message: `${successfulUpdates.length} ad set(s) updated to ${status}`,
      });
    } catch (error) {
      queryClient.setQueryData(['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage], previousAdSets);
      finishProcess(processId, {
        success: false,
        message: error.message,
      });
    }
  };

  const approveBulkAdSets = (adSetIds: Array<string>) => updateAdSetStatuses(adSetIds, AdSetStatusEnum.reserved);
  const rejectBulkAdSets = (adSetIds: Array<string>) => updateAdSetStatuses(adSetIds, AdSetStatusEnum.rejected);

  const updateAdSet = async ({ id, data }: { id: string; data: UpdateAdSetBody }) => {
    const processId = startProcess('Updating ad set');
    try {
      await updateAdSetMutation.mutateAsync({ id, data });
      queryClient.invalidateQueries({
        predicate: (query) => {
          const queryKey = query.queryKey as Array<string>;
          const firstKey = Array.isArray(queryKey) ? queryKey[0] : '';
          return firstKey === 'usePlanAdSets' || firstKey === 'searchAdSets';
        },
      });
      finishProcess(processId, { success: true, message: 'Ad set updated' });
    } catch (error) {
      finishProcess(processId, { success: false, message: 'Failed to update ad set' });
    }
  };

  return {
    approveAdSet,
    approveAdSetAsync,
    rejectAdSet,
    rejectAdSetAsync,
    approveBulkAdSets,
    rejectBulkAdSets,
    updateAdSet,
    proposeAdSet,
    isPending: transitionAdSet.isPending || updateAdSetMutation.isPending || approveAdSetMutation.isPending,
    failedAdsetActionsIds,
  };
};
