import React from 'react';
import { Box, Typography, IconButton, DeleteIcon } from '@zitcha/component-library';
import UploadIcon from '@mui/icons-material/Upload';

interface AccordionSummaryDetailsProps {
  adIndex: number;
  mediaSpaceName?: string;
  onRemove: () => void;
  isReadOnly: boolean;
}

const allowDelete = false;

export const AccordionSummaryDetails: React.FC<AccordionSummaryDetailsProps> = ({
  adIndex,
  mediaSpaceName,
  onRemove,
  isReadOnly,
}) => {
  return (
    <Box display='flex' alignItems='center' gap={1} width='100%'>
      <Typography variant='subtitle1' sx={{ flexGrow: 0 }}>{`Ad ${adIndex + 1}`}</Typography>
      <Box display='flex' alignItems='center' gap={1} flexGrow={1}>
        <Typography variant='body2'>{mediaSpaceName}</Typography>
        <UploadIcon sx={{ color: 'GrayText', flexShrink: 0 }} />
      </Box>
      {allowDelete && !isReadOnly && (
        <IconButton onClick={onRemove} size='small' aria-label='remove ad'>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};
