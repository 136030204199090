import { Button, Grid } from '@zitcha/component-library';
import React, { useContext } from 'react';
import type { Location } from '../../../lib/api/ad-management';
import { PlacementSelectorContext } from '../context/PlacementSelectorProvider';

export const ConfirmSelection = ({ onConfirm }: { onConfirm: (location: Array<Location>) => void }) => {
  const { locations } = useContext(PlacementSelectorContext);
  return (
    <Grid
      display='flex'
      flexDirection='column'
      sx={{
        borderTop: '1px solid #E0E0E0',
        padding: '8px',
        marginTop: '5px',
        alignItems: 'flex-end',
      }}
    >
      <Button
        sx={{ marginX: '20px' }}
        variant='text'
        onClick={() => {
          if (!locations) return;
          onConfirm(locations);
        }}
      >
        Confirm Selection
      </Button>
    </Grid>
  );
};
