import React, { createContext, useContext } from 'react';
import { AdSet } from 'v2/lib/api/ad-management';

interface AdSetContextType {
  adSet: AdSet | null;
  retailerId: string | null;
  brandId: string | null;
}

const AdSetContext = createContext<AdSetContextType | undefined>(undefined);

export const useAdSetContext = () => {
  const context = useContext(AdSetContext);
  if (context === undefined) {
    throw new Error('useAdSetContext must be used within an AdSetProvider');
  }
  return context;
};

export const AdSetProvider: React.FC<{
  children: React.ReactNode;
  adSet: AdSet | null;
  retailerId: string | null;
  brandId: string | null;
}> = ({ children, adSet, retailerId, brandId }) => {
  return <AdSetContext.Provider value={{ adSet, retailerId, brandId }}>{children}</AdSetContext.Provider>;
};
