import React from 'react';
import { TextField } from '@zitcha/component-library';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface DateFieldComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    minDate?: string;
    maxDate?: string;
    label?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value: any;
  isReadOnly: boolean;
  error?: boolean;
  helperText?: string;
}

export const DateFieldComponent: React.FC<DateFieldComponentProps> = ({
  properties,
  fieldName,
  onChange,
  onBlur,
  value,
  isReadOnly,
  error,
  helperText,
}) => {
  const controlledValue = value || '';
  return (
    <TextField
      type='date'
      id={`${fieldName}-date-field-${properties.label}`}
      variant='outlined'
      fullWidth
      InputLabelProps={{ shrink: true }}
      inputProps={{
        min: properties.minDate,
        max: properties.maxDate,
      }}
      onChange={onChange}
      onBlur={onBlur}
      value={controlledValue}
      data-testid={`${fieldName}-date-field-component`}
      disabled={isReadOnly}
      error={error}
      helperText={helperText}
      FormHelperTextProps={{ style: { whiteSpace: 'pre-line' } }}
    />
  );
};
