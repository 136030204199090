export const locales: { [key: string]: string } = {
  USD: 'en-US', // US Dollar
  GBP: 'en-GB', // British Pound
  EUR: 'en-GB', // Euro
  AUD: 'en-AU', // Australian Dollar
  NZD: 'en-NZ', // New Zealand Dollar
  ZAR: 'en-ZA', // South African Rand
  CAD: 'en-CA', // Canadian Dollar
};

export const formatCurrency = (amount: number | undefined, currency: string) => {
  const currencyFormat = currency || 'USD';
  const formatted = new Intl.NumberFormat(locales[currencyFormat] ?? 'en-US', {
    style: 'currency',
    currency: currencyFormat,
  }).format(amount ?? 0);

  // Fall back to showing the amount without currency symbol if currency is not provided
  if (currency === '') {
    return formatted.replace('$', '');
  }

  return formatted;
};
