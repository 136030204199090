import React from 'react';
import { AdSetSearch } from 'v2/lib/api/ad-management';
import { ActionTypesRelatedSetsActions } from './ActionTypesRelatedAdSetsActions';

interface AdSetsActionsTableProps {
  filters: Partial<AdSetSearch>;
  tab: string;
}

export const AdSetsActionsTable: React.FC<AdSetsActionsTableProps> = ({ filters, tab }) => {
  return <ActionTypesRelatedSetsActions filters={filters} actionTypes={[tab]} />;
};
