import React from 'react';
import { Box, DataGrid } from '@zitcha/component-library';

import { useClashManagementContext } from '../context/ClashManagementContext';
import { ClashManagementModalMode } from '../types/ClashManagementModalMode';
import { useClashManagementColumns } from './ClashManagementGridColumns';

const ClashManagementEditView = () => {
  const { state } = useClashManagementContext();
  const columns = useClashManagementColumns(ClashManagementModalMode.EDIT);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      padding={2}
      sx={{ backgroundColor: 'white' }}
    >
      <DataGrid
        loading={state.isLoading}
        disableColumnFilter
        disableColumnSorting={true}
        rows={state.EditClashRows}
        columns={columns}
        rowHeight={60}
        editMode='row'
        hideFooterPagination={true}
        sx={{
          '.MuiDataGrid-columnHeaders': {
            '.MuiDataGrid-columnHeaderTitle': {
              color: 'black',
            },
          },
          '& .MuiDataGrid-cell': {
            padding: '0 !important',
          },
        }}
      />
    </Box>
  );
};

export default ClashManagementEditView;
