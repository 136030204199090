import React, { FC } from 'react';
import { AdSetData } from '../../features/Planning/PlanContext';
import { AdSetStatusEnum } from '../../lib/api/ad-management';
import { ViewMode } from '../../features/Planning/PlanPage/ViewModeType';
import {
  Button,
  CheckIcon,
  ClearIcon,
  DeleteIcon,
  EditIcon,
  ErrorIcon,
  GridActionsCellItem,
  SendIcon,
  VisibilityIcon,
} from '@zitcha/component-library';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { PLAN_EDIT } from 'lib/permissions';

export type AdSetActionType =
  | 'approve'
  | 'reject'
  | 'view'
  | 'edit'
  | 'clear'
  | 'remove'
  | 'propose'
  | 'view_plan'
  | 'resolve_clash';

interface RenderActionsProps {
  adSet: AdSetData;
  viewMode: ViewMode;
  actionCallback: (action: AdSetActionType, adSet: AdSetData) => void;
  canApproveOrReject: boolean;
  canViewClash: boolean;
  canResolveClash?: boolean;
  isDashboardActions?: boolean;
  actionTypeList?: Array<AdSetActionType>;
}
interface ActionsListType {
  key: string;
  icon: React.JSX.Element;
  color: 'default' | 'success' | 'error';
  ariaLabel: string;
  onClick: () => void;
  shouldShow: boolean;
}

const renderDashboardAction = (actionData: ActionsListType, actionTitle: string) => {
  return (
    <Button
      key={actionData.key}
      aria-label={actionData.ariaLabel}
      endIcon={actionData.icon}
      variant='text'
      onClick={actionData.onClick}
      title={actionTitle}
    >
      {actionTitle}
    </Button>
  );
};

const renderAdSetAction = (actionData: ActionsListType, actionTitle: string) => {
  //@ts-expect-error : we know about this
  return <GridActionsCellItem key={actionData.ariaLabel} {...actionData} title={actionTitle} />;
};

export const AdSetActions: FC<RenderActionsProps> = ({
  adSet,
  viewMode,
  actionCallback,
  canApproveOrReject = false,
  canViewClash = false,
  canResolveClash = false,
  isDashboardActions = false,
  actionTypeList,
}) => {
  if (!adSet?.id) {
    return <></>;
  }

  const adSetIsApproved = new Set([AdSetStatusEnum.scheduled, AdSetStatusEnum.live, AdSetStatusEnum.failed]).has(
    adSet.status
  );
  const adSetHasClash = adSet.status === AdSetStatusEnum.clashed;
  const canProposedAdSet = new Set([
    AdSetStatusEnum.draft,
    AdSetStatusEnum.reserved,
    AdSetStatusEnum.scheduled,
    AdSetStatusEnum.live,
  ]).has(adSet.status);

  const canEditAdSet =
    (!adSetIsApproved && viewMode !== ViewMode.REVIEWING && adSet?.permissions?.[PLAN_EDIT]) || false;

  const canViewAdSet = !canEditAdSet;
  const canApproveAdSet =
    canApproveOrReject && viewMode !== ViewMode.EDITING && adSet.status === AdSetStatusEnum.pending_approval;
  const canRejectAdSet =
    canApproveOrReject && viewMode !== ViewMode.EDITING && adSet.status === AdSetStatusEnum.pending_approval;
  const canClearAdSet = canEditAdSet && adSet.existingAdSet !== undefined;
  const canDelete = viewMode === ViewMode.NEW;

  const actions: Array<ActionsListType> = [
    {
      key: 'view',
      icon: <VisibilityIcon />,
      color: 'default',
      ariaLabel: 'View ad set button',
      onClick: () => actionCallback('view', adSet),
      shouldShow: canViewAdSet,
    },
    {
      key: 'approve',
      icon: <CheckIcon />,
      color: 'success',
      ariaLabel: 'Approve ad set',
      onClick: () => actionCallback('approve', adSet),
      shouldShow: canApproveAdSet,
    },
    {
      key: 'reject',
      icon: <ClearIcon />,
      color: 'error',
      ariaLabel: 'Reject ad set',
      onClick: () => actionCallback('reject', adSet),
      shouldShow: canRejectAdSet,
    },
    {
      key: 'edit',
      icon: <EditIcon />,
      color: 'default',
      ariaLabel: 'Edit ad set button',
      onClick: () => actionCallback('edit', adSet),
      shouldShow: (!isDashboardActions && canEditAdSet) || (isDashboardActions && !adSetHasClash),
    },
    {
      key: 'clear',
      icon: <ClearIcon />,
      color: 'default',
      ariaLabel: 'Clear ad set changes',
      onClick: () => actionCallback('clear', adSet),
      shouldShow: canClearAdSet,
    },
    {
      key: 'remove',
      icon: <DeleteIcon />,
      color: 'error',
      ariaLabel: 'Remove ad set',
      onClick: () => actionCallback('remove', adSet),
      shouldShow: canDelete,
    },
    {
      key: 'propose',
      icon: <SendIcon />,
      color: 'default',
      ariaLabel: 'Propose ad set',
      onClick: () => actionCallback('propose', adSet),
      shouldShow: canProposedAdSet,
    },
    {
      key: 'resolve_clash',
      icon: <ErrorIcon />,
      color: 'error',
      ariaLabel: 'Resolve clash',
      onClick: () => (canResolveClash ? actionCallback('resolve_clash', adSet) : {}),
      shouldShow: (canViewClash || canViewAdSet) && (isDashboardActions || adSetHasClash),
    },
    {
      key: 'view_plan',
      icon: <OpenInNewIcon />,
      color: 'default',
      ariaLabel: 'View related plan',
      onClick: () => actionCallback('view_plan', adSet),
      shouldShow: isDashboardActions,
    },
  ];

  const adSetTableActions = actions.map(({ key }) => key);

  // choose the list of actions to render
  const actionsList = isDashboardActions ? actionTypeList : adSetTableActions;
  const renderActionFunction = isDashboardActions ? renderDashboardAction : renderAdSetAction;

  const actionButtons = actions
    // filter out actions that are not in the list of actions to render
    .filter(({ key }) => new Set(actionsList).has(key))
    // filter out actions that should not be shown
    .filter(({ shouldShow }) => shouldShow)
    .map((actionData) => {
      const actionTitle = actionData.key.replace(/_/g, ' ');

      return renderActionFunction(actionData, actionTitle);
    });

  return <> {actionButtons} </>;
};
