import React from 'react';
import { BaseAutocompleteProps } from './BaseAutocomplete';
import { FilterAutocomplete } from './FilterAutocomplete';
import { FormAutocomplete } from './FormAutocomplete';

export type AutocompleteRenderType = 'form' | 'filter';

export interface AutocompleteFactoryProps extends BaseAutocompleteProps {
  type: AutocompleteRenderType;
  labelText?: string;
  placeholderText?: string;
  isLoading?: boolean;
  multiple?: boolean;
  size?: 'small' | 'medium';
}

export const AutocompleteFactory: React.FC<AutocompleteFactoryProps> = (props) => {
  const { type, multiple = true, size = 'medium', ...restProps } = props;

  const commonProps = {
    ...restProps,
    multiple,
    autoCompletePropsOverride: {
      ...restProps.autoCompletePropsOverride,
      size,
      multiple,
    },
  };

  return type === 'filter' ? (
    <FilterAutocomplete
      labelText={props.labelText || ''}
      placeholderText={props.placeholderText || ''}
      {...commonProps}
    />
  ) : (
    <FormAutocomplete {...commonProps} />
  );
};
