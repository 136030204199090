import React from 'react';
import { FormControl, FormControlLabel, Switch, FormHelperText } from '@mui/material';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface ToggleComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    defaultChecked?: boolean;
    toggleLabel?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value: any;
  isReadOnly: boolean;
  error?: boolean;
  helperText?: string;
}

export const ToggleComponent = ({
  properties,
  fieldName,
  onChange,
  onBlur,
  value,
  isReadOnly,
  error,
  helperText,
}: ToggleComponentProps) => {
  const controlledValue = value || properties.defaultChecked || false;
  return (
    <FormControl error={error} component='fieldset'>
      <FormControlLabel
        control={
          <Switch onChange={onChange} onBlur={onBlur} checked={Boolean(controlledValue)} disabled={isReadOnly} />
        }
        label={properties.toggleLabel}
        data-testid={`${fieldName}-toggle-component`}
      />
      {helperText && <FormHelperText style={{ whiteSpace: 'pre-line' }}>{helperText}</FormHelperText>}{' '}
    </FormControl>
  );
};
