/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Zitcha System API
 * This is the API endpoint to manage systems in Zitcha.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from '@tanstack/react-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import type { FeatureFlags } from '../model';
import { customInstance } from '../../../axios/axiosMainInstance';
import type { ErrorType } from '../../../axios/axiosMainInstance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Get the values of all feature flags for users current active organisation
 * @summary Get feature flag values
 */
export const getFeatureFlags = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<FeatureFlags>({ url: `/system-api/v1/feature-flags`, method: 'GET', signal }, options);
};

export const getGetFeatureFlagsQueryKey = () => {
  return [`/system-api/v1/feature-flags`] as const;
};

export const getGetFeatureFlagsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFeatureFlags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeatureFlags>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFeatureFlagsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeatureFlags>>> = ({ signal }) =>
    getFeatureFlags(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFeatureFlags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFeatureFlagsQueryResult = NonNullable<Awaited<ReturnType<typeof getFeatureFlags>>>;
export type GetFeatureFlagsQueryError = ErrorType<void>;

/**
 * @summary Get feature flag values
 */
export const useGetFeatureFlags = <
  TData = Awaited<ReturnType<typeof getFeatureFlags>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeatureFlags>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFeatureFlagsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
