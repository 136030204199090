import React, { SetStateAction } from 'react';
import type { Location } from 'v2/lib/api/ad-management';
import { Popper, PopperProps, ClickAwayListener } from '@mui/material';
import { PlacementList } from './PlacementList';

export const PlacementAutocompletePopper = (
  props: PopperProps & {
    onClose: () => void;
    setSelectedValues: (value: SetStateAction<Array<Location>> | SetStateAction<Location> | undefined) => void;
    multiple: boolean;
    value: Array<Location>;
    mediaSpaceId?: string;
    retailerId: string;
  }
) => {
  const {
    onClose,
    multiple,
    setSelectedValues,
    value,
    mediaSpaceId,
    retailerId,
    role,
    open,
    disablePortal,
    anchorEl,
    className,
  } = props;
  return (
    <ClickAwayListener onClickAway={onClose} mouseEvent='onMouseDown'>
      <Popper
        anchorEl={anchorEl}
        open={open}
        role={role}
        disablePortal={disablePortal}
        className={className}
        data-testid='placement-popper'
        placement='bottom'
        modifiers={[
          {
            name: 'setWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn: ({ state }) => {
              state.styles.popper.width = 'auto';
              state.styles.popper.minWidth = `${state.rects.reference.width}px`;
            },
          },
        ]}
        sx={{
          backgroundColor: 'white',
          border: '1px solid #E0E0E0',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          flex: 1,
          borderRadius: '4px',
        }}
      >
        <PlacementList
          onConfirmPlacementSelection={(value: Array<Location> | Location | undefined) => {
            setSelectedValues(value);
          }}
          multiple={multiple}
          onClose={onClose}
          value={value}
          mediaSpaceId={mediaSpaceId}
          retailerId={retailerId}
        />
      </Popper>
    </ClickAwayListener>
  );
};
