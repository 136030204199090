import React from 'react';
import { Box, CircularProgress, Typography } from '@zitcha/component-library';
import ModalFooterButtons from './ModalFooterButtons';
import ClashManagementEditView from './ClashManagementEditView';
import ClashManagementReviewView from './ClashManagementReviewView';
import { ClashManagementModalMode } from '../types/ClashManagementModalMode';
import { useClashManagementContext } from '../context/ClashManagementContext';
import { Button } from 'react-bootstrap';

interface ClashManagementModalContentProps {
  closeClashManagementModal: () => void;
}

const ClashManagementModalContent: React.FC<ClashManagementModalContentProps> = ({ closeClashManagementModal }) => {
  const { state, actions } = useClashManagementContext();
  if (!state || !actions || state.isLoading) {
    return (
      <Box sx={{ overflow: 'auto', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  if (state.inventoryErrorOccurred) {
    return (
      <>
        <Box
          sx={{
            overflow: 'auto',
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p>Something went wrong while fetching inventory data. Please try again later.</p>
        </Box>
        <Button
          variant='text'
          color='error'
          aria-label='Close'
          className='w-5/12 mr-3'
          onClick={closeClashManagementModal}
        >
          CLOSE
        </Button>
      </>
    );
  }
  return (
    <>
      <Box sx={{ overflow: 'auto', height: '70vh' }}>
        <Typography variant='body1'>Edit your ad set to no longer clash</Typography>
        <p className='twd-text-red-500 twd-mt-2'>Remaining allocation: {state.availableApprovals}</p>
        {state.viewMode === ClashManagementModalMode.EDIT ? <ClashManagementEditView /> : <ClashManagementReviewView />}
      </Box>
      <ModalFooterButtons closeClashManagementModal={closeClashManagementModal} />
    </>
  );
};

export default ClashManagementModalContent;
