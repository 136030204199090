import React, { createContext, useContext, ReactNode } from 'react';
import { useClashManagement, ClashManagementState } from '../hooks/useClashManagement';

const ClashManagementContext = createContext<ClashManagementState | undefined>(undefined);

interface ClashManagementProviderProps {
  children: ReactNode;
  retailerId: string;
  bundleId: string;
  closeClashManagementModal: () => void;
}

export const ClashManagementProvider: React.FC<ClashManagementProviderProps> = ({
  children,
  retailerId,
  bundleId,
  closeClashManagementModal,
}) => {
  const clashManagementState = useClashManagement(retailerId, bundleId, closeClashManagementModal);

  return <ClashManagementContext.Provider value={clashManagementState}>{children}</ClashManagementContext.Provider>;
};

export const useClashManagementContext = () => {
  const context = useContext(ClashManagementContext);
  if (context === undefined) {
    throw new Error('useClashManagementContext must be used within a ClashManagementProvider');
  }
  return context;
};
