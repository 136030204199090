/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Inventory API
 * Zitcha Inventory API
 * OpenAPI spec version: 1.0.0
 */

export type RenderableMediaTypeFieldBelongsTo =
  (typeof RenderableMediaTypeFieldBelongsTo)[keyof typeof RenderableMediaTypeFieldBelongsTo];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RenderableMediaTypeFieldBelongsTo = {
  AdSet: 'AdSet',
  Ad: 'Ad',
} as const;
