import React, { useState, useEffect } from 'react';
import { Box, DateRangePicker, DateRange } from '@zitcha/component-library';
import { FormControl } from '@zitcha/component-library';
import { FormHelperText } from '@mui/material';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';
import moment from 'moment';

interface DateRangeComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    label?: string;
    minDate?: string;
    maxDate?: string;
    minDuration?: number; // duration in days
    maxDuration?: number;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  value: any;
  isReadOnly: boolean;
  error?: boolean;
  helperText?: string;
}

const emptyDateRange: DateRange = {
  from: undefined,
  to: undefined,
};

export const DateRangeComponent: React.FC<DateRangeComponentProps> = ({
  properties,
  fieldName,
  onChange,
  value,
  isReadOnly,
  error,
  helperText,
}) => {
  const controlledValue = value || emptyDateRange;
  const [dateRange, setDateRange] = useState<DateRange>(controlledValue);

  const validateDateRange = (range: DateRange | undefined) => {
    if (!range || !range.from || !range.to) return false;
    const { minDate, maxDate, minDuration, maxDuration } = properties;
    const startDate = moment(range.from);
    const endDate = moment(range.to);
    const duration = endDate.diff(startDate, 'days') + 1; // Including both start and end date

    if (minDate && startDate.isBefore(moment(minDate), 'day')) return false;
    if (maxDate && endDate.isAfter(moment(maxDate), 'day')) return false;
    if (minDuration && duration < minDuration) return false;
    if (maxDuration && duration > maxDuration) return false;

    return true;
  };

  useEffect(() => {
    if (dateRange === undefined) {
      onChange(emptyDateRange);
    } else if (validateDateRange(dateRange)) {
      onChange(dateRange);
    }
  }, [dateRange, properties, onChange]);

  return (
    <FormControl error={error} component='fieldset'>
      <Box
        display='flex'
        justifyContent='space-between'
        borderColor={validateDateRange(dateRange) ? 'transparent' : 'red'}
        data-testid={`${fieldName}-date-range-component`}
      >
        {isReadOnly ? (
          <Box>
            {dateRange?.from || dateRange?.to ? `${dateRange.from} - ${dateRange.to}` : 'No date range selected'}
          </Box>
        ) : (
          <DateRangePicker placeholderText={'Select a date range'} date={dateRange} setDate={setDateRange} />
        )}
      </Box>
      {helperText && <FormHelperText style={{ whiteSpace: 'pre-line' }}>{helperText}</FormHelperText>}{' '}
    </FormControl>
  );
};
