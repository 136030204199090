import { Control, Controller, useFormState } from 'react-hook-form';
import React from 'react';
import { RenderableMediaTypeField } from 'v2/lib/api/inventory';
import { InputComponent } from './DynamicMediaTypeFieldComponents/InputComponent';
import { ColorPickerComponent } from './DynamicMediaTypeFieldComponents/ColorPickerComponent';
import { UploaderComponent } from './DynamicMediaTypeFieldComponents/UploaderComponent';
import { CheckboxComponent } from './DynamicMediaTypeFieldComponents/CheckboxComponent';
import { ToggleComponent } from './DynamicMediaTypeFieldComponents/ToggleComponent';
import { RadioOptionsComponent } from './DynamicMediaTypeFieldComponents/RadioOptionsComponent';
import { NumberFieldComponent } from './DynamicMediaTypeFieldComponents/NumberFieldComponent';
import { DateFieldComponent } from './DynamicMediaTypeFieldComponents/DateFieldComponent';
import { DateRangeComponent } from './DynamicMediaTypeFieldComponents/DateRangeComponent';
import { FallbackComponent } from './DynamicMediaTypeFieldComponents/FallbackComponent';
import { TextAreaComponent } from './DynamicMediaTypeFieldComponents/TextAreaComponent';
import { Box, Typography } from '@zitcha/component-library';
import { AdSetFormValues } from './AdSetModal';
import { ProductSelectorComponent } from './DynamicMediaTypeFieldComponents/ProductSelectorComponent';

interface DynamicMediaTypeFieldProps {
  fieldDefinition: RenderableMediaTypeField;
  control: Control<AdSetFormValues>;
  adIndex: number;
  isReadOnly: boolean;
}

export const DynamicMediaTypeField = ({
  fieldDefinition,
  control,
  adIndex,
  isReadOnly,
}: DynamicMediaTypeFieldProps) => {
  const { type = '', properties = {}, name: fieldName } = fieldDefinition;
  const { label } = properties;
  // Construct the unique field name using adIndex and the field's name
  const uniqueFieldName = `ads.${adIndex}.fields.${fieldName}`;

  const { errors } = useFormState({ control });
  const fieldError = errors.ads?.[adIndex]?.fields?.[fieldName];

  return (
    <Box minHeight={'80px'}>
      <Typography variant='body1'>{label as string | undefined}</Typography>
      <Controller
        name={uniqueFieldName}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <DynamicComponent
            type={type}
            fieldDefinition={fieldDefinition}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            control={control}
            isReadOnly={isReadOnly}
            error={!!fieldError}
            helperText={fieldError?.message}
          />
        )}
      />
    </Box>
  );
};

interface DynamicComponentProps<T> {
  type: string;
  fieldDefinition: RenderableMediaTypeField;
  onChange: (value: T) => void;
  onBlur: () => void;
  value: T;
  control: Control<AdSetFormValues>;
  isReadOnly: boolean;
  error?: boolean;
  helperText?: string;
}

const DynamicComponent = <T,>({
  type,
  fieldDefinition,
  onChange,
  onBlur,
  value,
  control,
  isReadOnly,
  error,
  helperText,
}: DynamicComponentProps<T>) => {
  const { properties = {}, name } = fieldDefinition;
  switch (type) {
    case 'InputComponent':
      return (
        <InputComponent
          properties={properties}
          fieldName={name}
          onChange={(e) => onChange(e.target.value as T)}
          onBlur={onBlur}
          value={value}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    case 'TextAreaComponent':
      return (
        <TextAreaComponent
          properties={properties}
          fieldName={name}
          onChange={(e) => onChange(e.target.value as T)}
          onBlur={onBlur}
          value={value}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    case 'ColorPickerComponent':
      return (
        <ColorPickerComponent
          properties={properties}
          fieldName={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    case 'UploaderComponent':
      return (
        <UploaderComponent
          properties={properties}
          fieldName={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    case 'CheckboxComponent':
      return (
        <CheckboxComponent
          properties={properties}
          fieldName={name}
          onChange={(e) => onChange(e.target.checked as T)}
          onBlur={onBlur}
          value={value as boolean}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    case 'ToggleComponent':
      return (
        <ToggleComponent
          properties={properties}
          fieldName={name}
          onChange={(e) => onChange(e.target.checked as T)}
          onBlur={onBlur}
          value={value}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    case 'RadioOptionsComponent':
      return (
        <RadioOptionsComponent
          properties={properties}
          fieldName={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    case 'NumberFieldComponent':
      return (
        <NumberFieldComponent
          properties={properties}
          fieldName={name}
          onChange={(e) => onChange(e.target.value as unknown as T)}
          onBlur={onBlur}
          value={value}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    case 'DateFieldComponent':
      return (
        <DateFieldComponent
          properties={properties}
          fieldName={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    case 'DateRangeComponent':
      return (
        <DateRangeComponent
          properties={properties}
          fieldName={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    case 'ProductSelectorComponent':
      return (
        <ProductSelectorComponent
          properties={properties}
          fieldName={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          control={control}
          isReadOnly={isReadOnly}
          error={error}
          helperText={helperText}
        />
      );
    default:
      return <FallbackComponent type={type} fieldName={name} />;
  }
};
