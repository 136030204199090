import { ResponseUser, useGetTeam } from 'v2/lib/api/access-management';
import { User } from '../UsersList';
import { OptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';

interface GetTeamFromDataParams {
  teamId: number | null;
}

const transformUsersToFormValues = (users: Array<ResponseUser>): Array<User> => {
  return users?.map((user) => {
    return {
      user: {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
      },
      role: {
        id: Number(user?.teamsRoles[0].role?.id),
        name: user?.teamsRoles[0].role?.name,
      },
    };
  });
};

export const useGetTeamFormData = ({ teamId }: GetTeamFromDataParams) => {
  const { data, isLoading, isError } = useGetTeam(Number(teamId));

  const team = data?.data;
  const teamName = team?.name;
  const brands = (team?.scope?.organisations || []) as Array<OptionsType>;
  const productSets = team?.scope?.productSets || ([] as Array<OptionsType>);
  const orders = team?.scope?.orders || ([] as Array<OptionsType>);
  const plans = (team?.scope?.plans || []) as Array<OptionsType>;
  const wallets = team?.scope?.wallets || ([] as Array<OptionsType>);
  const users = transformUsersToFormValues(team?.scope?.users || []);
  const HAS_BRANDS = brands?.length > 0;
  const HAS_PRODUCT_SETS = productSets?.length > 0;
  const HAS_ORDERS = orders?.length > 0;
  const HAS_USERS = users?.length > 0;
  const HAS_PLANS = plans?.length > 0;
  const HAS_WALLETS = wallets?.length > 0;

  return {
    teamName,
    brands,
    productSets,
    orders,
    users,
    plans,
    wallets,
    HAS_BRANDS,
    HAS_PRODUCT_SETS,
    HAS_ORDERS,
    HAS_USERS,
    HAS_PLANS,
    HAS_WALLETS,
    isLoading,
    isError,
  };
};
