import React from 'react';
import { Column } from './Column';
import { Typography } from '@zitcha/component-library';
export const ErrorState = ({ errorFor }: { errorFor: 'locations types' | 'placements' }) => {
  return (
    <Column bordered>
      <Typography variant='subtitle2' sx={{ padding: 2 }}>
        Error loading {errorFor}!
      </Typography>
    </Column>
  );
};
