import React, { useState } from 'react';
import { Modal } from '@zitcha/component-library';
import {
  Typography,
  Autocomplete,
  DriveFileRenameOutlineIcon,
  StoreIcon,
  MenuItem,
  AccountBalanceWalletIcon,
  EditIcon,
  SendIcon,
  InputAdornment,
  TextField,
  Button,
} from '@zitcha/component-library';
import { useNavigate } from 'react-router-dom';
import { useBrandWallets } from 'v2/lib/hooks/useWallets';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { useNewPlanActions } from 'v2/lib/hooks/useNewPlanActions';
import { useProcessingContext } from 'v2/features/GlobalNotifications/ProcessingContext';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';

interface NewPlanModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface NewPlanModalState {
  name: string | null;
  brandId: string | null;
  walletId: string | null;
  retailerId: string | null;
}

const emptyState: NewPlanModalState = {
  name: null,
  brandId: null,
  walletId: null,
  retailerId: null,
};

export const NewPlanModal = (props: NewPlanModalProps) => {
  const { startProcess, finishProcess } = useProcessingContext();
  const { createAndProposePlanWithoutPlanContext } = useNewPlanActions();
  const [newPlan, setNewPlan] = useState<NewPlanModalState>(emptyState);
  const navigate = useNavigate();

  const organisation = useUserOrganisation();
  const organisations = useScopedOrganisations({}).organisations;

  const { wallets: brandWallets, isLoading: isBrandWalletsLoading } = useBrandWallets(
    organisation.is_retailer,
    newPlan.retailerId,
    newPlan.brandId
  );

  const updatePlanData = (key: keyof NewPlanModalState, value: string) => {
    if (newPlan[key] === value) return;

    if (key === 'brandId' || key === 'retailerId') {
      setNewPlan((prevState) => ({
        ...prevState,
        [key]: value,
        walletId: null,
      }));
    } else {
      setNewPlan((prevState) => ({ ...prevState, [key]: value }));
    }
  };

  const handleProposePlan = async () => {
    const processId = startProcess('Proposing plan...');

    try {
      await createAndProposePlanWithoutPlanContext(newPlan);
      finishProcess(processId, { success: true, message: 'Plan proposed' });
      props.onClose();
      setNewPlan(emptyState);
    } catch (error) {
      finishProcess(processId, { success: false, message: 'Failed to propose plan' });
    }
  }; //todo all succesfully gone

  const handleStartEditingPlan = () => {
    props.onClose();

    navigate('/plans-ad-sets/new-plan', { state: newPlan, replace: true });
    setNewPlan(emptyState);
  };

  if (organisation.is_retailer) {
    updatePlanData('retailerId', organisation.id);
  } else {
    updatePlanData('brandId', organisation.id);
  }

  const retailerDictionary = {
    'modal-sub-heading': 'Start editing a new plan, or propose a brand to fill out based on their preferences.',
    'wallet-helper-text': "If you can't see a related wallet, visit the brand's wallet settings",
  };
  const brandDictionary = {
    'modal-sub-heading': '',
    'wallet-helper-text': "If you can't see a related wallet, please contact the retailer",
  };
  const textDictionary = organisation.is_retailer ? retailerDictionary : brandDictionary;
  const handleOrganisationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (organisation.is_retailer) {
      updatePlanData('brandId', event.target.value);
    } else {
      updatePlanData('retailerId', event.target.value);
    }
  };

  const isFormValidForProposingPlan = () => {
    return newPlan.name && newPlan.brandId && newPlan.walletId && newPlan.retailerId;
  };

  return (
    <div>
      <Modal open={props.isOpen} size='medium' title='New plan' onClose={props.onClose} className='twd-min-w-[400px]'>
        <div>
          <Typography variant='body1'>{textDictionary['modal-sub-heading']}</Typography>
          {/* Plan Name */}
          <div className='twd-mt-8'>
            <TextField
              className='twd-w-full'
              id='plan-name'
              label='Name your plan'
              variant='standard'
              value={newPlan.name ?? ''}
              onChange={(event) => updatePlanData('name', event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <DriveFileRenameOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          {/* Brand selection */}
          <div className='twd-mt-8'>
            <TextField
              onChange={handleOrganisationChange}
              id={`${organisation.is_retailer ? 'brand-select' : 'retailer-select'}`}
              className='twd-w-full'
              select
              label={`${organisation.is_retailer ? 'Choose a brand' : 'Choose a retailer'}`}
              variant='standard'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <StoreIcon />
                  </InputAdornment>
                ),
              }}
            >
              {organisations.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* Wallet selection */}
          <div className='twd-mt-8'>
            <Autocomplete
              id='wallet-select'
              options={brandWallets}
              getOptionLabel={(option) => option.name}
              loading={isBrandWalletsLoading}
              onChange={(event, newValue) => updatePlanData('walletId', newValue?.id || null)}
              value={brandWallets.find((wallet) => wallet.id === newPlan.walletId) || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Choose a wallet'
                  variant='standard'
                  helperText={textDictionary['wallet-helper-text']}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AccountBalanceWalletIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>

          {/* Action buttons */}
          {organisation.is_retailer ? (
            <div className='twd-mt-8 twd-flex'>
              <Button variant='text' className='w-5/12 mr-3' onClick={handleStartEditingPlan}>
                <EditIcon className='twd-mr-2' />
                START EDITING PLAN
              </Button>
              <Button className='w-5/12' onClick={handleProposePlan} disabled={!isFormValidForProposingPlan()}>
                PROPOSE PLAN
                <SendIcon className='twd-ml-2' />
              </Button>
            </div>
          ) : (
            <div className='twd-mt-8 twd-flex twd-justify-end'>
              <Button className='w-5/12 ' onClick={handleStartEditingPlan}>
                <EditIcon className='twd-mr-2' />
                START EDITING PLAN
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
