import React, { useEffect } from 'react';
import { Controller, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { UserTeamsAutocomplete } from 'app/OrganisationSettings/components/UserTeamsAutocomplete';

export const WalletTeamSelector = () => {
  const methods = useFormContext();
  const { control, setValue, clearErrors } = methods;
  const { errors, isSubmitted, touchedFields } = useFormState();
  const selectedTeams = useWatch({ control, name: 'teams' }) || [];

  const touchedTeams = touchedFields?.teams || false;
  const errorsTeams = errors?.teams || null;
  const showErrors = (isSubmitted || touchedTeams) && errorsTeams;

  useEffect(() => {
    if (errorsTeams && touchedTeams) {
      clearErrors('teams');
    }
  }, [touchedTeams, errorsTeams, clearErrors]);

  return (
    <Form.Group className='position-relative'>
      <Form.Label>Teams</Form.Label>
      <Controller
        name='teams'
        control={control}
        render={({ field: controllerRenderProps }) => (
          <UserTeamsAutocomplete
            type='form'
            selectedOptions={selectedTeams}
            setSelectedOptions={(data) => setValue('teams', data)}
            controllerRenderProps={controllerRenderProps}
            error={showErrors ? String(errors?.teams?.message) : null}
          />
        )}
      />
    </Form.Group>
  );
};
