import React, { useMemo } from 'react';
import { Alert, Box, GridColDef, GridPaginationModel, DataGrid } from '@zitcha/component-library';
import { useFetchPlans } from 'v2/lib/hooks/useFetchPlans';
import { PerPageEnum, PlanSearch, Plan } from 'v2/lib/api/ad-management';
import { PlansTableFilters } from './PlansTableFilters';
import { debounce } from 'debounce';
import {
  renderAdSetActions,
  renderActionButtons,
  renderStatusChip,
  renderLastModified,
  renderWalletDateRange,
} from './TableCellRenderers';
import { useNavigate } from 'react-router-dom';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

export const PlansTable: React.FC = () => {
  const navigate = useNavigate();
  const organisation = useUserOrganisation();
  const retailerDictionary = {
    keyToSet: 'owner_organisation_ids',
    ownerOrganisationId: [organisation.id],
    supplierOrganisationId: undefined,
    otherUser: {
      key: 'brand',
      name: 'Brand',
    },
  };
  const supplierDictionary = {
    keyToSet: 'supplier_organisation_ids',
    ownerOrganisationId: undefined,
    supplierOrganisationId: [organisation.id],
    otherUser: {
      key: 'retailer',
      name: 'Retailer',
    },
  };
  const userDictionary = organisation.is_retailer ? retailerDictionary : supplierDictionary;

  const { plansData, planPagination, changePlanPagination, isLoadingPlans, plansError, setPlanSearch } = useFetchPlans({
    owner_organisation_ids: userDictionary.ownerOrganisationId,
    statuses: undefined,
    supplier_organisation_ids: userDictionary.supplierOrganisationId,
    wallet_ids: undefined,
    wallet_start_date: undefined,
    wallet_end_date: undefined,
  });

  const rows = plansData?.map((plan: Plan) => ({
    id: plan.id,
    name: plan.name,
    walletDateRange: `${plan.wallet?.startAt} - ${plan.wallet?.endAt}`,
    brand: plan.supplier?.name,
    retailer: plan.owner?.name,
    planStatus: plan.status,
    lastModified: plan.updatedAt,
    adSets: plan.adSetCount,
    adSetActions: plan.actionCounts,
    permissions: plan?.permissions,
    planValue: plan?.value,
    budgetLeft: plan?.budgetLeft,
    actions: plan.id,
  }));

  const dataGridPaginationModel = {
    pageSize: planPagination.perPage ?? PerPageEnum.NUMBER_15,
    page: (planPagination.currentPage ?? 1) - 1, //-1 to match 0 vs 1 based indexing
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changePlanPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  const debouncedHandleFilterChange = useMemo(
    () =>
      debounce((newFilter: PlanSearch) => {
        if (organisation.is_retailer) {
          newFilter.owner_organisation_ids = [organisation.id];
        } else {
          newFilter.supplier_organisation_ids = [organisation.id];
        }
        setPlanSearch(newFilter);
      }, 300),
    [organisation, plansData]
  );

  const columns: Array<GridColDef> = [
    { field: 'name', headerName: 'Plan name', width: 150 },
    { field: 'walletDateRange', headerName: 'Wallet date range', width: 180, renderCell: renderWalletDateRange },
    { field: userDictionary.otherUser.key, headerName: userDictionary.otherUser.name, width: 150 },
    { field: 'planStatus', headerName: 'Plan status', renderCell: renderStatusChip, width: 110 },
    { field: 'lastModified', headerName: 'Last modified', width: 150, renderCell: renderLastModified },
    { field: 'adSets', headerName: 'Ad sets', width: 100 },
    { field: 'adSetActions', headerName: 'Ad set actions', width: 300, renderCell: renderAdSetActions },
    { field: 'planValue', headerName: 'Plan value', width: 100 },
    { field: 'budgetLeft', headerName: 'Budget left', width: 100 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => renderActionButtons(params, navigate),
    },
  ];

  return (
    <Box sx={{ backgroundColor: 'white' }} padding={2} mt={3}>
      <PlansTableFilters onFilterChange={debouncedHandleFilterChange} />
      {plansError && <Alert severity='error'>An error occurred while fetching ad sets.</Alert>}
      <DataGrid
        disableColumnFilter
        disableColumnSorting
        rows={rows ?? []}
        paginationMode='server'
        paginationModel={dataGridPaginationModel}
        rowCount={planPagination?.total || 0}
        onPaginationModelChange={handlePaginationUpdate}
        columns={columns}
        pageSizeOptions={[5, 10, 15, 25, 50, 100]}
        autoHeight
        loading={isLoadingPlans}
      />
    </Box>
  );
};
