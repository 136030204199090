import React, { useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, ProgressBar, Row, Spinner, Tooltip } from 'react-bootstrap';
import { styled } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { ColorPicker } from './ColorPicker';
import { toast } from 'react-toastify';
import useNetworkBranding, { useNetworkBrandingMutation } from './useNetworkBranding';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/session/sessionSlice';
import { SnackbarNotifications } from 'app/components/SnackNotifications/SnackbarNotifications';
import { useTheme, Button } from '@zitcha/component-library';
import {
  NetworkBrandingSettings,
  UpdateNetworkBrandingRequest,
  uploadNetworkBrandingLogo,
} from 'v2/lib/api/system-management';
import { AxiosProgressEvent } from 'axios';
import { set } from 'lodash';

// @todo Decide of a better handling of undefined total (total is returned from the http stream and can take a while to exist)
const percLoaded = (progressEvent: AxiosProgressEvent) => (progressEvent.loaded / (progressEvent.total ?? 1)) * 100;

function NetworkBranding() {
  const user = useSelector(selectUser);
  const { data: networkBranding } = useNetworkBranding({ shouldFetch: false });
  const settings = networkBranding?.data?.settings;
  const defaultTheme = useTheme();
  const mutation = useNetworkBrandingMutation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [navbarBg, setNavbarBg] = React.useState<string | undefined>(
    settings?.theme?.components?.MuiAppBar?.styleOverrides?.root?.backgroundColor
  );
  const [networkLogo, setNetworkLogo] = useState<Pick<NetworkBrandingSettings, 'network_logo'>>({
    network_logo: settings?.network_logo,
  });

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const themeDeepClone = structuredClone(settings?.theme ?? {});
    if (navbarBg) {
      set(themeDeepClone, 'components.MuiAppBar.styleOverrides.root.backgroundColor', navbarBg);
    } else {
      // if more properties are customisable then another solution for resetting each property will need to be found
      delete themeDeepClone?.components;
    }

    const newSettings: UpdateNetworkBrandingRequest = {
      network_logo: networkLogo?.network_logo,
      theme: themeDeepClone,
    };

    mutation.mutate(newSettings);
  };

  useEffect(() => {
    setNetworkLogo({
      network_logo: settings?.network_logo,
    });

    setNavbarBg(settings?.theme?.components?.MuiAppBar?.styleOverrides?.root?.backgroundColor);
  }, [networkBranding]);

  const errors = mutation?.error?.response?.data?.errors;

  const HAS_NETWORK_LOGO =
    networkLogo?.network_logo?.url && networkLogo?.network_logo?.url !== '/Zitcha_Brandmark White.png';
  const MAX_FiLE_SIZE = 5242880;
  return (
    <div>
      <Row>
        <Col>
          <div className='rounded py-3 d-flex'>
            <StyledTitle className='d-inline-block'>
              <h3 className='font-weight-bold'>Network branding</h3>
              <hr />
            </StyledTitle>
          </div>
        </Col>
      </Row>

      <Paragraph>Suppliers will see this branding on the navigation bar when they use the platform.</Paragraph>
      <Form onSubmit={handleSave}>
        <Form.Group>
          <Label style={{ marginBottom: '1rem' }}>Network logo</Label>
          <FileContainer>
            <Form.File
              type='file'
              id='network_logo'
              key={networkLogo?.network_logo?.url}
              label={networkLogo?.network_logo?.name ? 'Change network logo' : 'Choose file'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files?.[0]?.size > MAX_FiLE_SIZE) {
                  alert('The file must be under 5MB.');
                  return;
                }
                uploadNetworkBrandingLogo(
                  { network_logo: e.target.files?.[0] },
                  {
                    onUploadProgress: (p) => setUploadProgress(percLoaded(p)),
                  }
                )
                  .then((resp) => {
                    setNetworkLogo((prev) => ({
                      ...prev,
                      network_logo: resp.network_logo,
                    }));
                    setUploadProgress(0);
                  })
                  .catch(() => {
                    toast.error('Error uploading network logo', {
                      bodyClassName: 'network_branding_toast',
                    });
                    setUploadProgress(0);
                  });
              }}
              custom
            />

            <StyledImage
              style={{
                backgroundColor: HAS_NETWORK_LOGO
                  ? (navbarBg ?? defaultTheme.components?.MuiAppBar?.styleOverrides?.root?.backgroundColor)
                  : 'inherit',
                border: HAS_NETWORK_LOGO ? 'none' : '2px dashed #ddd',
              }}
            >
              {HAS_NETWORK_LOGO ? (
                <img src={`${networkLogo?.network_logo?.url}?r=${Math.random()}`} alt='network logo' />
              ) : (
                <p>Upload your network logo</p>
              )}
            </StyledImage>

            {HAS_NETWORK_LOGO && (
              <Button
                variant='outline-danger'
                onClick={() => {
                  setNetworkLogo({
                    network_logo: {
                      creator_id: user.id,
                      extension: 'png',
                      name: 'zitcha_brandmark_white.png',
                      path: '/Zitcha_Brandmark White.png',
                      storage: 'locally',
                      url: '/Zitcha_Brandmark White.png',
                    },
                  });
                  /**
                   * The color should be reset to zitcha theme initial value, but there's currently no way to
                   * access this value, we can only access the theme post override.
                   */
                  setNavbarBg('#000');
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </FileContainer>
          <span>{errors?.network_logo && <span className='text-danger'>{errors?.network_logo[0]}</span>}</span>
          {uploadProgress > 0 && (
            <ProgressBar
              className='mt-2'
              animated
              now={uploadProgress}
              style={{
                width: '100%',
                maxWidth: '300px',
              }}
            />
          )}
        </Form.Group>
        <Form.Group>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem',
              gap: '10px',
            }}
          >
            <Label>Navigation bar color</Label>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id='info-popover' data-trigger='focus' className='topper'>
                  You must upload a network logo before you can select a navigation bar colour.
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faCircleInfo} size='sm' />
            </OverlayTrigger>
          </div>
          <ColorPicker
            color={
              /* Here we assume that the navbar by default uses the primary, if this ever changes this will need to be changed */
              navbarBg ?? defaultTheme.components?.MuiAppBar?.styleOverrides?.root?.backgroundColor
            }
            setColor={setNavbarBg}
            disabled={HAS_NETWORK_LOGO ? false : true}
          />
        </Form.Group>
        {errors?.['theme.components.MuiAppBar.styleOverrides.root.backgroundColor']?.length && (
          <span className='text-danger'>
            {errors['theme.components.MuiAppBar.styleOverrides.root.backgroundColor'][0]}
          </span>
        )}

        <Row className='mb-3'>
          <Col>
            <Button
              color='primary'
              className='mr-auto px-5 py-2 float-right'
              disabled={mutation.isPending}
              type='submit'
            >
              <span
                className='small d-flex'
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {mutation.isPending && (
                  <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' className='mr-2' />
                )}
                <span>Save Changes</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <SnackbarNotifications
        isSuccess={mutation.isSuccess}
        isError={mutation.isError}
        messages={{
          success: 'Network branding updated',
          error: 'Error updating network branding',
        }}
      />
    </div>
  );
}

export { NetworkBranding };
const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  color: #6c757d;
  margin-bottom: 2.5rem;
`;
const StyledTitle = styled.div`
  font-weight: bold;

  h3 {
    font-size: 22px;
  }

  color: #000;
  text-align: left;
  width: 100%;
`;
const Label = styled.span`
  font-size: 16px;
  display: block;
  font-weight: 700;
  line-height: 18.75px;
  text-align: left;
  color: #343a40;
`;

const FileContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;

  .custom-file {
    max-width: 300px;
  }
`;
const StyledImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 37px;
  padding: 8px;

  img {
    object-fit: contain;
    width: 100%;
    height: 40px;
    max-height: 100%;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #b9b9c0;
  }
`;
