import React, { useState } from 'react';
import { Typography, Box, DataGrid, GridColDef, useGridApiRef } from '@zitcha/component-library';
import { AdSet } from 'v2/lib/api/ad-management';
import {
  ScheduleCell,
  MediaSpaceCell,
  PlacementCell,
  RateCell,
  BudgetCell,
  DiscountCell,
  SkuCodesCell,
  ActionCell,
} from './NewPlanAdSetsCellRenders';
import { ViewMode } from './PlanPage/ViewModeType';
import { AdSetModal, AdSetModalSaveHandler } from 'v2/components/AdSetModal/AdSetModal';
import { AdSetData, usePlan, usePlanDispatch } from './PlanContext';

// NewPlanAdSets component for creating new ad sets in a plan (for new plans use viewMode.NEW, for editing existing plans use viewMode.EDITING)
export const NewPlanAdSets = ({ viewMode }: NewPlanAdSetsProps): React.ReactElement => {
  const plan = usePlan();
  const dispatch = usePlanDispatch();
  const adSets = plan.newAdSets;

  const addAdSet = () => dispatch({ type: 'addAdSet' });
  const removeAdSet = (id: string) => dispatch({ type: 'removeAdSet', adSet: { id } });
  const updateAdSet = (adSet: AdSetData) => dispatch({ type: 'updateAdSet', adSet });

  const [editAdSet, setEditAdSet] = useState<AdSetData | undefined>(undefined);
  const apiRef = useGridApiRef();

  const handleSaveAdSet: AdSetModalSaveHandler = async (updatedFormAdSet) => {
    updateAdSet(updatedFormAdSet);
    setEditAdSet(undefined);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'schedule',
      headerName: 'Schedule',
      minWidth: 375,
      type: 'custom',
      editable: false,
      renderCell: (params) => <ScheduleCell updateAdSet={updateAdSet} retailerId={plan.retailerId || ''} {...params} />,
    },
    {
      field: 'mediaSpace',
      headerName: 'Media type ',
      minWidth: 270,
      type: 'custom',
      editable: false,
      renderCell: (params) => (
        <MediaSpaceCell updateAdSet={updateAdSet} retailerId={plan.retailerId || ''} {...params} />
      ),
    },
    {
      field: 'placement',
      headerName: 'Placement',
      minWidth: 250,
      type: 'custom',
      editable: false,
      renderCell: (params) => (
        <PlacementCell
          updateAdSet={updateAdSet}
          retailerId={plan.retailerId || ''}
          disabled={!params.row?.mediaSpace?.id}
          {...params}
        />
      ),
    },
    {
      field: 'skuCodes',
      headerName: 'SKU code(s)',
      minWidth: 100,
      type: 'custom',
      editable: false,
      renderCell: (params) => <SkuCodesCell {...params} />,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <RateCell {...params} retailerId={plan.retailerId || ''} />,
    },
    {
      field: 'discount',
      headerName: 'Discount',
      minWidth: 70,
      editable: false,
      renderCell: (params) => <DiscountCell {...params} retailerId={plan.retailerId || ''} />,
    },
    {
      field: 'budget',
      headerName: 'Budget',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <BudgetCell {...params} retailerId={plan.retailerId || ''} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'right',
      minWidth: 85,
      cellClassName: 'actions',
      align: 'right',
      type: 'actions',
      renderCell: (params) => (
        <ActionCell params={params} plan={plan} setEditAdSet={setEditAdSet} removeAdSet={removeAdSet} />
      ),
    },
  ];

  const title = viewMode === ViewMode.NEW ? 'Build your plan' : 'Create new ad sets';

  return (
    <>
      <Box mb={1}>
        <Typography variant='h5' color='text.primary'>
          {title}
        </Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        padding={2}
        sx={{ backgroundColor: 'white' }} //todo spacing
      >
        <DataGrid
          apiRef={apiRef}
          disableColumnFilter
          disableColumnSorting
          rows={adSets}
          columns={columns}
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          rowHeight={60}
          editMode='row'
          showAddRowFooter={true}
          addRowFooterCallback={addAdSet}
          processRowUpdate={(newRow: AdSet) => updateAdSet(newRow)}
        />
        {editAdSet && (
          <AdSetModal
            adSet={editAdSet}
            isOpen={!!editAdSet}
            isReadOnly={false}
            onClose={() => setEditAdSet(undefined)}
            saveHandler={handleSaveAdSet}
            retailerId={plan.retailerId || plan.owner?.id || null}
            brandId={plan.brandId || plan.supplier?.id || null}
          />
        )}
      </Box>
    </>
  );
};

export interface NewPlanAdSetsProps {
  viewMode: ViewMode;
}

export interface Ids {
  media_space_id: string | undefined;
  calendar_period_id: string | undefined;
  location_id: string | undefined;
}
