import React from 'react';
import {
  Box,
  PersonAddIcon,
  GridActionsCellItem,
  GridRowParams,
  EditIcon,
  VisibilityIcon,
  GridColDef,
  Alert,
  DataGrid,
  Chip,
  GridPaginationModel,
} from '@zitcha/component-library';
import { useFetchUsers } from './hooks/useFetchUsers';
import { PerPageEnum, RequestSearchUsers } from 'v2/lib/api/access-management';
import { RenderTeamRoleCell } from '../TeamManagement/TeamRoleCellRenderer';
import { UserManagementFilters } from './UserManagementFilters';
import { GenericTabHeader } from '../components/GenericTabHeader';
import { RenderUserManagementModal } from './RenderUserManagementModal';
import { useUserManagementModalState } from './hooks/useUserManagementModalState';
import { SettingsTableContainer } from '../components/SettingsTableContainer';

export const UserManagement = () => {
  const { users, isLoadingUsers, changeUsersPagination, usersError, usersPagination, setUsersSearch } = useFetchUsers();
  const { modalState, openModal, closeModal } = useUserManagementModalState();

  const dataGridPaginationModel = {
    pageSize: usersPagination.perPage ?? PerPageEnum.NUMBER_5,
    page: (usersPagination.currentPage ?? 1) - 1,
  };

  const columns: Array<GridColDef> = [
    {
      field: 'firstName',
      headerName: 'Name',
      minWidth: 100,
      renderCell: (params) => {
        return `${params.row.firstName} ${params.row.lastName}`;
      },
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      renderCell: (params) => params.value,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      renderCell: (params) => params.value,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'teamsRoles',
      headerName: 'Roles and teams',
      renderCell: (params) => <RenderTeamRoleCell {...params} />,
      minWidth: 400,
      flex: 2,
    },
    {
      field: 'isActive',
      headerName: 'Status',
      renderCell: (params) => {
        return params.value ? (
          <Chip label='Active' variant='filled' color='success' size='small' />
        ) : (
          <Chip label='Inactive' variant='filled' color='error' size='small' />
        );
      },
      minWidth: 100,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 96,
      cellClassName: 'actions',
      align: 'center',
      type: 'actions',
      getActions: ({ row }: GridRowParams) => {
        return [
          <GridActionsCellItem
            key='view'
            icon={<VisibilityIcon />}
            label='View'
            aria-label='view user'
            className='textPrimary'
            onClick={() => {
              openModal({ user: row, mode: 'view' });
            }}
            color='inherit'
          />,
          <GridActionsCellItem
            key='edit'
            icon={<EditIcon />}
            label='Edit'
            aria-label='edit user'
            className='textPrimary'
            onClick={() => {
              openModal({ user: row, mode: 'edit' });
            }}
            color='inherit'
          />,
        ];
      },
    },
  ];

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changeUsersPagination(model.page + 1, model.pageSize as PerPageEnum);
  };
  const handleFilterChange = (newFilter: Partial<RequestSearchUsers>) => {
    setUsersSearch((prevFilter) => ({ ...prevFilter, ...newFilter }));
  };

  return (
    <Box pt={2.5}>
      <GenericTabHeader
        title='User Management'
        subtitle='Manage users within your organisation.'
        buttonText='New User'
        buttonIcon={<PersonAddIcon />}
        onButtonClick={() => {
          openModal({ user: null, mode: 'create' });
        }}
      />
      <SettingsTableContainer>
        <UserManagementFilters onFilterChange={handleFilterChange} />
        {usersError && <Alert severity='error'>An error occurred while fetching users</Alert>}
        <DataGrid
          isRowSelectable={() => false}
          disableColumnFilter
          disableColumnSorting
          loading={isLoadingUsers}
          paginationMode={'server'}
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          rowCount={usersPagination?.total || 0}
          paginationModel={dataGridPaginationModel}
          onPaginationModelChange={handlePaginationUpdate}
          columns={columns}
          rows={users}
        />
      </SettingsTableContainer>
      <RenderUserManagementModal modalState={modalState} closeModal={closeModal} />
    </Box>
  );
};
