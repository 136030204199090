/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Zitcha System Management API
 * This is the API endpoint to manage systems in Zitcha.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  NetworkBrandingResource,
  UpdateNetworkBrandingRequest,
  UploadNetworkBrandingLogoBody,
  UploadNetworkBrandingLogoResponse,
} from '../model';
import { customInstance } from '../../../axios/axiosMainInstance';
import type { ErrorType, BodyType } from '../../../axios/axiosMainInstance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get network branding details
 */
export const getNetworkBranding = (
  retailerId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NetworkBrandingResource>(
    { url: `/system-management/v1/network-branding/${retailerId}`, method: 'GET', signal },
    options
  );
};

export const getGetNetworkBrandingQueryKey = (retailerId: string) => {
  return [`/system-management/v1/network-branding/${retailerId}`] as const;
};

export const getGetNetworkBrandingQueryOptions = <
  TData = Awaited<ReturnType<typeof getNetworkBranding>>,
  TError = ErrorType<void>,
>(
  retailerId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNetworkBranding>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNetworkBrandingQueryKey(retailerId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNetworkBranding>>> = ({ signal }) =>
    getNetworkBranding(retailerId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!retailerId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNetworkBranding>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNetworkBrandingQueryResult = NonNullable<Awaited<ReturnType<typeof getNetworkBranding>>>;
export type GetNetworkBrandingQueryError = ErrorType<void>;

/**
 * @summary Get network branding details
 */
export const useGetNetworkBranding = <TData = Awaited<ReturnType<typeof getNetworkBranding>>, TError = ErrorType<void>>(
  retailerId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNetworkBranding>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNetworkBrandingQueryOptions(retailerId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update network branding
 */
export const updateNetworkBranding = (
  updateNetworkBrandingRequest: BodyType<UpdateNetworkBrandingRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NetworkBrandingResource>(
    {
      url: `/system-management/v1/network-branding`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateNetworkBrandingRequest,
    },
    options
  );
};

export const getUpdateNetworkBrandingMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNetworkBranding>>,
    TError,
    { data: BodyType<UpdateNetworkBrandingRequest> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateNetworkBranding>>,
  TError,
  { data: BodyType<UpdateNetworkBrandingRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateNetworkBranding>>,
    { data: BodyType<UpdateNetworkBrandingRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return updateNetworkBranding(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateNetworkBrandingMutationResult = NonNullable<Awaited<ReturnType<typeof updateNetworkBranding>>>;
export type UpdateNetworkBrandingMutationBody = BodyType<UpdateNetworkBrandingRequest>;
export type UpdateNetworkBrandingMutationError = ErrorType<void>;

/**
 * @summary Update network branding
 */
export const useUpdateNetworkBranding = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNetworkBranding>>,
    TError,
    { data: BodyType<UpdateNetworkBrandingRequest> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateNetworkBranding>>,
  TError,
  { data: BodyType<UpdateNetworkBrandingRequest> },
  TContext
> => {
  const mutationOptions = getUpdateNetworkBrandingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Upload network branding logo
 */
export const uploadNetworkBrandingLogo = (
  uploadNetworkBrandingLogoBody: BodyType<UploadNetworkBrandingLogoBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = new FormData();
  if (uploadNetworkBrandingLogoBody.network_logo !== undefined) {
    formData.append('network_logo', uploadNetworkBrandingLogoBody.network_logo);
  }

  return customInstance<UploadNetworkBrandingLogoResponse>(
    {
      url: `/system-management/v1/network-branding/upload`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getUploadNetworkBrandingLogoMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadNetworkBrandingLogo>>,
    TError,
    { data: BodyType<UploadNetworkBrandingLogoBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadNetworkBrandingLogo>>,
  TError,
  { data: BodyType<UploadNetworkBrandingLogoBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadNetworkBrandingLogo>>,
    { data: BodyType<UploadNetworkBrandingLogoBody> }
  > = (props) => {
    const { data } = props ?? {};

    return uploadNetworkBrandingLogo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadNetworkBrandingLogoMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadNetworkBrandingLogo>>
>;
export type UploadNetworkBrandingLogoMutationBody = BodyType<UploadNetworkBrandingLogoBody>;
export type UploadNetworkBrandingLogoMutationError = ErrorType<void>;

/**
 * @summary Upload network branding logo
 */
export const useUploadNetworkBrandingLogo = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadNetworkBrandingLogo>>,
    TError,
    { data: BodyType<UploadNetworkBrandingLogoBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadNetworkBrandingLogo>>,
  TError,
  { data: BodyType<UploadNetworkBrandingLogoBody> },
  TContext
> => {
  const mutationOptions = getUploadNetworkBrandingLogoMutationOptions(options);

  return useMutation(mutationOptions);
};
