import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { selectOwnerOrganisation } from '../../../features/order_form/orderFormSlice';

export const useEarliestStartDateTime = (overrides: { organisation?: object | null } = {}) => {
  const organisation = useSelector(selectOwnerOrganisation) ?? overrides.organisation;
  const retailerTimezone = organisation?.settings?.['timezone'] ?? null;
  const minimumReviewTimeInHours = organisation?.settings?.['minimumReviewTime'] ?? null;
  const validStartDateTime = getEarliestStartDate(retailerTimezone, minimumReviewTimeInHours);

  return validStartDateTime;
};

export const getEarliestStartDate = (
  retailerTimezone: string,
  minimumReviewTimeInHours: number,
  browserDateTime: moment.Moment = moment()
): moment.Moment => {
  if (retailerTimezone && minimumReviewTimeInHours) {
    const nowInRetailerTimezone = browserDateTime.tz(retailerTimezone);
    const nowWithMRT = nowInRetailerTimezone.clone().add(minimumReviewTimeInHours, 'hours');

    return nowWithMRT;
  }
  return browserDateTime;
};
