import React from 'react';
import { Typography, Box } from '@zitcha/component-library';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const EmptyState = ({ tab = 'clashes' }: { tab?: string }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      gap={1}
      padding={2}
      sx={{
        backgroundColor: '#FBFBFB',
        height: '11.5rem',
        borderRadius: '4px',
      }}
    >
      <CheckCircleIcon
        fontSize='large'
        color='success'
        sx={{
          fontSize: '4.5rem',
        }}
      />
      <Typography variant='body2'>
        <strong>No {tab.toLowerCase()} actions</strong>
      </Typography>
    </Box>
  );
};
