/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Zitcha Ad Management API
 * This is the API endpoint to manage ads.
 * OpenAPI spec version: 1.0.0
 */

/**
 * The type of entity to which the discount applies.
 */
export type DiscountForType = (typeof DiscountForType)[keyof typeof DiscountForType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiscountForType = {
  supplier: 'supplier',
  media_space: 'media_space',
  bundle: 'bundle',
  period: 'period',
  location: 'location',
  plan: 'plan',
  ad_set: 'ad_set',
} as const;
