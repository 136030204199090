import React from 'react';
import { AutocompleteFactory, AutocompleteFactoryProps } from 'v2/components/Autocomplete/AutocompleteFactory';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/session/sessionSlice';
import { OrganisationTypeEnum } from 'v2/constants/accessManagementConstants';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';

type ScopedOrganisationsAutocompleteProps = Omit<AutocompleteFactoryProps, 'options'> & {
  showLabel?: boolean;
  placeholderText?: string;
};

export const ScopedOrganisationsAutocomplete: React.FC<ScopedOrganisationsAutocompleteProps> = (props) => {
  const user = useSelector(selectUser);
  const { showLabel = true, placeholderText } = props;
  const isRetailer = user?.active_organisation?.is_retailer;

  const { organisations: organisationsWithExtraData } = useScopedOrganisations({});

  const organisations = organisationsWithExtraData.map(({ id, name }) => ({
    id: id || '',
    name: name || '',
  })) as Array<{ id: string; name: string }>;

  const defaultLabelText = showLabel
    ? isRetailer
      ? OrganisationTypeEnum.Suppliers
      : OrganisationTypeEnum.Retailers
    : '';
  const defaultPlaceholderText = placeholderText ? placeholderText : `All ${defaultLabelText}`;

  return (
    <AutocompleteFactory
      labelText={props.labelText || defaultLabelText}
      placeholderText={props.placeholderText || defaultPlaceholderText}
      {...props}
      options={organisations}
    />
  );
};
