import React, { ReactNode, useEffect, useMemo } from 'react';
import { Control, Controller, ControllerRenderProps, useWatch } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  InfoIcon,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@zitcha/component-library';
import { AdSet, AdSetDiscount, useGetBundle, useGetBundles, useGetCalendarPeriods } from 'v2/lib/api/ad-management';
import { PlacementAutocomplete } from '../PlacementList/PlacementAutocomplete';
import { SkuCodes } from './SkuCodes';
import { formatDisplayDate } from 'helpers/DateHelpers';
import { RenderCurrency } from '../RenderCurrency/RenderCurrency';
import { MediaSpace, useGetMediaSpaces } from 'v2/lib/api/inventory';
import { AdSetFormValues } from './AdSetModal';
import { DiscountSelector } from '../Discounts/DiscountSelector';
import { DiscountData } from '../Discounts/DiscountsModal';
import { calculateBudget } from 'v2/features/Planning/NewPlanAdSetsCellRenders';
import { CreateDiscountRequestType } from 'v2/lib/api/ad-management/model/createDiscountRequestType';

export type DetailsTableProps = {
  control: Control<AdSetFormValues>;
  setValue: (name: string, value: unknown) => void;
  existingAdSet?: AdSet;
  retailerId: string;
  isReadOnly: boolean;
  setLoadingBundle?: (loading: boolean) => void; // if parent component needs to know when bundle is loading
  hadBundleError?: boolean;
};

const ShowDiscountSelector = ({
  discount,
  readOnly,
  onChange,
}: {
  discount: AdSetDiscount;
  readOnly: boolean;
  onChange: (data: DiscountData) => void;
}) => {
  return (
    <DiscountSelector
      onChange={(data) => onChange(data)}
      variant='standard'
      row={{ type: discount?.type || CreateDiscountRequestType.percentage, discount: String(discount?.value || '0') }}
      readOnly={readOnly}
    />
  );
};

export const DetailsTable: React.FC<DetailsTableProps> = ({
  control,
  setValue,
  existingAdSet,
  retailerId,
  isReadOnly,
  setLoadingBundle,
  hadBundleError,
}) => {
  const bundleId = existingAdSet?.bundleIds?.[0] || '';
  const {
    data: existing_bundle,
    isPending,
    isSuccess: bundleFound,
  } = useGetBundle(bundleId, { query: { refetchOnMount: false } });

  const { data: mediaSpacesData, isLoading: isLoadingMediaSpaces } = useGetMediaSpaces({ organisation_id: retailerId });
  const mediaSpaces: Array<MediaSpace> = useMemo(() => mediaSpacesData?.data || [], [mediaSpacesData]);

  const { data: schedules, isLoading: isLoadingSchedules } = useGetCalendarPeriods({ organisation_id: retailerId });

  const [schedule, mediaSpace, placement, ads, rate, discount] = useWatch({
    control,
    name: ['schedule', 'mediaSpace', 'placement', 'ads', 'rate', 'discount'],
  });

  const { data: bundle, isPending: isGetBundlesPending } = useGetBundles(
    {
      organisation_id: retailerId,
      media_space_id: mediaSpace?.id,
      calendar_period_id: schedule?.id,
      location_id: placement?.id,
    },
    {
      query: {
        queryKey: ['bundles', retailerId, mediaSpace?.id, schedule?.id, placement?.id],
        enabled: !!(mediaSpace?.id && schedule?.id && placement?.id),
      },
    }
  );

  useEffect(() => {
    setValue('budget', calculateBudget(rate, discount));
  }, [rate, discount]);

  useEffect(() => {
    if (!isPending && !schedule && existing_bundle?.data?.calendarPeriod) {
      setValue('schedule', existing_bundle.data.calendarPeriod);
    }
    if (!isPending && !placement && existing_bundle?.data?.locations?.[0]) {
      setValue('placement', existing_bundle.data.locations[0]);
    }
    if (!isPending && !mediaSpace && existing_bundle?.data?.mediaSpace) {
      const preselectedMediaSpace = mediaSpaces.find((adType) => adType?.id === existing_bundle.data.mediaSpace.id);
      if (preselectedMediaSpace) {
        setValue('mediaSpace', preselectedMediaSpace);
      }
    }
    if (!isPending && !rate && existing_bundle?.data?.price) {
      setValue('rate', existing_bundle.data.price);
    }
  }, [existing_bundle, isPending, bundleFound, mediaSpaces, setValue]);

  useEffect(() => {
    const bundleData = bundle?.data?.[0];
    const price = Number(bundleData?.price ?? 0);
    const bundleId = bundleData?.id ?? null;

    setValue('rate', price);
    setValue('bundleId', bundleId);
    setLoadingBundle?.(isGetBundlesPending);
  }, [isGetBundlesPending, bundle, setValue]);

  const updateDiscountData = (data: DiscountData) => {
    let updatedDiscount;
    if (!discount?.isAdSet) {
      updatedDiscount = { value: Number(data.discount), type: data.type } as AdSetDiscount;
    } else {
      updatedDiscount = { ...discount, value: Number(data.discount), type: data.type } as AdSetDiscount;
    }

    setValue('discount', updatedDiscount);
  };

  return (
    <Stack direction='column' spacing={2}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label='table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '25%' }}>Schedule</TableCell>
                <TableCell sx={{ width: '25%' }}>Media type</TableCell>
                <TableCell sx={{ width: '25%' }}>Placement</TableCell>
                <TableCell sx={{ width: '25%' }}>SKU code(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Row>
                <TableCell>
                  <Controller
                    name='schedule'
                    control={control}
                    render={({ field }: { field: ControllerRenderProps }) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
                      const { ref: _ref, ...rest } = { ...field };
                      return (
                        <Autocomplete
                          {...rest}
                          size='medium'
                          fullWidth
                          getOptionLabel={(data) =>
                            `${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`
                          }
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          renderInput={(params) => <TextField {...params} variant='standard' error={hadBundleError} />}
                          options={schedules?.data ?? []}
                          loading={isLoadingSchedules}
                          onChange={(_, newValue) => field.onChange(newValue)}
                          disabled={isReadOnly}
                        />
                      );
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name='mediaSpace'
                    control={control}
                    render={({ field }: { field: ControllerRenderProps }) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
                      const { ref, ...rest } = field;
                      return (
                        <Autocomplete
                          {...rest}
                          fullWidth
                          getOptionLabel={(data) => data?.name}
                          renderInput={(params) => <TextField {...params} variant='standard' error={hadBundleError} />}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          options={mediaSpaces}
                          loading={isLoadingMediaSpaces}
                          onChange={(_, newValue) => field.onChange(newValue)}
                          disabled={isReadOnly}
                        />
                      );
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name='placement'
                    control={control}
                    render={({ field }) => (
                      <PlacementAutocomplete
                        onChange={field.onChange}
                        value={field.value}
                        fullWidth={true}
                        mediaSpaceId={mediaSpace?.id}
                        retailerId={retailerId}
                        disabled={isReadOnly}
                        textFieldProps={{ variant: 'standard', hasError: hadBundleError }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <SkuCodes ads={ads} isReadOnly={isReadOnly || Boolean(hadBundleError)} />
                </TableCell>
              </Row>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label='table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '33.3%' }}>
                  Rate{' '}
                  <Tooltip title='Ad set price' placement='right-end'>
                    <InfoIcon color='primary' />
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: '33.3%' }}>Discount</TableCell>
                <TableCell sx={{ width: '33.3%' }}>
                  Budget{' '}
                  <Tooltip title='ad set price minus the discount' placement='right-end'>
                    <InfoIcon color='primary' />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Row>
                <TableCell>
                  <Controller
                    name='rate'
                    control={control}
                    render={({ field }) => (
                      <RenderCurrency
                        amount={field.value}
                        isReadOnly={isReadOnly || Boolean(hadBundleError)}
                        organisationId={retailerId}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name='discount'
                    control={control}
                    render={({ field }) => (
                      <ShowDiscountSelector
                        discount={field.value}
                        readOnly={isReadOnly || Boolean(hadBundleError)}
                        onChange={(data) => updateDiscountData(data)}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name='budget'
                    control={control}
                    render={({ field }) => (
                      <RenderCurrency
                        amount={field.value}
                        isReadOnly={isReadOnly || Boolean(hadBundleError)}
                        organisationId={retailerId}
                      />
                    )}
                  />
                </TableCell>
              </Row>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
};

const Row = ({ children }: { children: ReactNode }) => {
  return (
    <TableRow
      sx={{
        border: 'none',
        borderTop: 'solid rgba(0, 0, 0, 0.12)',
        borderBottom: 'solid rgba(0, 0, 0, 0.12)',
        borderWidth: 'thin',
        backgroundColor: '#FBFBFB',
      }}
    >
      {children}
    </TableRow>
  );
};
