import React from 'react';
import { Alert, CircularProgress, CloseIcon, IconButton, Snackbar, CheckIcon } from '@zitcha/component-library';
import { Notification } from './ProcessingContext';
import { AlertTitle } from '@mui/material';

export const ProcessSnackbarNotification: React.FC<{
  notification: Notification;
  onClose: () => void;
}> = ({ notification, onClose }) => {
  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={notification.duration}
      onClose={onClose}
    >
      <Alert
        severity={notification.type}
        variant='filled'
        aria-label={`${notification.type}: ${notification.title || ''} ${notification.message}`}
        role='alert'
        iconMapping={{
          success: <CheckIcon fontSize='inherit' />,
        }}
        icon={notification.type === 'info' ? false : undefined}
        action={
          notification.dismissable ? (
            <IconButton aria-label='close' color='inherit' size='small' onClick={onClose}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
          ) : undefined
        }
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          '.MuiAlert-message': {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        {notification.title && <AlertTitle>{notification.title}</AlertTitle>}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {notification.type === 'info' && (
            <CircularProgress
              size={20}
              color='inherit'
              sx={{
                marginRight: '1rem',
              }}
            />
          )}
          <div style={{ whiteSpace: 'pre-line' }}>{notification.message}</div>
        </div>
      </Alert>
    </Snackbar>
  );
};
