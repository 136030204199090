import React, { useState } from 'react';
import type { Location } from 'v2/lib/api/ad-management';
import { Autocomplete, TextField } from '@zitcha/component-library';
import { PlacementAutocompletePopper } from './components/PlacementAutocompletePopper';

interface PlacementAutocompleteProps {
  multiple?: boolean;
  value: Array<Location>;
  onChange: (value: Location | Array<Location>) => void;
  retailerId: string;
  mediaSpaceId?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  textFieldProps?: {
    variant?: 'standard' | 'outlined' | 'filled';
    label?: string;
    placeholder?: string;
    hasError?: boolean;
  };
}

export const PlacementAutocomplete = ({
  multiple = false,
  value,
  onChange,
  retailerId,
  mediaSpaceId,
  disabled = false,
  fullWidth = false,
  textFieldProps = {
    variant: 'outlined',
    label: '',
    hasError: false,
  },
}: PlacementAutocompleteProps) => {
  const [popperOpen, setPopperOpen] = useState(false);

  const handlePopperOpen = () => {
    setPopperOpen(true);
  };
  const handleChange = (_, newValue) => {
    // clears the selection only
    onChange(newValue);
  };

  const handlePopperClose = () => {
    setPopperOpen(false);
  };

  const formatValueForAutocomplete = (value: Location | Array<Location>) => {
    if (multiple && Array.isArray(value)) {
      if (!value?.length) {
        return [];
      }
      return value.map((v) => `${v?.locationType?.name} : ${v?.name}`);
    } else if (!multiple) {
      if (!value) {
        return null;
      }
      return `${(value as Location)?.locationType?.name} : ${(value as Location)?.name}`;
    }
  };

  return (
    <Autocomplete
      multiple={multiple}
      readOnly={disabled}
      data-testid='placement-autocomplete'
      options={[]}
      value={formatValueForAutocomplete(value)}
      onChange={handleChange}
      onOpen={handlePopperOpen}
      open={popperOpen}
      getOptionLabel={(option) => String(option)}
      sx={{ width: fullWidth ? '100%' : '300px' }}
      PopperComponent={(props) => (
        <PlacementAutocompletePopper
          {...props}
          onClose={handlePopperClose}
          setSelectedValues={onChange}
          multiple={multiple}
          value={value}
          mediaSpaceId={mediaSpaceId}
          retailerId={retailerId}
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={textFieldProps.variant}
          label={textFieldProps.label}
          placeholder={textFieldProps.placeholder}
          disabled={disabled}
          error={textFieldProps.hasError}
        />
      )}
    />
  );
};
