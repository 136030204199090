import React, { useState } from 'react';
import { AutocompleteFactory, AutocompleteFactoryProps } from 'v2/components/Autocomplete/AutocompleteFactory';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';
import { useGetWallets } from 'v2/lib/api/wallet-management';

type WalletAutocompleteProps = Omit<AutocompleteFactoryProps, 'options'>;

export const WalletAutocomplete: React.FC<WalletAutocompleteProps> = (props) => {
  const [walletQuery, setWalletQuery] = useState('');
  const { data, isLoading } = useGetWallets({
    q: walletQuery,
    page: 1,
    perPage: 10,
  });
  const debouncedPlanQuery = useDebounceSetter(setWalletQuery);

  return (
    <AutocompleteFactory
      labelText={props.labelText || 'Wallets'}
      placeholderText={props.placeholderText || 'All wallets'}
      isLoading={isLoading}
      {...props}
      options={data?.data || []}
      debouncedQuery={debouncedPlanQuery}
    />
  );
};
