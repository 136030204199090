import React from 'react';
import { Box, Typography } from '@zitcha/component-library';

export const LoadingIndicator = ({ borderleft = false }: { borderleft?: boolean }) => {
  return (
    <Box p={2} minWidth={360} minHeight={240} borderLeft={borderleft ? '1px solid #E0E0E0' : 'none'}>
      <Typography variant='subtitle2'>Loading...</Typography>
    </Box>
  );
};
