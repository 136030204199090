import React, { useEffect, useMemo, useState } from 'react';
import { Alert } from '@zitcha/component-library';
import { Helmet } from 'react-helmet-async';
import Wallet from 'v2/Types/Wallets';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBrandWallets } from 'v2/lib/hooks/useWallets';
import { NewPlanAdSets } from '../NewPlanAdSets';
import { calculateTotalBudget } from 'v2/utils/newPlanUtils';
import { ViewMode } from '../PlanPage/ViewModeType';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { initialPlanState, PlanData, usePlan, usePlanDispatch } from '../PlanContext';
import { PlanProvider } from '../PlanProvider';
import { useNewPlanActions } from 'v2/lib/hooks/useNewPlanActions';
import { PlanOwner, PlanSupplier } from 'v2/lib/api/ad-management';
import { OptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';
import { PlanHeader } from './PlanHeader';
import { PlanDetails } from './PlanDetails';
import { useNetworkBrandingContext } from 'app/OrganisationSettings/useNetworkBranding';

export function NewPlanView(): React.ReactElement {
  const { state } = useLocation();
  const organisation = useUserOrganisation();
  const initialPlan: PlanData = {
    ...initialPlanState,
    ...state,
    ...(organisation.is_retailer ? { retailerId: organisation.id } : { brandId: organisation.id }),
  };

  return (
    <>
      <Helmet>
        <title>New plan - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <PlanProvider data={initialPlan}>
        <NewPlanViewContent />
      </PlanProvider>
    </>
  );
}

export function NewPlanViewContent(): React.ReactElement {
  const plan = usePlan();
  const dispatch = usePlanDispatch();
  const navigate = useNavigate();
  const newAdSets = plan.newAdSets;
  const organisations = useScopedOrganisations({}).organisations;
  const organisation = useUserOrganisation();
  const { setRetailerId } = useNetworkBrandingContext();

  let selectedOrg = null;

  if (organisation.is_retailer) {
    selectedOrg = organisations.find((o) => o.id === plan.brandId);
  } else {
    selectedOrg = organisations.find((o) => o.id === plan.retailerId);
  }

  const [selectedOrganisation, setSelectedOrganisation] = useState<OptionsType | null>(
    selectedOrg ? { id: selectedOrg.id ?? '', name: selectedOrg.name ?? '' } : null
  );

  const [remainingWalletBalance, setRemainingWalletBalance] = useState<number>(0);
  const { loading: savingPlan, createAndSavePlan, createAndProposePlan } = useNewPlanActions();
  const { wallets, isLoading: isWalletsLoading } = useBrandWallets(
    organisation.is_retailer,
    plan.retailerId,
    plan.brandId
  );

  const cancelPlan = () => navigate('/plans-ad-sets');

  const updatePlan = (updates: Partial<PlanData>) => {
    dispatch({ type: 'updatePlan', plan: { ...updates } });
  };

  const selectOrganisation = (newSelectedOrg: OptionsType | null) => {
    setSelectedOrganisation(newSelectedOrg);
    const newSelectedOrgId = String(newSelectedOrg?.id) || null;
    if (newSelectedOrg) {
      if (organisation.is_retailer) {
        updatePlan({
          supplier: { id: newSelectedOrgId, name: newSelectedOrg.name } as PlanSupplier,
          brandId: newSelectedOrgId,
          wallet: null,
          walletId: null,
        });
      } else {
        updatePlan({
          owner: { id: newSelectedOrgId, name: newSelectedOrg.name } as PlanOwner,
          retailerId: newSelectedOrgId,
          wallet: null,
          walletId: null,
        });
      }
    } else {
      updatePlan({
        supplier: null,
        brandId: null,
        owner: null,
        retailerId: null,
        wallet: null,
        walletId: null,
      });
    }
  };

  const selectWallet = (wallet: Wallet) => updatePlan({ wallet, walletId: wallet.id });
  const updatePlanName = (name: string) => updatePlan({ name });

  if (!organisation.is_retailer) {
    plan.brandId = organisation.id;
  } else {
    plan.retailerId = organisation.id;
  }

  useEffect(() => {
    if (plan.walletId) {
      const wallet = wallets.find((wallet: Wallet) => wallet.id === plan.walletId);
      setRemainingWalletBalance(wallet?.available_balance ?? 0);
    } else {
      setRemainingWalletBalance(0);
    }
  }, [plan.walletId]);

  const saveEnabled = useMemo(() => {
    const allAdSetsHaveBundleId = newAdSets.every((adSet) => adSet?.bundleIds && adSet.bundleIds?.length > 0);
    const hasPlanName = plan.name && plan.name.trim().length > 0;
    return (plan.brandId && plan.walletId && allAdSetsHaveBundleId && hasPlanName) || false;
  }, [plan, newAdSets]);

  const planValue = useMemo(() => {
    return calculateTotalBudget(newAdSets);
  }, [newAdSets]);

  const handleProposePlan = async () => {
    try {
      await createAndProposePlan(plan);
    } catch (error) {
      return error;
    }
  };

  const handleSavePlan = async () => {
    try {
      await createAndSavePlan(plan);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    setRetailerId(selectedOrganisation?.id || organisation.id);
  }, [selectedOrganisation?.id]);

  return (
    <>
      <Helmet>
        <title>New plan - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <PlanHeader
        planName={plan.name}
        updatePlanName={updatePlanName}
        cancelPlan={cancelPlan}
        savePlan={handleSavePlan}
        proposePlan={handleProposePlan}
        savingPlan={savingPlan}
        saveEnabled={saveEnabled}
      />

      <PlanDetails
        isRetailer={organisation.is_retailer}
        selectedOrganisation={selectedOrganisation}
        selectOrganisation={selectOrganisation}
        wallets={wallets}
        selectedWallet={wallets.find((wallet: Wallet) => wallet?.id === plan.walletId) ?? null}
        selectWallet={selectWallet}
        isWalletsLoading={isWalletsLoading}
        discounts={plan.newDiscounts}
        updatePlan={updatePlan}
        planValue={planValue}
        remainingWalletBalance={remainingWalletBalance}
        retailerId={plan.retailerId}
      />

      {plan.retailerId ? (
        <Alert
          variant='filled'
          severity='info'
          sx={{
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          Schedule, media type and placement are the minimum inputs required to request an ad set.
        </Alert>
      ) : (
        <Alert
          variant='filled'
          severity='error'
          sx={{
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          Select a Retailer to begin building your plan.
        </Alert>
      )}
      <NewPlanAdSets viewMode={ViewMode.NEW} />
    </>
  );
}
