import React from 'react';
import { Column } from './Column';
import { Typography } from '@zitcha/component-library';

export const NoSearchResults = ({ searchTerm, bordered = true }: { searchTerm: string; bordered?: boolean }) => {
  return (
    <Column bordered={bordered}>
      <Typography variant='subtitle2' sx={{ padding: 2 }}>
        No results found for &quot;<strong>{searchTerm}</strong>&quot;
      </Typography>
    </Column>
  );
};
