import React from 'react';
import { Box, Grid } from '@zitcha/component-library';
import { Location } from '../../../lib/api/ad-management';
import { FilteredLocationTypeList } from './FilteredLocationTypes';
import { LocationList } from './LocationList';
import { FilterBar } from './FilterBar';
import { PlacementSelectorProvider } from '../context/PlacementSelectorProvider';
import { ConfirmSelection } from './ConfirmSelection';

type OnConfirmPlacementSelection = {
  (locations: Array<Location>): void;
  (location: Location | undefined): void;
};
export interface PlacementListProps {
  onConfirmPlacementSelection: OnConfirmPlacementSelection;
  mediaSpaceId?: string | undefined;
  retailerId: string;
  onClose?: () => void; //<- can be use to clear the selection from the consumming form,
  multiple?: boolean;
  value?: Location | Array<Location>;
}

export const PlacementList = ({
  onConfirmPlacementSelection,
  onClose,
  mediaSpaceId,
  retailerId,
  multiple = false,
  value,
}: PlacementListProps) => {
  const handleConfirmSelection = (locations: Array<Location>) => {
    if (multiple) {
      onConfirmPlacementSelection(locations);
    } else {
      onConfirmPlacementSelection(locations?.at(0));
    }
    onClose?.();
  };
  return (
    <PlacementSelectorProvider
      initialState={{
        locations: value ? (Array.isArray(value) ? value : [value]) : [],
      }}
      retailerId={retailerId}
      multiple={multiple}
    >
      <Grid container spacing={0} width='100%' display='flex' flexDirection='column' minHeight={240}>
        <FilterBar
          onClose={() => {
            onClose?.();
          }}
        />
        <Box display='flex' flexDirection='row' height='240'>
          <FilteredLocationTypeList mediaSpaceId={mediaSpaceId} />
          <LocationList />
        </Box>
        <ConfirmSelection onConfirm={handleConfirmSelection} />
      </Grid>
    </PlacementSelectorProvider>
  );
};
