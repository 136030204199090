import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

interface FaviconMapping {
  [key: string]: string;
  default: string;
}

const HOURS_TO_REFRESH_FAVICON = 2;

// If adding a new domain, increment the favicon version below
const faviconMapping = {
  'ocado.uk.zitcha.app': 'favicon-ocado.png',
  'ocado.au.zitcha.dev': 'favicon-ocado.png',
  'uk.zitcha.app': 'favicon.ico',
  default: 'favicon.ico',
} satisfies FaviconMapping;

const DynamicFavicon: React.FC = () => {
  const faviconPath = useMemo(() => {
    // Cache busting every HOURS_TO_REFRESH_FAVICON hours or so by using the current system time
    const systemTimeHours = Math.trunc(new Date().getTime() / (60 * 1000 * HOURS_TO_REFRESH_FAVICON));
    const domain = window.location.hostname as keyof typeof faviconMapping;
    const path = faviconMapping[domain] || faviconMapping.default;
    const cacheBuster = `v=${systemTimeHours}`;
    return `${process.env.PUBLIC_URL}${path}?${cacheBuster}`;
  }, []);

  return (
    <Helmet>
      <link rel='icon' href={faviconPath} />
    </Helmet>
  );
};

export default DynamicFavicon;
