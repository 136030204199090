import React from 'react';
import { Box, Button, CheckIcon } from '@zitcha/component-library';
import UndoIcon from '@mui/icons-material/Undo';
import { ClashManagementModalMode } from '../types/ClashManagementModalMode';
import { useClashManagementContext } from '../context/ClashManagementContext';

interface ModalFooterButtonsProps {
  closeClashManagementModal: () => void;
}

const ModalFooterButtons = ({ closeClashManagementModal }: ModalFooterButtonsProps) => {
  const { state, actions } = useClashManagementContext();
  if (!state || !actions) {
    return null;
  }
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
      <Button
        variant='text'
        color='error'
        aria-label='Close without saving'
        className='w-5/12 mr-3'
        onClick={closeClashManagementModal}
        disabled={state.isResolvingClash}
      >
        CLOSE WITHOUT SAVING
      </Button>
      <div className='twd-flex'>
        {state.viewMode === ClashManagementModalMode.REVIEW ? (
          <Button
            variant='text'
            color='primary'
            size='large'
            aria-label='Back to editing'
            onClick={actions.returnToEditing}
            disabled={state.isResolvingClash}
          >
            BACK TO EDITING
          </Button>
        ) : (
          <Button
            onClick={actions.resetClashResolution}
            variant='text'
            color='primary'
            size='large'
            aria-label='Reset'
            disabled={state.isResolvingClash}
          >
            <UndoIcon />
            Reset
          </Button>
        )}
        <div className='twd-w-[20px]' />
        {state.viewMode === ClashManagementModalMode.EDIT ? (
          <Button
            variant='contained'
            color='primary'
            size='large'
            aria-label='Review changes'
            onClick={actions.prepareReviewChanges}
            disabled={state.modifiedAdSets.length === 0 || state.isResolvingClash}
          >
            REVIEW CHANGES
          </Button>
        ) : (
          <Button
            variant='contained'
            color='success'
            size='large'
            aria-label='Resolve clash'
            onClick={actions.resolveClash}
            disabled={state.modifiedAdSets.length === 0 || state.isResolvingClash}
            loading={state.isResolvingClash}
          >
            {!state.isResolvingClash && <CheckIcon className='twd-text-green-500 twd-mr-2' />}
            {'RESOLVE CLASH'}
          </Button>
        )}
      </div>
    </Box>
  );
};

export default ModalFooterButtons;
