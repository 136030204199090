import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConnectedOrderFormChrome from '../features/order_form/ConnectedOrderFormChrome';
import OrderAdForm from '../features/order_form/containers/OrderAdForm';
import {
  availableWebSpaces,
  clearOrder,
  orderUpdated,
  updateOrderFormOrganisations,
} from '../features/order_form/orderFormSlice';
import axios from '../axios';
import AdsThrobber from 'features/common/throbbers/AdsThrobber';

const getOrder = async (order_id) => {
  return axios.get(`/order/${order_id}`);
};
const ViewOrder = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { order_id } = useParams();

  useEffect(() => {
    dispatch(clearOrder());
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    getOrder(order_id)
      .then((order) => {
        dispatch(orderUpdated(order?.data?.data));
        dispatch(updateOrderFormOrganisations());
        dispatch(availableWebSpaces());
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, order_id]);

  if (loading)
    return (
      <div className='text-center d-flex align-items-center justify-content-center' style={{ height: '90vh' }}>
        <AdsThrobber />
      </div>
    );

  return (
    <>
      <Helmet>
        <title>View Order - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container fluid className='pt-4'>
        <Row>
          <Col>
            <ConnectedOrderFormChrome>
              <OrderAdForm readOnly />
            </ConnectedOrderFormChrome>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewOrder;
