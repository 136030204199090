import React from 'react';
import { Box, GridColDef, Typography, DataGrid, GridRenderCellParams } from '@zitcha/component-library';
import { HorizontalPagination } from './HorizontalPagination';
import { useInventoryBundles } from './hooks/useInventoryBundles';
import { InventoryTableFilters } from './InventoryTableFilters';
// import { RenderCurrency } from './cellrenderers/RenderCurrency';
import { RenderPlacemementCell } from './cellrenderers/RenderPlacemementCell';

export const InventoryTable = () => {
  const {
    dataGridPaginationModel,
    handlePaginationUpdate,
    inventoryReport,
    calendarPeriodsHeaders,
    calendarPeriodPage,
    setCalendarPeriodPage,
    calendarPeriodsData,
    loadingCalendarPeriods,
    rows,
    loadingReport,
    setFilters,
  } = useInventoryBundles();

  const columns: Array<GridColDef> = [
    {
      field: 'mediaType',
      headerName: 'Media type',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant='caption'>{params.value || ''}</Typography>;
      },
      headerClassName: 'inventory-table-left-header',
    },
    {
      field: 'placement',
      headerName: 'Placement',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return <RenderPlacemementCell {...params} />;
      },
    },
    ...(calendarPeriodsHeaders || [
      { field: 'no-data', headerName: '', flex: 1, headerClassName: 'inventory-table-right-header' },
    ]),
  ];

  return (
    <Box>
      <Box
        padding={2}
        sx={{
          backgroundColor: 'white',
          minWidth: '100%',
          borderRadius: '4px',
        }}
      >
        <InventoryTableFilters
          onFilterChange={(filters) => {
            setFilters((prev) => ({ ...prev, ...filters }));
          }}
        />
        <HorizontalPagination
          currentPage={calendarPeriodPage}
          lastPage={calendarPeriodsData?.meta.last_page || 1}
          onNextClick={() => setCalendarPeriodPage((prev) => prev + 1)}
          onPreviousClick={() => setCalendarPeriodPage((prev) => prev - 1)}
        />
        <DataGrid
          disableColumnSorting
          disableColumnFilter
          rows={rows || []}
          columns={columns}
          paginationMode='server'
          paginationModel={dataGridPaginationModel}
          autoHeight
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          rowCount={inventoryReport?.meta.total || 0}
          loading={loadingReport || loadingCalendarPeriods}
          sx={{
            width: '100%',
            minHeight: '300px',
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              height: '2.25rem !important',
            },
            '& .MuiDataGrid-cell': {
              minHeight: '3.35rem ',
              maxHeight: '3.35rem ',
            },
          }}
          onPaginationModelChange={handlePaginationUpdate}
        />
      </Box>
    </Box>
  );
};
