import { useQueryClient } from '@tanstack/react-query';
import { type AdSet, useTransitionAdSet, type SearchAdSets200 } from '../api/ad-management';
import { useProcessingContext } from 'v2/features/GlobalNotifications/ProcessingContext';

const AdsetQueryKeys = ['searchAdSets'];

export const useToggleAdset = () => {
  const queryClient = useQueryClient();
  const { startProcess, finishProcess } = useProcessingContext();

  const { mutate, isSuccess, isPending } = useTransitionAdSet({
    mutation: {
      onMutate: async (data) => {
        const processId = startProcess(`Toggling ad set status to ${data.data.status}`);
        // get last used key that includes searchAdSets
        const queryKeys = queryClient.getQueryCache().findAll({
          queryKey: AdsetQueryKeys,
        });
        const lastQueryKey = queryKeys[queryKeys.length - 1];
        await queryClient.cancelQueries({ queryKey: lastQueryKey.queryKey });
        const previousValue = await queryClient.getQueryData(lastQueryKey.queryKey);
        queryClient.setQueryData(lastQueryKey.queryKey, (old: SearchAdSets200) => {
          return {
            ...old,
            data: old?.data?.map((adSet: AdSet) => {
              if (adSet.id === data.id) {
                return {
                  ...adSet,
                  status: data.data.status,
                };
              }
              return adSet;
            }),
          };
        });
        return { previousValue, processId };
      },
      onSuccess: (_, __, context) => {
        queryClient.invalidateQueries({ queryKey: AdsetQueryKeys });
        finishProcess(context.processId, { success: true, message: 'Ad set status toggled' });
      },
      onError: (_, __, context) => {
        finishProcess(context.processId, { success: false, message: 'Failed to toggle ad set status' });
      },
    },
  });

  return {
    mutate,
    isSuccess,
    isPending,
  };
};
