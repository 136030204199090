import React from 'react';
import { Box, Button, Typography } from '@zitcha/component-library';
import { EditableField } from 'v2/components/EditableField/EditableField';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';

interface PlanHeaderProps {
  planName: string | null;
  updatePlanName: (name: string) => void;
  cancelPlan: () => void;
  savePlan: () => void;
  proposePlan: () => void;
  savingPlan: boolean;
  saveEnabled: boolean;
}

export function PlanHeader({
  planName,
  updatePlanName,
  cancelPlan,
  savePlan,
  proposePlan,
  savingPlan,
  saveEnabled,
}: PlanHeaderProps): React.ReactElement {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      my={2}
      sx={{
        minHeight: '64px',
      }}
    >
      <Box display='flex' gap={2} alignItems='flex-start'>
        <Typography variant='h4' data-testid='pageHeading'>
          New plan
        </Typography>
        <EditableField initialValue={planName ?? ''} onChange={updatePlanName} editable={true} />
      </Box>

      <Box gap={2} display='flex'>
        <Button
          variant='text'
          color='error'
          onClick={cancelPlan}
          startIcon={<DeleteIcon />}
          data-testid='cancel-plan'
          disabled={savingPlan}
        >
          Cancel Plan
        </Button>
        <Button
          variant='text'
          startIcon={<SaveIcon />}
          onClick={savePlan}
          data-testid='save-plan'
          disabled={savingPlan || !saveEnabled}
        >
          Save Draft
        </Button>
        <Button
          endIcon={<SendIcon />}
          onClick={proposePlan}
          data-testid='propose-plan'
          disabled={savingPlan || !saveEnabled}
        >
          Propose Plan
        </Button>
      </Box>
    </Box>
  );
}
