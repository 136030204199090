import { useQuery } from '@tanstack/react-query';
import { getOrganisation } from 'lib/api';
import type { Organisation } from './useUserOrganisation';

interface UseGetOrganisationResult {
  organisation: Organisation | null;
  isLoading: boolean;
  error: Error | null;
  status: 'error' | 'success' | 'pending';
}

export const useGetOrganisation = (orgId: string | undefined | null): UseGetOrganisationResult => {
  const result = useQuery({
    queryKey: ['organisation', orgId],
    queryFn: () => getOrganisation(orgId as string),
    enabled: !!orgId,
  });

  return {
    organisation: result.data?.data?.data as Organisation | null,
    isLoading: result.isLoading,
    error: result.error as Error | null,
    status: result.status,
  };
};
