import React from 'react';
import { getRetailerMPASettings } from 'lib/api/mpa';

interface EligibilityI {
  last_check: number;
  is_eligible: boolean;
  type: string;
  exception: any;
  reason_code: any;
  reason_description: any;
}
type Pagehandler = 'retailer' | 'supplier';
interface Onboarding {
  time: number;
  finalized_at: number;
  status: string;
  error_user_title: any;
  error_user_msg: any;
  error_code: any;
  error_sub_code: any;
}

interface Settings {
  page_handler: Array<Pagehandler>;
  credit_limit: number;
  website: string;
  countries_targeting: Array<string>;
  registration_country: string;
  vertical: string;
}

interface MpaSettings {
  status:
    | 'not-started'
    | 'new'
    | 'submitted'
    | 'failed'
    | 'completed'
    | 'action-required'
    | 'waiting_for_supplier_input'
    | 'in_progress';
  eligibility: EligibilityI;
  onboarding: Onboarding;
  settings: Settings;
}
type State = {
  loading: boolean;
  error: string | null;
  retailerSettings: MpaSettings | undefined;
};

type Action =
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_RETAILER_SETTINGS'; payload: MpaSettings }
  | { type: 'SET_ERROR'; payload: string | null };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_RETAILER_SETTINGS':
      return { ...state, retailerSettings: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export const useRetailerSettings = ({ organisationId }: { organisationId: string | undefined }) => {
  const initialState: State = {
    loading: false,
    retailerSettings: undefined,
    error: null,
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const fetchSettings = async () => {
      if (!organisationId) {
        return state;
      }
      try {
        dispatch({ type: 'SET_LOADING', payload: true });
        const settings = await getRetailerMPASettings(organisationId);
        if (settings?.status === 200) {
          dispatch({ type: 'SET_RETAILER_SETTINGS', payload: settings.data.data.mpa });
          dispatch({ type: 'SET_ERROR', payload: null });
        } else {
          throw new Error('Error fetching retailer settings');
        }
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: 'Error fetching retailer settings' });
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };
    fetchSettings();
  }, [organisationId, dispatch]);

  return state;
};
