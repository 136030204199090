import React from 'react';
import { Box, Typography } from '@zitcha/component-library';
import { formatCurrency } from 'lib/util/currencyFormatter';
import { useGetOrganisation } from 'v2/lib/hooks/useGetOrganisation';

interface RenderCurrencyProps {
  amount: number | undefined;
  label?: string;
  isReadOnly?: boolean;
  organisationId?: string;
  currencyCode?: string;
}

export const RenderCurrency = ({ amount, label, isReadOnly, organisationId, currencyCode }: RenderCurrencyProps) => {
  const { organisation, isLoading } = useGetOrganisation(organisationId || '');
  const currencySymbol = currencyCode || organisation?.settings?.defaultCurrency || ''; // Use currencyCode if provided

  // Apply read-only styling conditionally
  const typographyStyle = isReadOnly ? { color: 'gray', pointerEvents: 'none' } : {};

  const formatted = isLoading ? 'Loading...' : formatCurrency(amount, currencySymbol);

  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{
        minWidth: '80px',
        textAlign: 'left',
      }}
    >
      {label && (
        <Typography variant='caption' sx={typographyStyle}>
          {label}
        </Typography>
      )}
      <Typography variant='subtitle1' gutterBottom sx={typographyStyle}>
        {amount && amount > 0 ? formatted : '-'}
      </Typography>
    </Box>
  );
};
