import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  ErrorIcon,
  ImageSearchIcon,
  ProductionQuantityLimitsIcon,
  Tab,
  Tabs,
  TagIcon,
  TodayIcon,
} from '@zitcha/component-library';
import { ClashedAdSetsTable } from './ClashedAdSetsTable';
import { ActionRequiredFilters } from './ActionRequiredFilters';
import { AdSetSearch, PlanningActionTypeItem, useGetPlanningActionTypes } from 'v2/lib/api/ad-management';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { AdSetsActionsTable } from './AdSetsActionsTable';

enum ActionTab {
  CLASHES = 'CLASHES',
  DEADLINES = 'DEADLINES',
  SKU_ERRORS = 'SKU ERRORS',
  MISSING_ASSETS = 'MISSING ASSETS',
  FAILED = 'FAILED',
}

export enum ActionTypes {
  DEADLINE_SOON = 'Deadline Soon',
  DEADLINE_PAST = 'Deadline Past',
  SKU_ERRORS = 'Validation',
  MISSING_ASSETS = 'Missing Assets',
}

const tabIcons: { [key: string]: JSX.Element } = {
  [ActionTypes.DEADLINE_SOON]: <TodayIcon />,
  [ActionTypes.DEADLINE_PAST]: <TodayIcon />,
  [ActionTypes.SKU_ERRORS]: <TagIcon />,
  [ActionTypes.MISSING_ASSETS]: <ImageSearchIcon />,
  default: <ErrorIcon />,
} as const;

const getInitialTab = (isRetailer: boolean, dynamicTabs: Array<PlanningActionTypeItem> | undefined): ActionTab => {
  return isRetailer ? ActionTab.CLASHES : (dynamicTabs?.at(0)?.name as ActionTab);
};

export const ActionsRequired = () => {
  const { is_retailer: isRetailer, id: organisation_id } = useUserOrganisation();

  const { data: actionTypeItems } = useGetPlanningActionTypes(
    { organisation_id },
    {
      query: {
        select: (response) => response.data as Array<PlanningActionTypeItem>,
      },
    }
  );

  const [activeTab, setActiveTab] = useState<ActionTab>(ActionTab.CLASHES);
  const [filters, setFilters] = useState<Partial<AdSetSearch>>({});

  const handleFilterChange = useCallback((newFilters: Partial<AdSetSearch>) => {
    setFilters(newFilters);
  }, []);

  const tabsArray = useMemo(() => {
    const dynamicTabsArray =
      actionTypeItems?.map((tab: PlanningActionTypeItem) => ({
        label: tab.name,
        icon: tabIcons[tab.name] || tabIcons.default,
      })) || [];
    return isRetailer
      ? [{ label: ActionTab.CLASHES, icon: <ProductionQuantityLimitsIcon /> }, ...dynamicTabsArray]
      : dynamicTabsArray;
  }, [isRetailer, actionTypeItems]);

  const handleChange = (_: React.SyntheticEvent, newValue: ActionTab) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    setActiveTab(getInitialTab(isRetailer, actionTypeItems));
  }, [isRetailer, actionTypeItems]);

  return (
    <Box minHeight={200} borderColor={'grey'} p={2}>
      <ActionRequiredFilters onFilterChange={handleFilterChange} />
      <Box display='flex' gap={1.5} flexDirection='column' mt={2}>
        <Tabs value={activeTab} onChange={handleChange} aria-label='action required tabs'>
          {tabsArray.map((tab) => (
            <Tab
              label={tab.label}
              value={tab.label}
              icon={tab.icon}
              iconPosition='start'
              key={tab.label}
              sx={{
                minHeight: '35px',
              }}
            />
          ))}
        </Tabs>

        {activeTab === ActionTab.CLASHES ? (
          <ClashedAdSetsTable filters={filters} />
        ) : (
          <AdSetsActionsTable filters={filters} tab={activeTab} />
        )}
      </Box>
    </Box>
  );
};
