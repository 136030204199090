import React, { SetStateAction } from 'react';
import { EditIcon, Modal } from '@zitcha/component-library';
import { ViewTeam } from './ViewTeam';
import { ModalFooter } from '../components/ModalFooter';
import { EditTeam } from './EditTeam';
import { useDeleteTeamWithHandler } from './hooks/useDeleteTeamWithHandler';
import { CreateTeam } from './CreateTeam';
import { useAddTeamWithHandler } from './hooks/useAddTeamWithHandler';
import { useUpdateTeamWithHandler } from './hooks/useUpdateTeamWithHandler';
import { SnackbarNotifications } from 'app/components/SnackNotifications/SnackbarNotifications';

export interface ModalState {
  open: boolean;
  mode: 'edit' | 'view';
  teamId: number | null;
  title: string;
}

type TeamManagementModalProps = {
  modalState: ModalState;
  handleClose: () => void;
  handleModalState?: React.Dispatch<SetStateAction<ModalState>>;
};

export const TeamManagementModal: React.FC<TeamManagementModalProps> = ({
  modalState,
  handleClose,
  handleModalState,
}) => {
  const teamId = modalState.teamId;
  const isCreateMode = modalState.mode === 'edit' && !teamId;
  const isEditMode = modalState.mode === 'edit' && teamId;
  const isViewMode = modalState.mode === 'view' && teamId;

  const {
    isError: isCreateError,
    isSuccess: isCreateSuccess,
    isPending: isCreateLoading,
    error: createError,
    handleAddTeam,
  } = useAddTeamWithHandler({ handleClose });

  const {
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
    isPending: isUpdateLoading,
    error: updateError,
    handleUpdateTeam,
  } = useUpdateTeamWithHandler({ handleClose });

  const {
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    isPending: isDeleteLoading,
    error: deleteError,
    handleDeleteTeam,
  } = useDeleteTeamWithHandler({ teamId, handleClose });

  const renderViewMode = () => (
    <>
      <ViewTeam teamId={Number(teamId)} />
      <ModalFooter
        primaryButton={{
          label: 'Edit team',
          icon: EditIcon,
          onClick: () => {
            handleModalState?.({ ...modalState, mode: 'edit', title: 'Edit team' });
          },
        }}
        secondaryButton={{
          label: 'Close',
          onClick: handleClose,
          variant: 'close',
        }}
      />
    </>
  );

  const renderCreateMode = () => (
    <CreateTeam
      handleCreateTeam={handleAddTeam}
      isPending={isCreateLoading}
      secondaryButton={{
        label: 'Cancel',
        variant: 'normal',
        onClick: handleClose,
      }}
    />
  );

  const renderEditMode = () => (
    <EditTeam
      teamId={teamId}
      handleUpdateTeam={handleUpdateTeam}
      isPending={isUpdateLoading}
      secondaryButton={{
        label: 'Delete team',
        variant: 'warning',
        onClick: handleDeleteTeam,
      }}
    />
  );

  return (
    <>
      {isCreateMode && (
        <SnackbarNotifications
          isError={isCreateError}
          isSuccess={isCreateSuccess}
          isProcessing={isCreateLoading}
          messages={{
            error: createError?.response?.data?.error || 'Error adding new team. Please try again.',
            success: 'New team added',
          }}
        />
      )}
      {isEditMode && (
        <>
          <SnackbarNotifications
            isError={isDeleteError}
            isSuccess={isDeleteSuccess}
            isProcessing={isDeleteLoading}
            messages={{
              error: deleteError?.response?.data?.error || 'Error deleting team',
              success: 'Team deleted',
            }}
          />
          <SnackbarNotifications
            isError={isUpdateError}
            isSuccess={isUpdateSuccess}
            isProcessing={isUpdateLoading}
            messages={{
              error: updateError?.response?.data?.error || 'Error updating team',
              success: 'Team updated',
            }}
          />
        </>
      )}

      <Modal size='medium' open={modalState.open} onClose={handleClose} title={modalState.title}>
        <>
          {isViewMode && renderViewMode()}
          {isCreateMode && renderCreateMode()}
          {isEditMode && renderEditMode()}
        </>
      </Modal>
    </>
  );
};
