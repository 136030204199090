import { createSlice } from '@reduxjs/toolkit';
import {
  APPROVE_STATE,
  BRAND_STORE_ORDER_FORM_STATE,
  DATE_FORMAT,
  ORDER_LOADING_STATE,
  PREVIEW_STATE,
  PROVIDE_FEEDBACK_STATE,
  SAVE_STATE,
  SUBMIT_STATE,
} from '../constants';
import { getRandomIntegerInRange } from '../helpers';
import { getBlankPage, pageNameExists, transformConfigureFormFromResponse } from './BrandStoreStateHelpers';
import {
  getPreview,
  loadBrandStoreConfigureForm,
  loadBrandStoreOrder,
  onApproval,
  onProvideFeedback,
  saveBrandStoreConfiguration,
  saveBrandStoreOrder,
  submitForReview,
  updateBrandStoreConfiguration,
} from './BrandStoreAsyncActions';
import moment from 'moment';

const initialState = {
  brand_store_configure_form: {
    name: '',
    base_url: '',
    start_at: moment().format(DATE_FORMAT),
    stop_at: '',
    status: 'draft',
    total_cost: 0,
    brand_store_id: null,
    time_unit: 'years',
    cost_per_unit: null,
    unit_count: null,
    wallet: {
      id: null,
      owner_id: null,
    },
  },
  brand_store_order: { pages: [] },
  errors: {},
  order_form_state: BRAND_STORE_ORDER_FORM_STATE.VIEW,
  preview_state: PREVIEW_STATE.INITIAL,
  order_loading_state: ORDER_LOADING_STATE.INITIAL,
  provide_feedback_state: PROVIDE_FEEDBACK_STATE.INITIAL,
  isDirty: false,
  preview: {},
  blockTypes: [],
  save_state: SAVE_STATE.INITIAL,
  submit_state: SUBMIT_STATE.INITIAL,
  approve_state: APPROVE_STATE.INITIAL,
  permissions: {},
};

const handlePageErrors = (errors, state) => {
  // Reset errors in state
  state.errors = {
    pages: {},
  };

  // Iterate over errors
  for (const errorKey in errors) {
    // Split the error string into parts
    const errorKeyParts = errorKey.split('.');

    // Get page index and block index from error string
    const pageIndex = parseInt(errorKeyParts[1]);
    const blockIndex = parseInt(errorKeyParts[4]);

    // Get page ID
    const pageID = state.brand_store_order.pages[pageIndex].id;

    // Ensure page entry exists in state.errors.pages
    if (!state.errors.pages[pageID]) {
      state.errors.pages[pageID] = {
        errorMessages: {},
        blocks: {},
      };
    }

    // If error is related to a block
    if (!isNaN(blockIndex)) {
      // Get block ID
      const blockID = state.brand_store_order.pages[pageIndex].json_schema.blocks[blockIndex].id;

      // Ensure block entry exists in page's blocks
      if (!state.errors.pages[pageID].blocks[blockID]) {
        state.errors.pages[pageID].blocks[blockID] = {
          errorMessages: {},
        };
      }

      // Add error to block
      state.errors.pages[pageID].blocks[blockID].errorMessages[errorKeyParts[errorKeyParts.length - 1]] =
        errors[errorKey];
    } else {
      // Add error to page
      state.errors.pages[pageID].errorMessages[errorKeyParts[errorKeyParts.length - 1]] = errors[errorKey];
    }
  }
};

// Create the BrandStore slice
export const brandStoreSlice = createSlice({
  name: 'brand_store',
  initialState,
  reducers: {
    brandStoreConfigureFormUpdated(state, action) {
      state.brand_store_configure_form = {
        ...state.brand_store_configure_form,
        ...action.payload,
      };
    },
    permissionsUpdate(state, action) {
      state.permissions = action.payload;
    },
    editForm(state) {
      state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.EDIT;
      state.isDirty = false;
    },
    cancelEdit(state) {
      state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.VIEW;
      state.isDirty = false;
      state.brand_store_order = initialState.brand_store_order;
      state.errors = initialState.errors;
      state.preview_state = initialState.preview_state;
      state.preview = initialState.preview;
    },
    clearBrandStoreConfigureForm(state) {
      state.brand_store_configure_form = initialState.brand_store_configure_form;
      state.errors = initialState.errors;
      state.permissions = initialState.permissions;
    },
    clearBrandStore: (state) => {
      state.brand_store_order = initialState.brand_store_order;
      state.errors = initialState.errors;
      state.permissions = initialState.permissions;
      state.preview_state = initialState.preview_state;
      state.order_form_state = initialState.order_form_state;
      state.preview = initialState.preview;
      state.isDirty = initialState.isDirty;
      state.errors = initialState.errors;
      state.blockTypes = initialState.blockTypes;
      state.brand_store_configure_form = initialState.brand_store_configure_form;
      state.save_state = SAVE_STATE.INITIAL;
      state.submit_state = SAVE_STATE.INITIAL;
      state.order_loading_state = ORDER_LOADING_STATE.INITIAL;
      state.provide_feedback_state = initialState.provide_feedback_state;
    },
    setPageName: (state, action) => {
      const page = state.brand_store_order.pages.find((page) => page.id === action.payload.pageId);
      if (page) {
        page.page_name = action.payload.pageName;
        state.isDirty = true;
      } else {
        console.error(`Page with ID ${action.payload.pageId} not found.`);
      }
    },
    setPageUrl: (state, action) => {
      const { pageId, pageUrl } = action.payload;
      const page = state.brand_store_order.pages.find((page) => page.id === pageId);
      if (!page) {
        console.error(`Page with ID ${pageId} not found.`);
        return;
      }
      page.page_url = pageUrl;
      state.isDirty = true;
    },
    setDateInheritedFromParent: (state, action) => {
      const { pageId } = action.payload;
      state.brand_store_order.pages.find((page) => page.id === pageId).is_date_inherited_from_parent =
        action.payload.dateInheritedFromParent;
      state.isDirty = true;
    },
    setPageStartDate: (state, action) => {
      const { pageId } = action.payload;
      state.brand_store_order.pages.find((page) => page.id === pageId).page_start_date = action.payload.pageStartDate;
      state.isDirty = true;
    },
    setPageStopDate: (state, action) => {
      const { pageId } = action.payload;
      state.brand_store_order.pages.find((page) => page.id === pageId).page_stop_date = action.payload.pageStopDate;
      state.isDirty = true;
    },
    addPage: (state, action) => {
      const pages = state.brand_store_order.pages;
      const { newPageId } = action.payload;
      let pageNumber;
      for (pageNumber = pages.length + 1; pageNameExists(pages, `Page ${pageNumber}`); pageNumber++) {
        // Empty block, the increment happens in the for loop condition
      }

      const defaultName = `Page ${pageNumber}`;
      state.brand_store_order.pages = [
        ...state.brand_store_order.pages,
        getBlankPage(defaultName, newPageId, state.brand_store_order.start_at, state.brand_store_order.stop_at),
      ];
      state.isDirty = true;
    },
    removePage: (state, action) => {
      const { pageId } = action.payload;

      // If there is only one page, do not remove it.
      if (state.brand_store_order.pages.length === 1) {
        return;
      }

      // Remove the page by filtering out the current page ID
      state.brand_store_order.pages = state.brand_store_order.pages.filter((page) => page.id !== pageId);
      state.isDirty = true;
    },
    reorderPages: (state, action) => {
      state.brand_store_order.pages = action.payload;
      state.isDirty = true;
    },
    addBlock: (state, action) => {
      const { newBlockId, pageId, blockType } = action.payload;
      const newBlock = {
        id: newBlockId,
        block_id: blockType.id,
        name: blockType.name,
        data: Object.fromEntries(
          blockType.meta.variables.map((variable) => {
            let defaultValue;
            switch (variable.type) {
              case 'image':
                defaultValue = undefined;
                break;
              case 'video':
                defaultValue = undefined;
                break;
              case 'link':
                defaultValue = {
                  brandPageLink: '',
                  externalUrl: null,
                  linkType: 'noLink',
                };
                break;
              case 'products':
                defaultValue = [];
                break;
              default:
                defaultValue = variable.default;
            }

            return [variable.key, defaultValue];
          })
        ),
      };

      const page = state.brand_store_order.pages.find((page) => page.id === pageId);

      if (page) {
        page.json_schema.blocks.push(newBlock);
        state.isDirty = true;
      } else {
        console.error(`Page with ID ${pageId} not found.`);
      }
    },
    removeBlock: (state, action) => {
      const { blockId } = action.payload;
      for (let page of state.brand_store_order.pages) {
        const initialBlocksLength = page.json_schema.blocks.length;
        page.json_schema.blocks = page.json_schema.blocks.filter((block) => block.id !== blockId);
        if (page.json_schema.blocks.length < initialBlocksLength) {
          // Block was found and removed
          state.isDirty = true;
          return;
        }
      }
      console.error(`Block with ID ${blockId} not found.`);
    },
    reorderBlocks: (state, action) => {
      const { pageId, newBlockOrder } = action.payload;
      const page = state.brand_store_order.pages.find((p) => p.id === pageId);
      page.json_schema.blocks = newBlockOrder;
      state.isDirty = true;
    },
    setBlockVariableValue: (state, action) => {
      const { blockId, variableKey, value } = action.payload;
      for (let page of state.brand_store_order.pages) {
        const block = page.json_schema.blocks.find((block) => block.id === blockId);
        if (block) {
          if (Array.isArray(block.data) && block.data.length === 0) {
            block.data = {};
          }
          block.data[variableKey] = value;
          state.isDirty = true;
          return;
        }
      }
      console.error(`Block with ID ${blockId} not found.`);
    },
    setComment: (state, action) => {
      state.brand_store_order.comment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadBrandStoreConfigureForm.fulfilled, (state, action) => {
        state.brand_store_configure_form = transformConfigureFormFromResponse(action.payload.order);
      })
      .addCase(loadBrandStoreOrder.pending, (state) => {
        state.order_loading_state = ORDER_LOADING_STATE.LOADING;
        state.save_state = SAVE_STATE.INITIAL;
        state.submit_state = SAVE_STATE.INITIAL;
      })
      .addCase(loadBrandStoreOrder.fulfilled, (state, action) => {
        // If action.payload has an empty pages array, insert getBlankPage with name 'home' and id random negative int
        const fetchedOrder = action.payload.order;
        if (fetchedOrder.pages.length === 0) {
          const homePageId = getRandomIntegerInRange(Number.MIN_SAFE_INTEGER, -1);
          fetchedOrder.pages.push(getBlankPage('Home', homePageId, fetchedOrder.start_at, fetchedOrder.stop_at, true));
        }
        state.blockTypes = action.payload.blockTypes;
        state.brand_store_order = fetchedOrder;

        const orgPermissions = action.payload.orgPermissions;
        const facebookProductSets = orgPermissions?.product_sets?.filter((p) => p?.platform === 'facebook') || [];
        state.permissions = { ...orgPermissions, product_sets: facebookProductSets };

        state.brand_store_order.wallet = action.payload.orderWallet;
        state.preview = action.payload.orderPreview;

        state.preview_state = PREVIEW_STATE.UPDATED;
        state.order_loading_state = ORDER_LOADING_STATE.LOADED;
      })
      .addCase(loadBrandStoreOrder.rejected, (state) => {
        state.order_loading_state = ORDER_LOADING_STATE.ERROR;
      })
      .addCase(saveBrandStoreOrder.pending, (state) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.SAVING;
        state.save_state = SAVE_STATE.SAVING;
      })
      .addCase(saveBrandStoreOrder.fulfilled, (state) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.VIEW;
        state.isDirty = false;
        state.save_state = SAVE_STATE.SAVED;
      })
      .addCase(saveBrandStoreOrder.rejected, (state, action) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.EDIT;
        const errors = action.payload?.errors ? action.payload.errors : null;
        state.save_state = SAVE_STATE.ERROR;

        if (errors) {
          handlePageErrors(errors, state);
        }
      })
      .addCase(submitForReview.pending, (state) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.SUBMITTING;
        state.submit_state = SUBMIT_STATE.SUBMITTING;
      })
      .addCase(submitForReview.fulfilled, (state) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.VIEW;
        state.submit_state = SUBMIT_STATE.SUBMITTED;
      })
      .addCase(submitForReview.rejected, (state, action) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.EDIT;
        state.submit_state = SUBMIT_STATE.ERROR;

        // Extract errors from action.payload.errors
        const errors = action.payload.errors;

        handlePageErrors(errors, state);
      })
      .addCase(onApproval.pending, (state) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.APPROVING;
        state.approve_state = APPROVE_STATE.APPROVING;
      })
      .addCase(onApproval.fulfilled, (state) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.VIEW;
        state.approve_state = APPROVE_STATE.APPROVED;
      })
      .addCase(onApproval.rejected, (state, action) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.VIEW;
        state.errors = { error: action.payload.error };
        state.approve_state = APPROVE_STATE.ERROR;
      })
      .addCase(getPreview.pending, (state) => {
        state.preview_state = PREVIEW_STATE.UPDATING;
      })
      .addCase(getPreview.fulfilled, (state, action) => {
        state.preview = action.payload.data;
        state.preview_state = PREVIEW_STATE.UPDATED;
      })
      .addCase(getPreview.rejected, (state) => {
        state.preview_state = PREVIEW_STATE.ERROR;
      })
      .addCase(onProvideFeedback.pending, (state) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.PROVIDING_FEEDBACK;
        state.provide_feedback_state = PROVIDE_FEEDBACK_STATE.PROVIDING_FEEDBACK;
      })
      .addCase(onProvideFeedback.fulfilled, (state) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.VIEW;
        state.provide_feedback_state = PROVIDE_FEEDBACK_STATE.PROVIDED_FEEDBACK;
        state.isDirty = false;
        state.errors = initialState.errors;
      })
      .addCase(onProvideFeedback.rejected, (state, action) => {
        state.order_form_state = BRAND_STORE_ORDER_FORM_STATE.ERROR;
        state.provide_feedback_state = PROVIDE_FEEDBACK_STATE.ERROR;
        state.errors = { error: action.payload.errors };
      });
  },
});

// Export actions
export const {
  brandStoreConfigureFormUpdated,
  clearBrandStoreConfigureForm,
  editForm,
  cancelEdit,
  clearBrandStore,
  setPageName,
  setPageUrl,
  setDateInheritedFromParent,
  setPageStartDate,
  setPageStopDate,
  addPage,
  removePage,
  reorderPages,
  addBlock,
  removeBlock,
  reorderBlocks,
  setBlockVariableValue,
  setComment,
} = brandStoreSlice.actions;

// Export async thunk action creators
export {
  loadBrandStoreConfigureForm,
  saveBrandStoreConfiguration,
  updateBrandStoreConfiguration,
  loadBrandStoreOrder,
  saveBrandStoreOrder,
  submitForReview,
  getPreview,
};

// Export selectors
export const selectBrandStoreOrder = (state) => state.brand_store.brand_store_order;
export const selectBrandStoreBlocks = (state) => state.brand_store.blockTypes;
export const selectBrandStore = (state) => state.brand_store;
export const selectBrandStoreSupplierId = (state) => state.brand_store.brand_store_order.supplier_id;
export const selectBrandStoreOwnerId = (state) => state.brand_store.brand_store_order.owner_id;
export const selectErrors = (state) => state.brand_store.errors;
export const selectRequestErrors = (state) => state.brand_store.request_errors;
export const selectBrandStorePermissions = (state) => state.brand_store.permissions;

// Export the BrandStore slice reducer
export default brandStoreSlice.reducer;
