import React, { useState } from 'react';
import { Box, Button, Chip, Stack, TextField, Typography } from '@zitcha/component-library';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import { ScopedOrganisationsAutocomplete } from 'v2/components/Autocomplete/ScopedOrganisationsAutocomplete';
import { OptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';
import { RenderCurrency } from 'v2/components/RenderCurrency/RenderCurrency';
import Wallet from 'v2/Types/Wallets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { DiscountData, DiscountsModal } from 'v2/components/Discounts/DiscountsModal';
import { PlanData } from '../PlanContext';

interface PlanDetailsProps {
  isRetailer: boolean;
  selectedOrganisation: OptionsType | null;
  selectOrganisation: (newSelectedOrg: OptionsType | null) => void;
  wallets: Array<Wallet>;
  selectedWallet: Wallet | null;
  selectWallet: (wallet: Wallet) => void;
  isWalletsLoading: boolean;
  discounts: Array<DiscountData>;
  updatePlan: (updates: Partial<PlanData>) => void;
  planValue: number;
  remainingWalletBalance: number;
  retailerId: string | null;
}

export function PlanDetails({
  isRetailer,
  selectedOrganisation,
  selectOrganisation,
  wallets,
  selectedWallet,
  selectWallet,
  isWalletsLoading,
  discounts,
  updatePlan,
  planValue,
  remainingWalletBalance,
  retailerId,
}: PlanDetailsProps): React.ReactElement {
  const [discountModalOpen, setDiscountModalOpen] = useState<boolean>(false);

  const openDiscountsModal = () => setDiscountModalOpen(true);

  const handleDiscountsClosed = (newDiscounts: Array<DiscountData> | null) => {
    if (newDiscounts) {
      updatePlan({ newDiscounts });
    }
    setDiscountModalOpen(false);
  };

  return (
    <Box display='flex' justifyContent='space-between' my={2}>
      <Typography variant='h5'>
        Plan status <Chip label='Draft' />
      </Typography>
      <Stack direction='row' spacing={2}>
        <ScopedOrganisationsAutocomplete
          data-testid={isRetailer ? 'autocomplete-brand' : 'autocomplete-retailer'}
          selectedOptions={selectedOrganisation}
          setSelectedOptions={(newValue) => selectOrganisation(newValue as OptionsType | null)}
          type='form'
          multiple={false}
          autoCompletePropsOverride={{
            sx: { minWidth: '225px' },
            renderInput: (params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                variant='standard'
                label={isRetailer ? 'Brand' : 'Retailer'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ),
          }}
        />
        <Autocomplete
          data-testid='autocomplete-wallet'
          onChange={(_, newValue: Wallet) => selectWallet(newValue)}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              variant='standard'
              label='Wallet'
              sx={{ minWidth: '225px' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          getOptionKey={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          options={wallets}
          loading={isWalletsLoading}
          size='medium'
          value={selectedWallet}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
        />
        {isRetailer && (
          <>
            <TextField
              variant='standard'
              label='Discounts'
              value={
                discounts.length ? `${discounts.length} discount${discounts.length > 1 ? 's' : ''}` : 'No discounts'
              }
              placeholder='No discounts'
              sx={{ minWidth: '225px' }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <Button
                    variant='text'
                    sx={{ padding: 0, minWidth: '40px' }}
                    size='small'
                    onClick={openDiscountsModal}
                  >
                    <CropFreeIcon />
                  </Button>
                ),
              }}
            />
            {retailerId && (
              <DiscountsModal
                retailerId={retailerId}
                applicableDiscounts={discounts}
                onClose={handleDiscountsClosed}
                isOpen={discountModalOpen}
                isReadOnly={false}
              />
            )}
          </>
        )}
        <Box display='flex' flexDirection='row' gap={2}>
          <RenderCurrency amount={planValue} label='Plan value' organisationId={retailerId || ''} />
          <RenderCurrency
            amount={remainingWalletBalance}
            label='Remaining wallet balance'
            currencyCode={selectedWallet?.currency}
          />
        </Box>
      </Stack>
    </Box>
  );
}
