import React, { useEffect } from 'react';
import { useFetchAdSets } from 'v2/lib/hooks/useFetchAdSets';
import { AdSetSearch } from 'v2/lib/api/ad-management';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { BaseAdSetsActionsTable } from './BaseAdSetsActionsTable';

interface ActionTypesRelatedSetsActionsProps {
  filters: Partial<AdSetSearch>;
  actionTypes: Array<string>;
}

export const ActionTypesRelatedSetsActions: React.FC<ActionTypesRelatedSetsActionsProps> = ({
  filters,
  actionTypes,
}) => {
  const organisation = useUserOrganisation();
  const isRetailer = organisation.is_retailer;

  const organisationTypeFilter = isRetailer
    ? { retailer_ids: [organisation.id], supplier_ids: [] }
    : { retailer_ids: [], supplier_ids: [organisation.id] };

  const initialAdSetSearch: AdSetSearch = {
    action_types: actionTypes,
    ...organisationTypeFilter,
    ...filters,
  };

  const {
    adSetsData,
    isLoadingAdSets,
    adSetPagination,
    changeAdSetPagination,
    setAdSetSearch,
    refetch: refetchAdSets,
  } = useFetchAdSets(initialAdSetSearch, !!filters);

  useEffect(() => {
    setAdSetSearch((prevSearch) => {
      const newSearch: AdSetSearch = {
        ...prevSearch,
        ...filters,
        action_types: actionTypes,
      };

      // Remove any filters that are undefined
      Object.keys(newSearch).forEach((key) => {
        if (newSearch[key as keyof AdSetSearch] === undefined) {
          delete newSearch[key as keyof AdSetSearch];
        }
      });

      return newSearch;
    });
  }, [filters, setAdSetSearch, actionTypes]);

  return (
    <BaseAdSetsActionsTable
      adSetsData={adSetsData}
      isLoadingAdSets={isLoadingAdSets}
      adSetPagination={adSetPagination}
      changeAdSetPagination={changeAdSetPagination}
      isRetailer={isRetailer}
      actionTypes={actionTypes}
      refetchAdSets={refetchAdSets}
    />
  );
};
