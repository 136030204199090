import React from 'react';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';
import { TextareaAutosize, FormControl, FormHelperText } from '@mui/material';

interface TextAreaComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    label?: string;
    placeholder?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value: any;
  isReadOnly: boolean;
  error?: boolean;
  helperText?: string;
}

export const TextAreaComponent: React.FC<TextAreaComponentProps> = ({
  properties,
  fieldName,
  onChange,
  onBlur,
  value,
  isReadOnly,
  error,
  helperText,
}) => {
  const controlledValue = value || '';
  return (
    <FormControl error={error} component='fieldset'>
      <TextareaAutosize
        aria-label={properties.label}
        placeholder={properties.placeholder}
        minRows={3}
        data-testid={`${fieldName}-text-area-component`}
        onChange={onChange}
        onBlur={onBlur}
        value={controlledValue}
        disabled={isReadOnly}
      />
      {helperText && <FormHelperText style={{ whiteSpace: 'pre-line' }}>{helperText}</FormHelperText>}{' '}
    </FormControl>
  );
};
