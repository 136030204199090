import React, { createContext, useContext, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { API_TIMEOUT_TIME } from 'v2/lib/axios/axiosMainInstance';

export type NotificationType = 'success' | 'error' | 'info' | 'warning';
export type NotificationStyle = 'snackbar' | 'modal';

interface Process {
  id: string;
  title?: string;
  message: string;
  startTime: number;
  timeoutId: NodeJS.Timeout;
}

export interface Notification {
  type: NotificationType;
  style: NotificationStyle;
  title?: string;
  message: string | React.ReactNode;
  duration?: number;
  dismissable: boolean;
  processId: string;
}

interface ProcessingContextType {
  processes: Array<Process>;
  notifications: Array<Notification>;
  startProcess: (message: string, title?: string) => string;
  finishProcess: (id: string, result: { success: boolean; message: string; title?: string }) => void;
  isProcessing: boolean;
  dismissNotification: (id: string) => void;
  setActivePrimaryProcess: (id: string | null) => void;
  primaryProcessId: string | null;
}

const ProcessingContext = createContext<ProcessingContextType | undefined>(undefined);

export const ProcessingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [processes, setProcesses] = useState<Array<Process>>([]);
  const [notifications, setNotifications] = useState<Array<Notification>>([]);
  const [primaryProcessId, setPrimaryProcessId] = useState<string | null>(null);

  const startProcess = useCallback((message: string, title?: string) => {
    const id = uuidv4();
    const timeoutId = setTimeout(() => {
      finishProcess(id, { success: false, message: 'Process timed out' });
    }, API_TIMEOUT_TIME);

    const newProcess = { id, message, title, startTime: Date.now(), timeoutId };
    setProcesses((prev) => [...prev, newProcess]);
    return id;
  }, []);

  const dismissNotification = useCallback((processId: string) => {
    setNotifications((prev) => prev.filter((notif) => notif.processId !== processId));
  }, []);

  const finishProcess = useCallback((id: string, result: { success: boolean; message: string; title?: string }) => {
    setProcesses((prev) => {
      const process = prev.find((p) => p.id === id);
      if (process) {
        clearTimeout(process.timeoutId);
      }
      return prev.filter((process) => process.id !== id);
    });

    const newNotification: Notification = {
      type: result.success ? 'success' : 'error',
      style: 'snackbar',
      message: result.message,
      title: result.title,
      duration: result.success ? 3000 : undefined,
      dismissable: true,
      processId: id,
    };

    setNotifications((prev) => [...prev, newNotification]);

    if (newNotification.duration) {
      setTimeout(() => {
        setNotifications((prev) => prev.filter((notif) => notif.processId !== id));
      }, newNotification.duration);
    }

    setPrimaryProcessId((currentPrimaryProcessId) => {
      if (id === currentPrimaryProcessId) {
        return null;
      }
      return currentPrimaryProcessId;
    });
  }, []);

  const setActivePrimaryProcess = useCallback((id: string | null) => {
    setPrimaryProcessId(id);
  }, []);

  const isProcessing = processes.length > 0;

  return (
    <ProcessingContext.Provider
      value={{
        processes,
        notifications,
        startProcess,
        finishProcess,
        isProcessing,
        dismissNotification,
        setActivePrimaryProcess,
        primaryProcessId,
      }}
    >
      {children}
    </ProcessingContext.Provider>
  );
};

export const useProcessingContext = (): ProcessingContextType => {
  const context = useContext(ProcessingContext);
  if (context === undefined) {
    throw new Error('useProcessingContext must be used within a ProcessingProvider');
  }
  return context;
};
