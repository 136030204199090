import { useUpdateAdSet, useUpdateAdsInAdSet } from '../api/ad-management';
import { AdSetData } from '../../features/Planning/PlanContext';
import { useProcessingContext } from 'v2/features/GlobalNotifications/ProcessingContext';

export const useSaveAdSet = () => {
  const updateAdSet = useUpdateAdSet();
  const updateAdsInAdSet = useUpdateAdsInAdSet();
  const { startProcess, finishProcess, isProcessing } = useProcessingContext();

  const saveAdSet = async (adSet: AdSetData) => {
    const processId = startProcess('Saving ad set');
    try {
      if (adSet?.existingAdSet.bundleIds?.[0] !== adSet.bundleIds?.[0]) {
        await updateAdSet.mutateAsync({
          id: adSet.id,
          data: { new_bundle_id: adSet.bundleIds?.[0], existing_bundle_id: adSet.existingAdSet.bundleIds?.[0] },
        });
      }

      if (adSet.ads.length > 0) {
        await updateAdsInAdSet.mutateAsync({
          adSet: adSet.id,
          data: { ads: adSet.ads.map((ad) => ({ id: ad.id, name: ad.name ?? '', fields: ad.fields })) },
        });
      }

      finishProcess(processId, { success: true, message: 'Ad set saved' });
    } catch (error) {
      finishProcess(processId, { success: false, message: 'Failed to save ad set' });
      throw error;
    }
  };

  return { isProcessing, saveAdSet };
};
