import React from 'react';
import { useProcessingContext } from './ProcessingContext';
import { ProcessSnackbarNotification } from './ProcessSnackbarNotification';
import { ModalNotification } from './ModalNotification';

export const NotificationRenderer: React.FC = () => {
  const { notifications, dismissNotification, processes, primaryProcessId } = useProcessingContext();

  const primaryProcessShowing =
    processes.some((process) => process.id === primaryProcessId) ||
    notifications.some((notification) => notification.processId === primaryProcessId);

  const visibleNotifications = primaryProcessId
    ? notifications.filter((notification) => notification.processId === primaryProcessId || !primaryProcessShowing)
    : notifications;

  const visibleProcesses = primaryProcessId
    ? processes.filter((process) => process.id === primaryProcessId || !primaryProcessShowing)
    : processes;

  return (
    <>
      {visibleProcesses.map((process, index) => (
        <ProcessSnackbarNotification
          key={`${process.id}-${index}`}
          notification={{
            type: 'info',
            style: 'snackbar',
            message: process.message,
            dismissable: false,
            processId: process.id,
          }}
          onClose={() => {
            // Processes can't be dismissed
          }}
        />
      ))}
      {visibleNotifications.map((notification, index) => {
        switch (notification.style) {
          case 'snackbar':
            return (
              <ProcessSnackbarNotification
                key={`${notification.processId}-${index}`}
                notification={notification}
                onClose={() => dismissNotification(notification.processId)}
              />
            );
          case 'modal':
            return (
              <ModalNotification
                key={`${notification.processId}-${index}`}
                notification={notification}
                onClose={() => dismissNotification(notification.processId)}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
};
