import React from 'react';
import { BaseOption } from '@zitcha/component-library/dist/components/Inputs/Autocomplete/Autocomplete';
import { Autocomplete, TextField, InputAdornment, SearchIcon } from '@zitcha/component-library';

interface Option {
  id: string | number;
  name: string;
}

interface CustomAutocompleteProps {
  options: Array<Option>;
  loading: boolean;
  onInputChange: (value: string) => void;
  value: Option | null;
  onChange: (data: Option | null) => void;
  rest?: object;
  showAdornment?: boolean;
}

export const CustomAutocomplete = React.forwardRef<HTMLDivElement, CustomAutocompleteProps>(
  ({ options, loading, onInputChange, value, onChange, rest, showAdornment }, ref) => {
    // Extract the key prop from the rest object
    const { key, ...restProps } = rest || {};

    return (
      <Autocomplete
        options={options}
        loading={loading}
        onInputChange={(_, value) => onInputChange(value)}
        getOptionLabel={(option: BaseOption & { name: string }) => option.name}
        getOptionKey={(option: BaseOption) => option.id}
        isOptionEqualToValue={(option: BaseOption, value: BaseOption) => String(option.id) === String(value?.id)}
        value={value || null}
        onChange={(_, data: Option) => onChange(data)}
        {...restProps}
        key={key}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant='standard'
            InputProps={{
              ...params.InputProps,
              ...(showAdornment && {
                startAdornment: (
                  <>
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }),
            }}
          />
        )}
        ref={ref}
      />
    );
  }
);
