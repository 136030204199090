import React, { FC, useState } from 'react';
import {
  ArrowForwardIcon,
  Box,
  Button,
  CloseIcon,
  DataGrid,
  GridColDef,
  GridRowParams,
  Modal,
  SaveIcon,
  Typography,
} from '@zitcha/component-library';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { MediaSpaceCell, PlacementCell, ScheduleCell } from 'v2/features/Planning/NewPlanAdSetsCellRenders';
import { type CalendarPeriod, type Location } from 'v2/lib/api/ad-management';
import { MediaSpace } from '../../lib/api/inventory';
import { DiscountSelector } from './DiscountSelector';

export interface DiscountModalProps {
  isOpen: boolean;
  retailerId: string;
  planId?: string;
  isReadOnly?: boolean;
  applicableDiscounts: Array<DiscountData>;
  onClose: (data: Array<DiscountData> | null) => void;
}

export interface DiscountData {
  schedule?: CalendarPeriod | null;
  mediaSpace?: MediaSpace | null;
  placement?: Location | null;
  planId?: string | null;
  discount?: string;
  type?: string;
  isError?: boolean;
  id?: string;
}

export const DiscountsModal: FC<DiscountModalProps> = ({
  isOpen,
  retailerId,
  planId = null,
  onClose,
  isReadOnly = false,
  applicableDiscounts = [],
}) => {
  const initialPlanDiscounts = applicableDiscounts.filter((discount) => discount.planId !== undefined);
  const initialDiscounts = applicableDiscounts.filter((discount) => discount.planId === undefined);

  const initialPlanDiscount = initialPlanDiscounts[0] ?? null;

  const [discounts, setDiscounts] = useState<Array<DiscountData>>(() => initialDiscounts);
  const [planDiscount, setPlanDiscount] = useState<DiscountData | null>(() => initialPlanDiscount);
  // uses to fire a re-render on Delete All action
  const [planDiscountKey, setPlanDiscountKey] = useState<number>(0);

  const [isChanged, setIsChanged] = useState<boolean>(false);

  const hasErrors = discounts.some((discount) => discount.isError);

  function updatePlanDiscount(updatedDiscount: DiscountData) {
    setPlanDiscount({ ...planDiscount, discount: updatedDiscount.discount, type: updatedDiscount.type });
    setIsChanged(true);
  }

  const addDiscounts = (updatedDiscount: DiscountData) => {
    setDiscounts([...discounts, updatedDiscount]);
    setIsChanged(true);
  };

  const updateDiscounts = (updatedDiscount: DiscountData) => {
    const updatedDiscounts = discounts.map((discount) => {
      if (discount.id === updatedDiscount.id) {
        const isError =
          !updatedDiscount.schedule &&
          !updatedDiscount.mediaSpace &&
          !updatedDiscount.placement &&
          Number(updatedDiscount.discount) !== 0;

        return {
          ...discount,
          schedule: updatedDiscount.schedule,
          mediaSpace: updatedDiscount.mediaSpace,
          placement: updatedDiscount.placement,
          discount: updatedDiscount.discount,
          type: updatedDiscount.type,
          isError: isError,
        } as DiscountData;
      }
      return discount;
    });

    setDiscounts(updatedDiscounts);
    setIsChanged(true);
  };

  const handleAddRow = () => {
    const id = uuidv4();
    addDiscounts({ id } as DiscountData);
    setIsChanged(true);
  };

  const handleSave = () => onClose(planDiscount ? [...discounts, planDiscount] : discounts);

  const removeDiscounts = (id: string) => {
    setDiscounts(discounts.filter((discount) => discount.id !== id));
  };

  const deleteAllDiscounts = () => {
    setDiscounts([]);
    setPlanDiscount(null);
    setPlanDiscountKey((prevState) => prevState + 1);
    setIsChanged(true);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'deleteRow',
      type: 'actions',
      width: 40,
      editable: false,
      getActions: ({ id }: GridRowParams) => [
        <Button
          key={`remove-${id}`}
          variant='text'
          onClick={() => removeDiscounts(id)}
          aria-label='delete discount set'
        >
          <CloseIcon />
        </Button>,
      ],
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      minWidth: 350,
      type: 'custom',
      editable: false,
      renderCell: (params) => (
        <ScheduleCell
          error={params.row?.isError ? 'Select at least one property' : null}
          readOnly={isReadOnly}
          updateAdSet={updateDiscounts}
          retailerId={retailerId}
          {...params}
        />
      ),
    },
    {
      field: 'mediaSpace',
      headerName: 'Media type',
      minWidth: 270,
      type: 'custom',
      editable: false,
      renderCell: (params) => (
        <MediaSpaceCell
          error={params.row?.isError ? 'Select at least one property' : null}
          readOnly={isReadOnly}
          updateAdSet={updateDiscounts}
          retailerId={retailerId}
          {...params}
        />
      ),
    },
    {
      field: 'placement',
      headerName: 'Placement',
      minWidth: 250,
      type: 'custom',
      editable: false,
      renderCell: (params) => (
        <PlacementCell
          error={params.row?.isError ? 'Select at least one property' : null}
          readOnly={isReadOnly}
          updateAdSet={updateDiscounts}
          retailerId={retailerId}
          {...params}
        />
      ),
    },
    {
      editable: false,
      width: 40,
      headerName: '',
      type: 'custom',
      field: 'arrow',
      align: 'center',
      renderCell: () => <ArrowForwardIcon />,
    },
    {
      field: 'discount',
      minWidth: 120,
      editable: false,
      headerName: 'Amount off',
      type: 'custom',
      renderCell: (params) => (
        <DiscountSelector
          onChange={(data) => updateDiscounts({ ...params?.row, discount: data?.discount, type: data?.type })}
          variant='standard'
          row={params?.row}
          readOnly={isReadOnly}
        />
      ),
    },
  ];

  return (
    <Modal title='Discounts' size='large' open={isOpen} onClose={() => onClose(null)}>
      <>
        <Box display='flex' flexDirection='column' justifyContent='start' gap={1} mt={2}>
          <Typography variant='h6'>Entire Plan</Typography>
          <DiscountSelector
            onChange={updatePlanDiscount}
            row={{ ...planDiscount, planId: planId }}
            variant={'outlined'}
            readOnly={isReadOnly}
            key={planDiscountKey}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          sx={{ backgroundColor: 'white' }}
          gap={1}
          mt={2}
        >
          <Typography variant='h6'>Ad set properties</Typography>
          <DataGrid
            // slots={{
            //   columnHeaders: () => null,
            // }}
            disableColumnFilter
            disableColumnSorting
            rowHeight={70}
            rows={discounts}
            columns={columns}
            pageSize={5}
            pageSizeOptions={[5, 100]}
          />
        </Box>
        {!isReadOnly && (
          <Box justifyContent='space-between' display='flex' alignItems='center'>
            <Button onClick={handleAddRow} aria-label='Add discount' variant='text' color='primary'>
              + Add discount
            </Button>
            <Button onClick={deleteAllDiscounts} aria-label='Delete all' variant='text' color='error' sx={{ gap: 1 }}>
              <FontAwesomeIcon icon={faTrash} />
              <span>Delete All</span>
            </Button>
          </Box>
        )}
        <Box justifyContent='space-between' display='flex' alignItems='center' mt={4}>
          {isReadOnly ? (
            <>
              <Button variant='text' onClick={() => onClose(null)} aria-label='Close'>
                Close
              </Button>
            </>
          ) : (
            <>
              <Button variant='text' onClick={() => onClose(null)} aria-label='Cancel' color='error'>
                Cancel
              </Button>
              <Button
                variant='contained'
                onClick={handleSave}
                aria-label='Save Discounts'
                sx={{ gap: 1 }}
                color='primary'
                size='large'
                type='button'
                disabled={!isChanged || hasErrors}
              >
                <SaveIcon />
                Save Discounts
              </Button>
            </>
          )}
        </Box>
      </>
    </Modal>
  );
};
