import moment from 'moment';

/**
 * Converts a string to title case, capitalizing the first letter of each word.
 *
 * @param {string} string - The string to convert to title case.
 * @returns {string} The string with the first letter of each word capitalized.
 */
export function titleCase(string) {
  return string
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
}

/**
 * Converts a snake_case string to title case, replacing underscores with spaces and capitalizing the first letter of each word.
 *
 * @param {string} string - The snake_case string to convert to title case.
 * @returns {string} The string converted to title case.
 */
export function snakeCaseToTitleCase(string) {
  return titleCase(string.replaceAll('_', ' '));
}
/**
 * Formats a number with commas as thousands separators.
 *
 * @param {number} num - The number to format.
 * @returns {string} The formatted number as a string with commas as thousands separators.
 */
export function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Trims a string to a specified length and appends an ellipsis if the string exceeds that length.
 * If the input is a number, it converts it to a string before trimming.
 *
 * @param {string} string - The string or number to trim.
 * @param {number} len - The maximum length of the trimmed string.
 * @returns {string} The trimmed string with an ellipsis if it exceeds the specified length.
 */
export function trimEllipses(string, len) {
  if (String(string).length <= len) {
    return String(string);
  }

  if (typeof string !== 'string') {
    return String(string);
  }

  return String(string).slice(0, len) + '…';
}

/**
 * Compares two strings lexicographically.
 *
 * @param {string} str1 - The first string to compare.
 * @param {string} str2 - The second string to compare.
 * @returns {number} Returns 0 if the strings are equal, 1 if the first string is greater, and -1 if the second string is greater.
 */
export function strcmp(str1, str2) {
  return str1 === str2 ? 0 : str1 > str2 ? 1 : -1;
}

/**
 * Returns a human-readable string representing the time duration from the given date to now.
 *
 * @param {string|Date} date - The date to compare with the current time.
 * @returns {string} A human-readable string representing the time duration.
 */
export function lastEditedText(date) {
  const momentDate = moment.utc(date);
  const momentNow = moment();
  const duration = moment.duration(momentDate.diff(momentNow));
  return duration.humanize(true);
}

/**
 * Truncates a string to a specified length and appends an ellipsis if the string exceeds that length.
 *
 * @param {string} text - The string to truncate.
 * @param {number} len - The maximum length of the truncated string.
 * @returns {string} The truncated string with an ellipsis if it exceeds the specified length.
 */
export function truncate(text, len) {
  return text.length > len ? text.slice(0, len) + '...' : text;
}
/**
 * Capitalizes the first letter of the given string.
 *
 * @param {string} string - The string to capitalize.
 * @returns {string} The string with the first letter capitalized.
 */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
