import React, { useContext } from 'react';
import { Location, useGetBundles } from 'v2/lib/api/ad-management';
import { ListItemButton } from '@mui/material';
import { PlacementSelectorContext } from '../context/PlacementSelectorProvider';

interface PlacementListItemBtnProps {
  value: Location;
}

export const PlacementListItemBtn = ({ value }: PlacementListItemBtnProps) => {
  const { locations, handleLocationClick, retailerId } = useContext(PlacementSelectorContext);
  const { data, isLoading, isError } = useGetBundles(
    {
      organisation_id: retailerId,
      location_id: value.id,
    },
    {
      query: {
        queryKey: ['bundle', value.id],
      },
    }
  );
  const disabled = isLoading || data?.data.length === 0 || isError;
  return (
    <ListItemButton
      selected={locations?.some((location) => location.id === value.id)}
      onClick={() => handleLocationClick(value)}
      disabled={disabled}
    >
      {value?.name}
    </ListItemButton>
  );
};
