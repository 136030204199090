import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlanData } from 'v2/features/Planning/PlanContext';
import { useCreatePlan, useTransitionPlan } from 'v2/lib/api/ad-management';
import { BodyType } from '../axios/axiosMainInstance';
import { PlanCreateForm } from 'v2/lib/api/ad-management';
import { useProposePlan } from 'v2/features/Planning/PlanPage/useProposePlan';
import { NewPlanModalState } from 'v2/components/NewPlanModal/NewPlanModal';
import { useProcessingContext } from 'v2/features/GlobalNotifications/ProcessingContext';

export const useNewPlanActions = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const createPlan = useCreatePlan();
  const { proposePlan, savePlan } = useProposePlan();
  const transitionPlan = useTransitionPlan();
  const { startProcess, finishProcess, setActivePrimaryProcess } = useProcessingContext();

  const transformPlanData = (planData: PlanData): BodyType<PlanCreateForm> => ({
    name: planData.name || '',
    owner_organisation_id: planData.retailerId || '',
    supplier_organisation_id: planData.brandId || '',
    wallet_id: planData.walletId || '',
  });

  const createAndSavePlan = async (planData: PlanData) => {
    setLoading(true);
    const processId = startProcess('Saving plan');
    setActivePrimaryProcess(processId);
    try {
      const transformedPlanData = transformPlanData(planData);
      const createdPlan = await createPlan.mutateAsync({ data: transformedPlanData });

      if (createdPlan.id) {
        await savePlan(createdPlan.id);
        navigate(`/plans-ad-sets/${createdPlan.id}`);
      }
      finishProcess(processId, { success: true, message: 'Plan saved' });
    } catch (error) {
      finishProcess(processId, { success: false, message: 'Failed to save plan' });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createAndProposePlan = async (planData: PlanData) => {
    setLoading(true);
    const processId = startProcess('Proposing plan');
    setActivePrimaryProcess(processId);
    try {
      const transformedPlanData = transformPlanData(planData);
      const createdPlan = await createPlan.mutateAsync({ data: transformedPlanData });

      if (createdPlan.id) {
        await proposePlan(createdPlan.id);
        navigate('/plans-ad-sets', { replace: true });
      }
      finishProcess(processId, { success: true, message: 'Plan proposed' });
    } catch (error) {
      finishProcess(processId, { success: false, message: 'Failed to propose plan' });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createAndProposePlanWithoutPlanContext = async (plan: NewPlanModalState) => {
    const processId = startProcess('Creating and proposing plan');
    setActivePrimaryProcess(processId);
    try {
      const requiredFields = ['name', 'retailerId', 'brandId', 'walletId'] as const;
      const missingFields = requiredFields.filter((field) => !plan[field]);

      if (missingFields.length > 0) {
        throw new Error(`Cannot propose plan: Missing required fields: ${missingFields.join(', ')}`);
      }

      const createdPlan = await createPlan.mutateAsync({
        data: {
          name: plan.name,
          owner_organisation_id: plan.retailerId,
          supplier_organisation_id: plan.brandId,
          wallet_id: plan.walletId,
        },
      });

      transitionPlan.mutate(
        { id: createdPlan.id, data: { status: 'proposed' } },
        {
          onSuccess: () => {
            navigate('/plans-ad-sets', { replace: true });
            finishProcess(processId, { success: true, message: 'Plan proposed' });
          },
          onError: () => {
            finishProcess(processId, { success: false, message: 'Failed to propose plan' });
          },
        }
      );
    } catch (error) {
      finishProcess(processId, { success: false, message: 'Failed to propose plan' });
    }
  };

  return {
    loading,
    createAndSavePlan,
    createAndProposePlan,
    createAndProposePlanWithoutPlanContext,
  };
};
