import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, type DateRange, DateRangePicker } from '@zitcha/component-library';
import {
  AdSetSearch,
  AdSetSearchActionTypesItem,
  AdSetStatusEnum,
  PlanningActionTypeItem,
  useGetPlanningActionTypes,
  Location,
} from 'v2/lib/api/ad-management';
import { MediaType, useGetMediaSpaces } from 'v2/lib/api/inventory';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { ScopedOrganisationsAutocomplete } from 'v2/components/Autocomplete/ScopedOrganisationsAutocomplete';
import { OptionsType, SelectedOptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';
import { PlacementAutocomplete } from 'v2/components/PlacementList/PlacementAutocomplete';
import { capitalizeFirstLetter } from 'lib/strings';

type AdSetsTableFiltersProps = {
  onFilterChange: (newFilter: Partial<AdSetSearch>) => void;
  isRetailerView: boolean;
};
type FilterOption<T> = { id: T; selected: boolean; name: string };

const mapToFilterOption = (option: string | MediaType): FilterOption<string> => ({
  id: typeof option === 'string' ? option : option.id,
  selected: false,
  name: capitalizeFirstLetter(typeof option === 'string' ? option : option.name).replace('_', ' '),
});

const mapToActionFilterOption = (action: PlanningActionTypeItem): FilterOption<string> => ({
  id: action.name,
  selected: false,
  name: capitalizeFirstLetter(action.name.replace('_', ' ').toLowerCase()),
});

const handleOptionChange =
  (
    options: Array<FilterOption<string>>,
    setOptions: React.Dispatch<React.SetStateAction<Array<FilterOption<string>>>>
  ) =>
  (_: unknown, newValue: Array<FilterOption<string>>) => {
    setOptions(
      options.map((option) => ({
        ...option,
        selected: newValue.some((selectedOption) => selectedOption.id === option.id) || false,
      }))
    );
  };

export const AdSetsTableFilters: React.FC<AdSetsTableFiltersProps> = ({ onFilterChange, isRetailerView }) => {
  const organisation = useUserOrganisation();
  const { data: getMediaTypesData, isLoading: isLoadingMediaTypes } = useGetMediaSpaces({
    organisation_id: organisation.id,
  });
  const { data: actionTypes, isLoading: loadingActionTypes } = useGetPlanningActionTypes(
    {
      organisation_id: organisation.id,
    },
    {
      query: {
        select: (response) => response.data as Array<PlanningActionTypeItem>,
      },
    }
  );

  const allStatuses: Array<AdSetStatusEnum> = Object.keys(AdSetStatusEnum) as Array<AdSetStatusEnum>;

  const [adsetActionOptions, setAdsetActionOptions] = useState<Array<FilterOption<string>>>([]);
  const [selectedOrganisations, setSelectedOrganisations] = useState<SelectedOptionsType>([]);
  const [statusOptions, setStatusOptions] = useState<Array<FilterOption<string>>>(allStatuses.map(mapToFilterOption));
  const [mediaTypeOptions, setMediaTypeOptions] = useState<Array<FilterOption<string>>>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | undefined>({ from: undefined, to: undefined });
  const [selectedPlacementOptions, setSelectedPlacementOptions] = useState<Array<Location>>([]);

  useEffect(() => {
    const mediaTypes = getMediaTypesData?.data;
    if (mediaTypes) {
      setMediaTypeOptions(mediaTypes.map(mapToFilterOption));
    }
  }, [getMediaTypesData]);

  useEffect(() => {
    if (!loadingActionTypes && actionTypes) {
      const options = actionTypes.map(mapToActionFilterOption);
      setAdsetActionOptions(options);
    }
  }, [loadingActionTypes, actionTypes]);

  //compute selected options from options
  const selectedStatusOptions = useMemo(() => statusOptions.filter((option) => option.selected), [statusOptions]);
  const selectedAdsetActionOptions = useMemo(
    () => adsetActionOptions.filter((option) => option.selected),
    [adsetActionOptions]
  );
  const selectedMediaTypeOptions = useMemo(
    () => mediaTypeOptions.filter((option) => option.selected),
    [mediaTypeOptions]
  );

  const calculateStatusFilter = () => {
    return selectedStatusOptions.map((option: FilterOption<AdSetStatusEnum>) => option.id);
  };

  const getRetailerSupplierFilters = () => {
    if (isRetailerView) {
      return {
        supplier_ids:
          Array.isArray(selectedOrganisations) && selectedOrganisations.length
            ? selectedOrganisations?.map((option: OptionsType) => String(option.id))
            : undefined,
      };
    }
    return {
      retailer_ids:
        Array.isArray(selectedOrganisations) && selectedOrganisations.length
          ? selectedOrganisations.map((option: OptionsType) => String(option.id))
          : undefined,
    };
  };

  useEffect(() => {
    onFilterChange({
      statuses: calculateStatusFilter().length ? calculateStatusFilter() : undefined,
      media_space_ids:
        selectedMediaTypeOptions && selectedMediaTypeOptions.length > 0
          ? selectedMediaTypeOptions.map((option) => option.id)
          : undefined,
      start_date: selectedDateRange?.to ? selectedDateRange?.from?.toISOString() : undefined,
      end_date: selectedDateRange?.to?.toISOString(),
      ...getRetailerSupplierFilters(),
      action_types:
        selectedAdsetActionOptions && selectedAdsetActionOptions.length > 0
          ? (selectedAdsetActionOptions.map((option) => option.id) as Array<AdSetSearchActionTypesItem>)
          : undefined,
      location_ids:
        selectedPlacementOptions && selectedPlacementOptions.length > 0
          ? selectedPlacementOptions.reduce((acc, option) => {
              if (option.id) acc.push(option.id);
              return acc;
            }, [] as Array<string>)
          : undefined,
    });
  }, [
    selectedOrganisations,
    selectedMediaTypeOptions,
    selectedDateRange,
    selectedStatusOptions,
    selectedPlacementOptions,
    selectedAdsetActionOptions,
  ]);

  return (
    <Box sx={{ display: 'flex', gap: 2, padding: 2 }} className='twd-overflow-auto'>
      <Autocomplete
        id='status'
        label='Status'
        options={statusOptions}
        value={selectedStatusOptions}
        onChange={handleOptionChange(statusOptions, setStatusOptions)}
        sx={{ minWidth: 200 }}
        multiple
        getOptionLabel={(option) => option.name}
      />
      <Autocomplete
        id='adset-actions'
        label='Adset actions'
        options={adsetActionOptions}
        value={selectedAdsetActionOptions}
        onChange={handleOptionChange(adsetActionOptions, setAdsetActionOptions)}
        sx={{ minWidth: 200, '& .MuiFormLabel-root': { textTransform: 'none' } }}
        multiple
        getOptionLabel={(option) => option.name}
        loading={loadingActionTypes}
      />
      <ScopedOrganisationsAutocomplete
        type='filter'
        showLabel={false}
        placeholderText={isRetailerView ? 'Brands' : 'Retailers'}
        selectedOptions={selectedOrganisations}
        setSelectedOptions={setSelectedOrganisations}
        autoCompletePropsOverride={{ sx: { minWidth: 200 } }}
      />
      <DateRangePicker placeholderText='Schedule' date={selectedDateRange} setDate={setSelectedDateRange} />
      <Autocomplete
        id='media-type'
        label='Media type'
        value={selectedMediaTypeOptions}
        options={mediaTypeOptions}
        onChange={handleOptionChange(mediaTypeOptions, setMediaTypeOptions)}
        sx={{ minWidth: 200, '& .MuiFormLabel-root': { textTransform: 'none' } }}
        multiple
        getOptionLabel={(option) => option.name}
        loading={isLoadingMediaTypes}
      />
      {organisation.is_retailer && (
        <PlacementAutocomplete
          multiple={true}
          value={selectedPlacementOptions}
          onChange={(newValue) => {
            setSelectedPlacementOptions(newValue as Array<Location>);
          }}
          textFieldProps={{
            variant: 'outlined',
            label: 'Placements',
          }}
          retailerId={organisation.id}
        />
      )}
    </Box>
  );
};
