import React, { ChangeEvent, createContext, useState } from 'react';
import type { Location, LocationType } from 'v2/lib/api/ad-management';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';

interface Context {
  retailerId: string;
  locations: Array<Location> | undefined;
  locationType: LocationType | undefined;
  setLocations: React.Dispatch<React.SetStateAction<Array<Location>>>;
  setLocationType: React.Dispatch<React.SetStateAction<LocationType | undefined>>;
  searchLocationNameTerm: string;
  searchLocationNameTermDebounced: string;
  searchLocationTypeTerm: string;
  setSearchLocationNameTermDebounced: (value: unknown) => void;
  handleSearchTerm: (e: ChangeEvent<HTMLInputElement>) => void;
  showSecondColumn: boolean;
  handleLocationClick: (location: Location) => void;
}
const defaultMethod = () => {
  // This is a default implementation that does nothing.
};
const defaultContext: Context = {
  retailerId: '',
  locations: undefined,
  locationType: undefined,
  setLocations: defaultMethod,
  setLocationType: defaultMethod,
  searchLocationNameTerm: '',
  searchLocationNameTermDebounced: '',
  searchLocationTypeTerm: '',
  setSearchLocationNameTermDebounced: defaultMethod,
  handleSearchTerm: defaultMethod,
  showSecondColumn: false,
  handleLocationClick: defaultMethod,
};

export const PlacementSelectorContext = createContext<Context>(defaultContext);

export const PlacementSelectorProvider = ({
  children,
  initialState,
  multiple = false,
  retailerId,
}: {
  children: React.ReactNode;
  initialState: {
    locations?: Array<Location>;
  };
  retailerId: string;
  multiple?: boolean;
}) => {
  const [locations, setLocations] = useState<Array<Location>>(initialState.locations || []);
  const [locationType, setLocationType] = useState<LocationType | undefined>(undefined);

  const [searchLocationTypeTerm, setSearchLocationTypeTerm] = useState<string>(''); //TODO: Get from API
  const [searchLocationNameTerm, setSearchLocationNameTerm] = useState<string>('');
  const [searchLocationNameTermDebounced, setSearchLocationNameTermdebounced] = useState<string>('');

  const setSearchLocationNameTermDebounced = useDebounceSetter(setSearchLocationNameTermdebounced);
  const handleNameSearch = (term: string) => {
    setSearchLocationNameTermDebounced(term);
  };
  const handleSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
    if (locationType) {
      searchLocationTypeTerm && setSearchLocationTypeTerm('');
      setSearchLocationNameTerm(e.target.value);
      handleNameSearch(e.target.value);
    } else {
      searchLocationNameTerm && setSearchLocationNameTerm('');
      setSearchLocationTypeTerm(e.target.value);
    }
  };
  const handleLocationClick = (location: Location) => {
    if (multiple) {
      if (locations.some((l) => l.id === location.id)) {
        const newLocations = locations.filter((l) => l.id !== location.id);
        setLocations(newLocations);
      } else {
        setLocations([...locations, location]);
      }
    } else {
      setLocations([location]);
    }
  };

  return (
    <PlacementSelectorContext.Provider
      value={{
        retailerId: retailerId,
        locations,
        locationType,
        setLocations,
        setLocationType,
        searchLocationNameTerm,
        searchLocationNameTermDebounced,
        searchLocationTypeTerm,
        setSearchLocationNameTermDebounced,
        handleSearchTerm,
        showSecondColumn: locationType !== undefined,
        handleLocationClick,
      }}
    >
      {children}
    </PlacementSelectorContext.Provider>
  );
};
