//returning first rejected promise to mirror previous implementation of promise.All()
//future improvement: return all rejected promises and update consumers to handle multiple rejections
export const batchedPromises = async <T>(promises: Array<() => Promise<T>>, batchSize: number): Promise<Array<T>> => {
  const results: Array<T> = [];
  for (let i = 0; i < promises.length; i += batchSize) {
    const batch = promises.slice(i, i + batchSize);
    const batchResults = await Promise.allSettled(batch.map((promise) => promise()));

    // Check if any promise in the batch was rejected
    const rejectedPromise = batchResults.find((result) => result.status === 'rejected');
    if (rejectedPromise) {
      // Propagate the rejection by returning a rejected promise
      return Promise.reject((rejectedPromise as PromiseRejectedResult).reason);
    }

    // All promises in this batch were fulfilled
    const successfulResults = batchResults.map((result) => (result as PromiseFulfilledResult<T>).value);
    results.push(...successfulResults);
  }
  return results;
};
