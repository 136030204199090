import React, { useState } from 'react';
import { AutocompleteFactory, AutocompleteFactoryProps } from 'v2/components/Autocomplete/AutocompleteFactory';
import { useDebounceSetter } from 'v2/lib/hooks/useDebounceSetter';
import { useTeamsListScoped } from 'v2/lib/api/access-management';

type UserTeamsAutocompleteProps = Omit<AutocompleteFactoryProps, 'options'>;

export const UserTeamsAutocomplete: React.FC<UserTeamsAutocompleteProps> = (props) => {
  const [userTeamsQuery, setUserTeamsQuery] = useState('');
  const { data, isLoading } = useTeamsListScoped({
    q: userTeamsQuery,
    page: 1,
    perPage: 50,
  });
  const debouncedPlanQuery = useDebounceSetter(setUserTeamsQuery);

  return (
    <AutocompleteFactory
      labelText={props.labelText || 'Teams'}
      placeholderText={props.placeholderText || 'All User Teams'}
      isLoading={isLoading}
      {...props}
      options={data?.data || []}
      debouncedQuery={debouncedPlanQuery}
    />
  );
};
