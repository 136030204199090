import React from 'react';
import { Checkbox, FormControl } from '@zitcha/component-library';
import { FormControlLabel, FormHelperText } from '@mui/material';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface CheckboxComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    defaultChecked?: string;
    checkboxLabel?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value?: boolean;
  isReadOnly: boolean;
  error?: boolean;
  helperText?: string;
}

export const CheckboxComponent = ({
  properties,
  fieldName,
  onChange,
  onBlur,
  value,
  isReadOnly,
  error,
  helperText,
}: CheckboxComponentProps) => {
  const isChecked = value !== undefined ? value : properties.defaultChecked === 'true';

  return (
    <FormControl error={error} component='fieldset'>
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={onChange} onBlur={onBlur} disabled={isReadOnly} />}
        label={properties.checkboxLabel}
        data-testid={`${fieldName}-checkbox-component`}
      />
      {helperText && <FormHelperText style={{ whiteSpace: 'pre-line' }}>{helperText}</FormHelperText>}{' '}
    </FormControl>
  );
};
