import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, GridRenderCellParams, Typography } from '@zitcha/component-library';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { isSpaceUnavailable } from '../utils/isSpaceUnavailable';

export const RenderInventoryCell = (params: GridRenderCellParams) => {
  const ucfirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const organisation = useUserOrganisation();
  const getDisplayValue = () => {
    if (isSpaceUnavailable(params)) return 'Unavailable';
    if (organisation.is_retailer) {
      return params.value?.supplier?.name;
    } else if (params.value?.owner?.name && params?.value?.status) {
      return ucfirst(params?.value?.status);
    }
  };
  return (
    <Typography
      variant='body2'
      component='div'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: 1,
      }}
    >
      {params.value?.status === 'clashed' && !isSpaceUnavailable(params) ? <WarningAmberIcon color='error' /> : null}
      <Box sx={{ maxWidth: '250px', textWrap: 'wrap', textAlign: 'center' }}>
        {getDisplayValue()?.replace('_', ' ') || ''}
      </Box>
    </Typography>
  );
};
