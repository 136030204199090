//helper function to get the row class name when we want to highlight the row red
export const getRowClassName = () => {
  return 'row-red-background';
};
export const styles = {
  '& .row-red-background': {
    backgroundColor: '#FDEDED',
    '&:hover': {
      backgroundColor: '#FDEDED',
    },
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: 'inherit',
  },
};
