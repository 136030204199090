import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const FullWidthDiv = styled.div`
  width: 100%;
`;

const fallback_logo = '/ad_preview/logo.png';

const CustomOption = ({ data, innerRef, innerProps, isFocused, isSelected }) => {
  const backgroundColor = isSelected ? '#7155FF' : isFocused ? '#F4F4F4' : 'white';
  const customOptionStyles = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor,
    color: isSelected ? 'white' : '#777785',
    padding: '8px 12px',
  };
  return (
    <div ref={innerRef} style={customOptionStyles} {...innerProps}>
      <img src={data.logo || fallback_logo} alt={data.name} style={{ maxWidth: '20px', marginRight: '10px' }} />
      {data.label}
    </div>
  );
};

const SingleValue = ({ data }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src={data.logo || fallback_logo} alt={data.label} style={{ maxWidth: '20px', marginRight: '10px' }} />
    {data.label}
  </div>
);

const customStyles = {
  control: (base) => ({
    ...base,
    width: '100%',
  }),
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

export const ComboBox = ({ options, value, onChange, placeholder = 'Select...', readOnly, ariaLabel }) => {
  //so that we can have always unexpired url from facebook otherwise the page logo will be broken image if we use the one from ad.
  const matchingPage = options?.find((option) => option?.id === value?.id);

  return (
    <FullWidthDiv>
      <Select
        aria-label={ariaLabel ?? ''}
        options={options}
        value={matchingPage}
        onChange={(v) => onChange(v)}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.id}
        components={{ Option: CustomOption, SingleValue: SingleValue }}
        styles={customStyles}
        menuPlacement='auto'
        placeholder={placeholder}
        isClearable={!readOnly}
        isSearchable={!readOnly}
        isDisabled={readOnly}
      />
    </FullWidthDiv>
  );
};
