import { Input } from '@mui/material';
import { CloseIcon, FilterListIcon, Grid, IconButton, Typography } from '@zitcha/component-library';
import React, { useContext } from 'react';
import { PlacementSelectorContext } from '../context/PlacementSelectorProvider';
interface FilterBarProps {
  onClose: () => void;
}

export const FilterBar = ({ onClose }: FilterBarProps) => {
  const { locationType, handleSearchTerm, searchLocationNameTerm, searchLocationTypeTerm } =
    useContext(PlacementSelectorContext);

  return (
    <Grid
      item
      display='flex'
      alignItems='center'
      gap={1}
      xs
      sx={{
        bgcolor: '#F5F5F5',
        borderBottom: '1px solid #E0E0E0',
        padding: '10px',
      }}
    >
      <FilterListIcon />
      <Typography variant='subtitle2'> Filter </Typography>
      <Input
        placeholder='Type to filter'
        value={locationType ? searchLocationNameTerm : searchLocationTypeTerm}
        onChange={handleSearchTerm}
        fullWidth
      />
      <IconButton onClick={onClose} ariaLabel='exit placement selection'>
        <CloseIcon />
      </IconButton>
    </Grid>
  );
};
