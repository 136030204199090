import React, { ReactNode } from 'react';
import { Box } from '@zitcha/component-library';

export const SettingsTableContainer = ({ children }: { children: ReactNode }) => {
  // TODO ->  for now for the sake of consistency, we will use 4px as the border radius , ideally we should get it from the theme
  return (
    <Box
      padding={2}
      mt={2}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      gap={2}
      borderRadius={'4px'}
      bgcolor={'#fff'}
    >
      {children}
    </Box>
  );
};
