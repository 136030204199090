/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Zitcha System Management API
 * This is the API endpoint to manage systems in Zitcha.
 * OpenAPI spec version: 1.0.0
 */
import { faker } from '@faker-js/faker';
import { HttpResponse, http } from 'msw';
import type { NetworkBrandingResource, UploadNetworkBrandingLogoResponse } from '../model';

export const getGetNetworkBrandingResponseMock = (
  overrideResponse: Partial<NetworkBrandingResource> = {}
): NetworkBrandingResource => ({
  data: faker.helpers.arrayElement([
    {
      settings: faker.helpers.arrayElement([
        {
          network_logo: faker.helpers.arrayElement([
            {
              creator_id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
              extension: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              name: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              path: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              storage: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              url: faker.helpers.arrayElement([faker.word.sample(), undefined]),
            },
            undefined,
          ]),
          theme: faker.helpers.arrayElement([{}, undefined]),
        },
        undefined,
      ]),
      updated: faker.helpers.arrayElement([faker.word.sample(), undefined]),
    },
    undefined,
  ]),
  ...overrideResponse,
});

export const getGetNetworkBrandingResponseMock200 = (
  overrideResponse: Partial<NetworkBrandingResource> = {}
): NetworkBrandingResource => ({
  data: faker.helpers.arrayElement([
    {
      settings: faker.helpers.arrayElement([
        {
          network_logo: faker.helpers.arrayElement([
            {
              creator_id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
              extension: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              name: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              path: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              storage: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              url: faker.helpers.arrayElement([faker.word.sample(), undefined]),
            },
            undefined,
          ]),
          theme: faker.helpers.arrayElement([{}, undefined]),
        },
        undefined,
      ]),
      updated: faker.helpers.arrayElement([faker.word.sample(), undefined]),
    },
    undefined,
  ]),
  ...overrideResponse,
});

export const getUpdateNetworkBrandingResponseMock = (
  overrideResponse: Partial<NetworkBrandingResource> = {}
): NetworkBrandingResource => ({
  data: faker.helpers.arrayElement([
    {
      settings: faker.helpers.arrayElement([
        {
          network_logo: faker.helpers.arrayElement([
            {
              creator_id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
              extension: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              name: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              path: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              storage: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              url: faker.helpers.arrayElement([faker.word.sample(), undefined]),
            },
            undefined,
          ]),
          theme: faker.helpers.arrayElement([{}, undefined]),
        },
        undefined,
      ]),
      updated: faker.helpers.arrayElement([faker.word.sample(), undefined]),
    },
    undefined,
  ]),
  ...overrideResponse,
});

export const getUpdateNetworkBrandingResponseMock200 = (
  overrideResponse: Partial<NetworkBrandingResource> = {}
): NetworkBrandingResource => ({
  data: faker.helpers.arrayElement([
    {
      settings: faker.helpers.arrayElement([
        {
          network_logo: faker.helpers.arrayElement([
            {
              creator_id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
              extension: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              name: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              path: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              storage: faker.helpers.arrayElement([faker.word.sample(), undefined]),
              url: faker.helpers.arrayElement([faker.word.sample(), undefined]),
            },
            undefined,
          ]),
          theme: faker.helpers.arrayElement([{}, undefined]),
        },
        undefined,
      ]),
      updated: faker.helpers.arrayElement([faker.word.sample(), undefined]),
    },
    undefined,
  ]),
  ...overrideResponse,
});

export const getUploadNetworkBrandingLogoResponseMock = (
  overrideResponse: Partial<UploadNetworkBrandingLogoResponse> = {}
): UploadNetworkBrandingLogoResponse => ({
  network_logo: faker.helpers.arrayElement([
    {
      creator_id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
      extension: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      name: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      path: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      storage: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      url: faker.helpers.arrayElement([faker.word.sample(), undefined]),
    },
    undefined,
  ]),
  ...overrideResponse,
});

export const getUploadNetworkBrandingLogoResponseMock201 = (
  overrideResponse: Partial<UploadNetworkBrandingLogoResponse> = {}
): UploadNetworkBrandingLogoResponse => ({
  network_logo: faker.helpers.arrayElement([
    {
      creator_id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
      extension: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      name: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      path: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      storage: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      url: faker.helpers.arrayElement([faker.word.sample(), undefined]),
    },
    undefined,
  ]),
  ...overrideResponse,
});

export const getGetNetworkBrandingMockHandler = (
  overrideResponse?:
    | NetworkBrandingResource
    | ((
        info: Parameters<Parameters<typeof http.get>[1]>[0]
      ) => Promise<NetworkBrandingResource> | NetworkBrandingResource)
) => {
  return http.get('*/network-branding/:retailerId', async (info) => {
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getGetNetworkBrandingResponseMock()
      ),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  });
};

export const getGetNetworkBrandingMockHandler200 = (
  overrideResponse?:
    | NetworkBrandingResource
    | ((
        info: Parameters<Parameters<typeof http.get>[1]>[0]
      ) => Promise<NetworkBrandingResource> | NetworkBrandingResource)
) => {
  return http.get('*/network-branding/:retailerId', async (info) => {
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getGetNetworkBrandingResponseMock200()
      ),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  });
};

export const getGetNetworkBrandingMockHandler400 = () => {
  return http.get('*/network-branding/:retailerId', () => {
    return new HttpResponse(null, {
      status: 400,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  });
};

export const getUpdateNetworkBrandingMockHandler = (
  overrideResponse?:
    | NetworkBrandingResource
    | ((
        info: Parameters<Parameters<typeof http.put>[1]>[0]
      ) => Promise<NetworkBrandingResource> | NetworkBrandingResource)
) => {
  return http.put('*/network-branding', async (info) => {
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getUpdateNetworkBrandingResponseMock()
      ),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  });
};

export const getUpdateNetworkBrandingMockHandler200 = (
  overrideResponse?:
    | NetworkBrandingResource
    | ((
        info: Parameters<Parameters<typeof http.put>[1]>[0]
      ) => Promise<NetworkBrandingResource> | NetworkBrandingResource)
) => {
  return http.put('*/network-branding', async (info) => {
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getUpdateNetworkBrandingResponseMock200()
      ),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  });
};

export const getUpdateNetworkBrandingMockHandler401 = () => {
  return http.put('*/network-branding', () => {
    return new HttpResponse(null, {
      status: 401,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  });
};

export const getUploadNetworkBrandingLogoMockHandler = (
  overrideResponse?:
    | UploadNetworkBrandingLogoResponse
    | ((
        info: Parameters<Parameters<typeof http.post>[1]>[0]
      ) => Promise<UploadNetworkBrandingLogoResponse> | UploadNetworkBrandingLogoResponse)
) => {
  return http.post('*/network-branding/upload', async (info) => {
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getUploadNetworkBrandingLogoResponseMock()
      ),
      {
        status: 201,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  });
};

export const getUploadNetworkBrandingLogoMockHandler201 = (
  overrideResponse?:
    | UploadNetworkBrandingLogoResponse
    | ((
        info: Parameters<Parameters<typeof http.post>[1]>[0]
      ) => Promise<UploadNetworkBrandingLogoResponse> | UploadNetworkBrandingLogoResponse)
) => {
  return http.post('*/network-branding/upload', async (info) => {
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getUploadNetworkBrandingLogoResponseMock201()
      ),
      {
        status: 201,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  });
};

export const getUploadNetworkBrandingLogoMockHandler401 = () => {
  return http.post('*/network-branding/upload', () => {
    return new HttpResponse(null, {
      status: 401,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  });
};
export const getZitchaSystemManagementAPIMock = () => [
  getGetNetworkBrandingMockHandler(),
  getUpdateNetworkBrandingMockHandler(),
  getUploadNetworkBrandingLogoMockHandler(),
];
