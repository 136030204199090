/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Zitcha Access Management API
 * This is the API endpoint to manage access.
 * OpenAPI spec version: 1.0.0
 */

/**
 * perPage
 */
export type PerPageEnum = (typeof PerPageEnum)[keyof typeof PerPageEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PerPageEnum = {
  NUMBER_5: 5,
  NUMBER_10: 10,
  NUMBER_15: 15,
  NUMBER_25: 25,
  NUMBER_50: 50,
  NUMBER_100: 100,
} as const;
