/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Zitcha Wallet API
 * This is the API endpoint to manage wallets.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from '@tanstack/react-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import type {
  BadRequestResponse,
  ForbiddenResponse,
  GetWalletsParams,
  NotFoundResponse,
  ResponseWalletList,
} from '../model';
import { customInstance } from '../../../axios/axiosMainInstance';
import type { ErrorType } from '../../../axios/axiosMainInstance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get a list of Wallets
 */
export const getWallets = (
  params?: GetWalletsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ResponseWalletList>(
    { url: `/wallet-management/v1/wallets`, method: 'GET', params, signal },
    options
  );
};

export const getGetWalletsQueryKey = (params?: GetWalletsParams) => {
  return [`/wallet-management/v1/wallets`, ...(params ? [params] : [])] as const;
};

export const getGetWalletsQueryOptions = <
  TData = Awaited<ReturnType<typeof getWallets>>,
  TError = ErrorType<BadRequestResponse | ForbiddenResponse | NotFoundResponse>,
>(
  params?: GetWalletsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWalletsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWallets>>> = ({ signal }) =>
    getWallets(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getWallets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetWalletsQueryResult = NonNullable<Awaited<ReturnType<typeof getWallets>>>;
export type GetWalletsQueryError = ErrorType<BadRequestResponse | ForbiddenResponse | NotFoundResponse>;

/**
 * @summary Get a list of Wallets
 */
export const useGetWallets = <
  TData = Awaited<ReturnType<typeof getWallets>>,
  TError = ErrorType<BadRequestResponse | ForbiddenResponse | NotFoundResponse>,
>(
  params?: GetWalletsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetWalletsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
