import React, { useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GoogleRemarketingAudiences from '../components/audience/GoogleRemarketingAudiences';
import SelectAudience from '../components/SelectAudience';
import FacebookAudienceTable, { type AudienceArray, type Audience } from '../components/audience/FacebookAudienceTable';
import styled from 'styled-components';

const FormSection = styled.div`
  max-height: 500px;
  overflow: auto;
`;
type SelectedAudience = {
  facebook_custom_audiences?: AudienceArray;
  facebook_lookalike_audiences?: AudienceArray;
  facebook_saved_audiences?: AudienceArray;
  google_remarketing_audiences?: AudienceArray;
  standard_audience?: Audience;
};
interface Ad {
  platform: string;
  type: string;
  selected_audience?: SelectedAudience;
  audience?: Audience;
}

interface Structure {
  audiences: Array<string>;
}

interface Order {
  [key: string]: unknown;
}

interface AudiencesTabProps {
  controlId: string;
  readOnly: boolean;
  ad: Ad;
  structure: Structure;
  facebookCustomAudiences: AudienceArray;
  facebookLookalikeAudiences: AudienceArray;
  facebookSavedAudiences: AudienceArray;
  googleRemarketingAudiences: AudienceArray;
  handleChange: (ad: Partial<Ad>) => void;
  order: Order;
}
export const AudiencesTab = ({
  controlId,
  readOnly,
  ad,
  structure,
  facebookCustomAudiences,
  facebookLookalikeAudiences,
  facebookSavedAudiences,
  googleRemarketingAudiences,
  handleChange,
  order,
}: AudiencesTabProps) => {
  const handleAudienceChange = useCallback(
    (type: keyof SelectedAudience, newValue: Audience | AudienceArray, checked: boolean) => {
      if (!newValue) return;

      let updatedAudiences: AudienceArray = ad.selected_audience?.[type] || [];
      if (checked) {
        if (Array.isArray(newValue)) {
          updatedAudiences = [...newValue];
        } else {
          updatedAudiences = [...updatedAudiences, newValue];
        }
      } else {
        if (Array.isArray(newValue)) {
          updatedAudiences = [];
        } else {
          updatedAudiences = updatedAudiences.filter((e) => e.id !== newValue?.id);
        }
      }

      handleChange({
        selected_audience: {
          ...ad.selected_audience,
          [type]: updatedAudiences,
          facebook_saved_audiences: type === 'facebook_saved_audiences' ? updatedAudiences : [],
        },
      });
    },
    [ad, handleChange]
  );

  return (
    <FormSection>
      <Container>
        <Row>
          <Col sm='12'>
            {structure.audiences.includes('facebook_custom_audiences') && facebookCustomAudiences.length > 0 && (
              <FacebookAudienceTable
                audiences={facebookCustomAudiences}
                title='Custom Audiences'
                value={ad.selected_audience?.facebook_custom_audiences}
                readOnly={readOnly}
                onChange={(newValue, checked) => handleAudienceChange('facebook_custom_audiences', newValue, checked)}
              />
            )}
            {structure.audiences.includes('facebook_lookalike_audiences') && facebookLookalikeAudiences.length > 0 && (
              <FacebookAudienceTable
                audiences={facebookLookalikeAudiences}
                title='Lookalike Audiences'
                value={ad.selected_audience?.facebook_lookalike_audiences}
                readOnly={readOnly}
                onChange={(newValue, checked) =>
                  handleAudienceChange('facebook_lookalike_audiences', newValue, checked)
                }
              />
            )}

            {structure.audiences.includes('facebook_saved_audiences') && facebookSavedAudiences.length > 0 && (
              <FacebookAudienceTable
                audiences={facebookSavedAudiences}
                title='Saved Audiences (Max. 1)'
                maxOne={true} //< -- Allow only one selection
                readOnly={readOnly}
                value={ad.selected_audience?.facebook_saved_audiences}
                onChange={(newValue, checked) => {
                  if (!newValue) return;
                  let facebook_saved_audiences = ad.selected_audience?.facebook_saved_audiences || [];
                  if (checked) {
                    facebook_saved_audiences = [newValue];
                  } else {
                    facebook_saved_audiences = facebook_saved_audiences.filter((e) => e.id !== newValue?.id);
                  }
                  handleChange({
                    selected_audience: {
                      ...ad.selected_audience,
                      facebook_custom_audiences: [],
                      facebook_lookalike_audiences: [],
                      facebook_saved_audiences,
                    },
                  });
                }}
              />
            )}

            {structure.audiences.includes('google_remarketing_audience') && googleRemarketingAudiences.length > 0 && (
              <GoogleRemarketingAudiences
                controlId={`${controlId}-google-remarketing-audiences`}
                readOnly={readOnly}
                disabled={readOnly}
                platform={ad.platform}
                audiences={googleRemarketingAudiences}
                value={ad.selected_audience?.google_remarketing_audiences}
                onChange={(newValue, checked) =>
                  handleAudienceChange('google_remarketing_audiences', newValue, checked)
                }
              />
            )}

            <SelectAudience
              controlId={`${controlId}-select-audience`}
              readOnly={readOnly}
              adType={ad.type}
              platform={ad.platform}
              order={order}
              value={ad.selected_audience?.standard_audience}
              audience={ad.audience}
              adAudiences={structure.audiences}
              onChange={(audience) => {
                handleChange({
                  audience,
                  selected_audience: {
                    ...ad.selected_audience,
                    standard_audience: audience, // Auto select the standard audience
                  },
                });
              }}
              onSelect={(standard_audience) => {
                handleChange({
                  selected_audience: {
                    ...ad.selected_audience,
                    standard_audience,
                  },
                });
              }}
            />
          </Col>
        </Row>
      </Container>
    </FormSection>
  );
};
