import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, DataGrid, GridColDef, GridPaginationModel } from '@zitcha/component-library';
import { useFetchAdSets } from 'v2/lib/hooks/useFetchAdSets';
import { AdSetSearch, PerPageEnum } from 'v2/lib/api/ad-management';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { BudgetCell, SkuCodesCell } from 'v2/features/Planning/NewPlanAdSetsCellRenders';
import { AdSetActions, AdSetActionType } from 'v2/components/AdSetActions/AdSetActions';
import { AdSetData } from 'v2/features/Planning/PlanContext';
import { ViewMode } from 'v2/features/Planning/PlanPage/ViewModeType';
import { RenderSchedule } from 'v2/features/Planning/PlanPage/reviewAdSets/ViewPlanAdSetsTableCellRenderers';
import { ClashManagementModal } from 'v2/features/ClashManagement/components/ClashManagementModal';
import { EmptyState } from './EmptyState';
import { usePermissions } from 'v2/lib/hooks/usePermissions';
import { PLAN_ADSET_REVIEW, PLAN_REVIEW } from 'lib/permissions';
import { getRowClassName, styles } from './utils/styles';

interface ClashedAdSetsTableProps {
  filters: Partial<AdSetSearch>;
}

export const ClashedAdSetsTable: React.FC<ClashedAdSetsTableProps> = ({ filters }) => {
  const navigate = useNavigate();
  const organisation = useUserOrganisation();
  const isRetailer = organisation?.is_retailer;
  const userPermissions = usePermissions();
  const userCanResolveClash =
    isRetailer && userPermissions.hasPermission(PLAN_REVIEW) && userPermissions.hasPermission(PLAN_ADSET_REVIEW);
  const [clashAdSet, setClashAdSet] = useState<AdSetData | undefined>(undefined);

  const organisationTypeFilter = isRetailer
    ? { retailer_ids: [organisation.id], supplier_ids: [] }
    : { retailer_ids: [], supplier_ids: [organisation.id] };

  const initialAdSetSearch: AdSetSearch = {
    statuses: ['clashed'],
    ...organisationTypeFilter,
    ...filters,
  };

  const { adSetsData, isLoadingAdSets, adSetPagination, changeAdSetPagination, setAdSetSearch, refetch } =
    useFetchAdSets(initialAdSetSearch);

  useEffect(() => {
    setAdSetSearch((prevSearch) => {
      const newSearch: AdSetSearch = {
        ...prevSearch,
        ...filters,
        statuses: ['clashed'],
      };

      // Remove any filters that are undefined
      Object.keys(newSearch).forEach((key) => {
        if (newSearch[key as keyof AdSetSearch] === undefined) {
          delete newSearch[key as keyof AdSetSearch];
        }
      });

      return newSearch;
    });
  }, [filters, setAdSetSearch]);

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  const handleAction = useCallback(
    (action: AdSetActionType, adSet: AdSetData) => {
      switch (action) {
        case 'resolve_clash':
          setClashAdSet(adSet);
          break;
        case 'view_plan':
          navigate(`/plans-ad-sets/${adSet.plan?.id}`, { state: { viewMode: ViewMode.REVIEWING } });
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  const columns: Array<GridColDef> = [
    {
      field: isRetailer ? 'brand' : 'retailer',
      headerName: isRetailer ? 'Brand' : 'Retailer',
      width: 150,
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      width: 250,
      renderCell: (params) => <RenderSchedule bundleIds={params.row.bundleIds} />,
    },
    { field: 'media-type', headerName: 'Media type', width: 150 },
    { field: 'placement', headerName: 'Placement', width: 250 },
    {
      field: 'skuCodes',
      headerName: 'SKU code(s)',
      width: 150,
      renderCell: (params) => <SkuCodesCell {...params} />,
    },
    {
      field: 'budget',
      headerName: 'Budget',
      width: 120,
      renderCell: (params) => <BudgetCell {...params} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <Box display='flex' justifyContent='flex-end' alignItems='center' width='100%' height='100%'>
          <AdSetActions
            adSet={params.row.adSet}
            viewMode={ViewMode.SEARCH}
            actionCallback={handleAction}
            canApproveOrReject={isRetailer ?? false}
            canViewClash={isRetailer}
            canResolveClash={userCanResolveClash}
            isDashboardActions={true}
            actionTypeList={['resolve_clash', 'view_plan']}
          />
        </Box>
      ),
    },
  ];

  const rows =
    adSetsData?.map((adSet: AdSetData) => ({
      id: adSet.id,
      brand: adSet?.plan?.supplier?.name,
      retailer: adSet?.plan?.owner?.name,
      bundleIds: adSet?.bundleIds,
      'media-type': adSet?.mediaSpace?.name,
      placement: adSet.bundleLocationNames,
      budget: adSet?.price,
      adSet: adSet,
    })) || [];

  const dataGridPaginationModel = {
    pageSize: adSetPagination.perPage ?? PerPageEnum.NUMBER_15,
    page: (adSetPagination.currentPage ?? 1) - 1,
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changeAdSetPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      {adSetsData.length === 0 && !isLoadingAdSets ? (
        <EmptyState />
      ) : (
        <DataGrid
          disableColumnFilter
          disableColumnSorting
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          autoHeight
          paginationMode='server'
          getRowClassName={getRowClassName}
          loading={isLoadingAdSets}
          rowCount={adSetPagination.total || 0}
          paginationModel={dataGridPaginationModel}
          onPaginationModelChange={handlePaginationUpdate}
          sx={styles}
        />
      )}
      {clashAdSet && (
        <ClashManagementModal
          retailerId={clashAdSet.plan?.owner?.id || ''}
          bundleId={clashAdSet?.bundleIds?.[0] || ''}
          isOpen={!!clashAdSet}
          closeModal={() => setClashAdSet(undefined)}
        />
      )}
    </Box>
  );
};
