import React from 'react';
import { Skeleton } from '@mui/material';
import { Box } from '@zitcha/component-library';
import { ViewMode } from './ViewModeType';

interface LoadingSkeletonProps {
  mode: ViewMode;
}

export const LoadingSkeleton = ({ mode }: LoadingSkeletonProps): React.ReactElement => {
  return (
    <Box>
      {mode === ViewMode.EDITING && (
        <>
          {/* Simulating the Autocomplete and TextField in editing mode */}
          <Box display='flex' justifyContent='start' my={3} gap={3}>
            <Skeleton variant='rectangular' width={250} height={40} />
            <Skeleton variant='rectangular' width={225} height={40} />
          </Box>
        </>
      )}
      {/* Simulating the plan info section */}
      <Box display='flex' flexWrap='wrap' gap={2} my={3}>
        {/* Simulating DetailItems */}
        {[...Array(8)].map((_, index) => (
          <Box key={index} display='flex' alignItems='center'>
            <Skeleton variant='text' width={80} />
            <Skeleton variant='rectangular' width={100} height={24} sx={{ ml: 1 }} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
