import React, { useContext, useEffect, useMemo } from 'react';
import { Column } from './Column';
import { useInView } from 'react-intersection-observer';
import { useGetLocationsInfinite } from 'v2/lib/api/ad-management';
import { PlacementListItemBtn } from './PlacementListItemBtn';
import { Button } from '@zitcha/component-library';
import { LoadingIndicator } from './LoadingIndicator';
import { ErrorState } from './ErrorState';
import { NoSearchResults } from './NoSearchResults';
import { EmptyState } from './EmptyState';
import { PlacementSelectorContext } from '../context/PlacementSelectorProvider';

const key = 'locations';

export const LocationList = () => {
  const { ref, inView } = useInView({ threshold: 0 });
  const { locationType, retailerId, searchLocationNameTermDebounced, showSecondColumn } =
    useContext(PlacementSelectorContext);

  const {
    data,
    hasNextPage,
    isLoading: loading,
    fetchNextPage,
    isFetchingNextPage,
    isError,
  } = useGetLocationsInfinite(
    {
      organisation_id: retailerId,
      page: 1,
      name: searchLocationNameTermDebounced,
      location_type_id: locationType?.id,
    },
    {
      query: {
        queryKey: [key, locationType, searchLocationNameTermDebounced, retailerId],
        getNextPageParam: (firstPage) => {
          return firstPage.meta.current_page === firstPage.meta.last_page ? undefined : firstPage.meta.current_page + 1;
        },
        getPreviousPageParam: (firstPage, allPages) => {
          const previousPage = allPages.find((page) => page.meta.current_page === firstPage.meta.current_page - 1);

          return previousPage?.meta?.current_page ?? undefined;
        },
        enabled: !!locationType?.id,
      },
    }
  );
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const flattenedLocations = useMemo(
    () => data?.pages?.map((page) => page.data).flat(),
    [data?.pages, searchLocationNameTermDebounced]
  );

  if (loading) {
    return <LoadingIndicator borderleft />;
  }
  if (isError) {
    return <ErrorState errorFor='placements' />;
  }
  if (searchLocationNameTermDebounced.trim() !== '' && flattenedLocations?.length === 0) {
    return <NoSearchResults searchTerm={searchLocationNameTermDebounced} />;
  }
  if (!loading && !isError && flattenedLocations?.length === 0 && !searchLocationNameTermDebounced) {
    return <EmptyState />;
  }
  if (!showSecondColumn) return null;
  return (
    <Column bordered>
      <>{flattenedLocations?.map((loc) => <PlacementListItemBtn key={loc.id} value={loc} />)}</>
      {hasNextPage && (
        <div ref={ref} className='flex items-center justify-center'>
          <Button
            variant='text'
            disabled={!hasNextPage || isFetchingNextPage}
            loading={isFetchingNextPage}
            onClick={() => {
              fetchNextPage();
            }}
          >
            Load more
          </Button>
        </div>
      )}
    </Column>
  );
};
