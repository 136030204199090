import { useUpdateAdSet, useUpdateAdsInAdSet } from 'v2/lib/api/ad-management';
import { AdSet, AdSetStatusEnum } from 'v2/lib/api/ad-management/model';
import { batchedPromises } from 'v2/lib/utils/batchedPromises';
import { useQueryClient } from '@tanstack/react-query';
import { useAdSetActions } from 'v2/lib/hooks/useAdSetActions';
import { useProcessingContext } from 'v2/features/GlobalNotifications/ProcessingContext';

export const useSubmitResolvedClash = (onClose: () => void) => {
  const updateAdSet = useUpdateAdSet();
  const updateAdsInAdSet = useUpdateAdsInAdSet();
  const { approveAdSetAsync, rejectAdSetAsync } = useAdSetActions();
  const queryClient = useQueryClient();
  const { startProcess, finishProcess, setActivePrimaryProcess } = useProcessingContext();

  const submitResolvedClash = async (modifiedAdSets: Array<AdSet>, allAdSets: Array<AdSet>) => {
    const processId = startProcess('Resolving ad set clashes');
    setActivePrimaryProcess(processId);

    const updatePromises: Array<() => Promise<unknown>> = [];
    const errors: { [adSetId: string]: Array<string> } = {};
    const summary = {
      bundleUpdates: 0,
      statusChanges: { approved: 0, rejected: 0 },
      adUpdates: 0,
    };

    modifiedAdSets.forEach((adSet) => {
      const originalAdSet = allAdSets.find((originalAdSet) => originalAdSet.id === adSet.id);
      if (!originalAdSet) return;

      if (adSet.bundleIds?.[0] !== originalAdSet.bundleIds?.[0]) {
        summary.bundleUpdates++;
        updatePromises.push(() =>
          updateAdSet
            .mutateAsync({
              id: adSet.id,
              data: { new_bundle_id: adSet.bundleIds?.[0], existing_bundle_id: originalAdSet.bundleIds?.[0] },
            })
            .catch((error) => {
              if (!errors[adSet.id]) errors[adSet.id] = [];
              errors[adSet.id].push(`Failed to update ad set details: ${error.message}`);
            })
        );
      }

      if (adSet.status !== originalAdSet.status) {
        if (adSet.status === AdSetStatusEnum.reserved) {
          summary.statusChanges.approved++;
          updatePromises.push(async () => {
            const result = await approveAdSetAsync(adSet.id);
            if (!result.success) {
              if (!errors[adSet.id]) errors[adSet.id] = [];
              errors[adSet.id].push(`Failed to approve ad set: ${result.error.message}`);
            }
          });
        } else if (adSet.status === AdSetStatusEnum.draft) {
          summary.statusChanges.rejected++;
          updatePromises.push(async () => {
            const result = await rejectAdSetAsync(adSet.id);
            if (!result.success) {
              if (!errors[adSet.id]) errors[adSet.id] = [];
              errors[adSet.id].push(`Failed to reject ad set: ${result.error.message}`);
            }
          });
        }
      }

      if (adSet?.ads && adSet?.ads !== originalAdSet.ads) {
        summary.adUpdates++;
        updatePromises.push(() =>
          updateAdsInAdSet
            .mutateAsync({
              adSet: adSet.id,
              data: {
                ads: adSet.ads?.map((ad) => ({ id: ad.id, name: ad.name ?? 'Ad 1', fields: ad.fields ?? [] })) || [
                  { name: 'Ad 1', fields: [] },
                ],
              },
            })
            .catch((error) => {
              if (!errors[adSet.id]) errors[adSet.id] = [];
              errors[adSet.id].push(`Failed to update ads: ${error.message}`);
            })
        );
      }
    });

    try {
      await batchedPromises(updatePromises, 5);

      if (Object.keys(errors).length > 0) {
        const errorMessage = generateErrorMessage(errors);
        finishProcess(processId, {
          success: false,
          message: errorMessage,
          title: 'Some actions failed',
        });
        return { success: false, errorMessage };
      }

      queryClient.invalidateQueries({ queryKey: ['searchAdSets'] });
      queryClient.invalidateQueries({ queryKey: ['usePlanAdSets'] });
      onClose();

      const successMessage = 'Successfully resolved clashes';
      finishProcess(processId, {
        success: true,
        message: successMessage,
      });
      return { success: true };
    } catch (error) {
      const errorMessage = generateErrorMessage(errors);
      finishProcess(processId, {
        success: false,
        message: errorMessage,
        title: 'Some actions failed',
      });
      return { success: false, errorMessage };
    }
  };

  return submitResolvedClash;
};

const generateErrorMessage = (errors: { [adSetId: string]: Array<string> }) => {
  const errorSummary = {
    updateFailed: 0,
    rejectFailed: 0,
    approveFailed: 0,
  };

  for (const adSetErrors of Object.values(errors)) {
    adSetErrors.forEach((error) => {
      if (error.includes('Failed to update ad set details')) {
        errorSummary.updateFailed++;
      } else if (error.includes('Failed to reject ad set')) {
        errorSummary.rejectFailed++;
      } else if (error.includes('Failed to approve ad set')) {
        errorSummary.approveFailed++;
      }
    });
  }

  let errorMessage = '';

  if (errorSummary.updateFailed > 0) {
    errorMessage += `• ${errorSummary.updateFailed} ad set details failed to update\n`;
  }
  if (errorSummary.rejectFailed > 0) {
    errorMessage += `• Failed to reject ${errorSummary.rejectFailed} ad set${errorSummary.rejectFailed > 1 ? 's' : ''}\n`;
  }
  if (errorSummary.approveFailed > 0) {
    errorMessage += `• Failed to approve ${errorSummary.approveFailed} ad set${errorSummary.approveFailed > 1 ? 's' : ''}\n`;
  }
  return errorMessage;
};
