import React from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import propTypes from 'prop-types';
import { createWallet } from '../../lib/api/wallets';
import { toast } from 'react-toastify';
import WalletName from './components/form/WalletName';
import WalletMinBalance from './components/form/WalletMinBalance';
import WalletBalance from './components/form/WalletBalance';
import WalletRemark from './components/form/WalletRemark';
import WalletOverdraw from './components/form/WalletOverdraw';
import WalletDateSelector from './components/form/WalletDateSelector';
import WalletFormActionButton from './components/form/WalletFormActionButton';
import { WalletTeamSelector } from './components/form/WalletTeamSelector';

const NewWalletDialog = ({
  organisation,
  currency,
  existingWallets = [],
  onHideHandler = () => {},
  onSubmitHandler = () => {},
}) => {
  const defaultValues = {
    name: '',
    start_date: null,
    end_date: null,
    min_balance: '',
    initial_balance: '',
    teams: [],
    remark: '',
    allow_overdraw: false,
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, formState, setError, reset } = methods;
  const { isSubmitting } = formState;

  const onSubmit = async (data) => {
    if (data?.teams) {
      data.teams = data.teams.map((team) => team.id);
    }

    try {
      const res = await createWallet(organisation?.id, data);
      reset(defaultValues);
      toast.success(res?.data?.message ?? 'Wallet created');
      onSubmitHandler();
    } catch (e) {
      const errors = e.response?.data?.errors;
      if (e.response?.status !== 422) {
        toast.error('Something went wrong, please try again');
      } else {
        const keys = Object.keys(errors);
        keys.forEach((errorKey) => {
          setError(errorKey, {
            type: 'manual',
            message: errors[errorKey][0],
          });
        });
      }
    }
  };

  return (
    <>
      <Modal size='md' show={true} enforceFocus={false} onHide={onHideHandler} backdrop='static'>
        <FormProvider {...methods}>
          <Modal.Header closeButton>Create new wallet for {organisation?.name}</Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <WalletName existingWallets={existingWallets} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <WalletBalance currency={currency} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <WalletMinBalance currency={currency} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <WalletDateSelector control_name='start_date' label='Start Date' />
                </Col>
                <Col>
                  <WalletDateSelector control_name='end_date' label='End Date' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <WalletTeamSelector />
                </Col>
              </Row>
              <Row>
                <Col>
                  <WalletRemark />
                </Col>
              </Row>
              <Row>
                <Col>
                  <WalletOverdraw />
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Form.Group>
              <WalletFormActionButton handleSubmit={handleSubmit(onSubmit)} text='Create' loading={isSubmitting} />
            </Form.Group>
          </Modal.Footer>
        </FormProvider>
      </Modal>
    </>
  );
};

NewWalletDialog.propTypes = {
  organisation: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
  }),
  currency: propTypes.string.isRequired,
  existingWallets: propTypes.arrayOf(propTypes.string).isRequired,
  onHideHandler: propTypes.func.isRequired,
  onSubmitHandler: propTypes.func.isRequired,
};

export default NewWalletDialog;
