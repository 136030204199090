import { getRetailers } from 'lib/api';
import { useQuery } from '@tanstack/react-query';
import { Organisation } from './useRetailerSettings';

const fetchRetailersWithSettings = async () => {
  const response = await getRetailers();
  const data: Array<Organisation> = response.data.data;

  return data;
};

export const useRetailersWithSettings = () => {
  const { data, isError, isLoading, error } = useQuery({
    queryKey: ['fetchRetailersWithSettings'],
    queryFn: fetchRetailersWithSettings,
    retry: 1,
  });

  return {
    data,
    isError,
    isLoading,
    error,
  };
};
