/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Zitcha Ad Management API
 * This is the API endpoint to manage ads.
 * OpenAPI spec version: 1.0.0
 */

/**
 * The type of discount, either 'percentage' or 'amount'.
 */
export type CreateDiscountRequestType = (typeof CreateDiscountRequestType)[keyof typeof CreateDiscountRequestType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateDiscountRequestType = {
  percentage: 'percentage',
  fixed: 'fixed',
} as const;
