import { useQueryClient } from '@tanstack/react-query';
import { getGetPlanQueryKey, useTransitionPlan } from 'v2/lib/api/ad-management';
import { useProcessingContext } from 'v2/features/GlobalNotifications/ProcessingContext';

export const usePlanActions = () => {
  const transitionPlan = useTransitionPlan();
  const queryClient = useQueryClient();
  const { startProcess, finishProcess } = useProcessingContext();

  const agreePlan = async (planId: string) => {
    const processId = startProcess('Agreeing to plan');
    try {
      await transitionPlan.mutateAsync(
        { id: planId, data: { status: 'agreed' } },
        {
          onSuccess: () => {
            const getPlanQueryKey = getGetPlanQueryKey(planId);
            queryClient.invalidateQueries({ queryKey: getPlanQueryKey });
            finishProcess(processId, { success: true, message: 'Plan agreed' });
          },
        }
      );
    } catch (error) {
      finishProcess(processId, { success: false, message: 'Failed to agree to plan' });
    }
  };

  return {
    agreePlan,
    processing: transitionPlan.isPending,
  };
};
