import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, DataGrid, GridColDef, GridPaginationModel } from '@zitcha/component-library';
import { AdSet, PerPageEnum } from 'v2/lib/api/ad-management';
import { CenteredTableCellContainer, SkuCodesCell } from 'v2/features/Planning/NewPlanAdSetsCellRenders';
import { AdSetActions, AdSetActionType } from 'v2/components/AdSetActions/AdSetActions';
import { AdSetData } from 'v2/features/Planning/PlanContext';
import { ViewMode } from 'v2/features/Planning/PlanPage/ViewModeType';
import { RenderSchedule } from 'v2/features/Planning/PlanPage/reviewAdSets/ViewPlanAdSetsTableCellRenderers';
import { EmptyState } from './EmptyState';
import { AdSetModal } from 'v2/components/AdSetModal/AdSetModal';
import { getRowClassName, styles } from './utils/styles';
import { useSaveAdSet } from 'v2/lib/hooks/useSaveAdSet';
import { batchedPromises } from 'v2/lib/utils/batchedPromises';
import { useDiscounts } from 'v2/features/Planning/useDiscounts';
import { RenderAdSetsStatusChip } from 'v2/features/Planning/TableCellRenderers';
interface AdSetPagination {
  currentPage: number | undefined;
  from: number | undefined;
  lastPage: number | undefined;
  perPage: number | undefined;
  to: number | undefined;
  total: number | undefined;
}
interface BaseAdSetsActionsTableProps {
  adSetsData: Array<AdSet>;
  isLoadingAdSets: boolean;
  adSetPagination: AdSetPagination;
  changeAdSetPagination: (page: number, perPage: PerPageEnum) => void;
  isRetailer: boolean;
  actionTypes: Array<string>;
  refetchAdSets: () => void;
}

export const BaseAdSetsActionsTable: React.FC<BaseAdSetsActionsTableProps> = ({
  adSetsData,
  isLoadingAdSets,
  adSetPagination,
  changeAdSetPagination,
  isRetailer,
  actionTypes,
  refetchAdSets,
}) => {
  const navigate = useNavigate();
  const { saveAdSet } = useSaveAdSet();
  const { syncAdSetsDiscounts } = useDiscounts();

  const [adSetToEdit, setAdSetToEdit] = useState<AdSetData | undefined>(undefined);

  const handleAction = useCallback(
    (action: AdSetActionType, adSet: AdSetData) => {
      switch (action) {
        case 'edit':
          setAdSetToEdit(adSet);
          break;
        case 'view_plan':
          navigate(`/plans-ad-sets/${adSet.plan?.id}`, { state: { viewMode: ViewMode.REVIEWING } });
          break;
        default:
          break;
      }
    },
    [navigate]
  );
  const saveAdSetFromModal = async (adSet: AdSet) => {
    await saveAdSet({ ...adSet, existingAdSet: adSetToEdit });
    await batchedPromises(syncAdSetsDiscounts([adSet]), 1);
    refetchAdSets();
    setAdSetToEdit(undefined);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'statuses',
      headerName: 'Ad set actions',
      width: 250,
      renderCell: (params) => <RenderAdSetsStatusChip {...params} key='dashboard-adset-actions-chips' />,
    },
    {
      field: isRetailer ? 'brand' : 'retailer',
      headerName: isRetailer ? 'Brand' : 'Retailer',
      width: 150,
      renderCell: (params) => <CenteredTableCellContainer>{params.value}</CenteredTableCellContainer>,
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      width: 250,
      renderCell: (params) => <RenderSchedule bundleIds={params.row.bundleIds} />,
    },
    {
      field: 'media-type',
      headerName: 'Media type',
      width: 150,
      renderCell: (params) => <CenteredTableCellContainer>{params.value}</CenteredTableCellContainer>,
    },
    {
      field: 'placement',
      headerName: 'Placement',
      width: 250,
      renderCell: (params) => <CenteredTableCellContainer>{params.value}</CenteredTableCellContainer>,
    },
    {
      field: 'skuCodes',
      headerName: 'SKU code(s)',
      width: 150,
      renderCell: (params) => <SkuCodesCell {...params} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <Box display='flex' justifyContent='flex-end' alignItems='center' width='100%' height='100%'>
          <AdSetActions
            adSet={params.row.adSet}
            viewMode={ViewMode.SEARCH}
            actionCallback={handleAction}
            canApproveOrReject={isRetailer ?? false}
            isDashboardActions={true}
            actionTypeList={['edit', 'view_plan']}
          />
        </Box>
      ),
    },
  ];

  const rows =
    adSetsData?.map((adSet: AdSetData) => ({
      id: adSet.id,
      statuses: {
        status: adSet.status,
        actions: adSet.actions,
      },
      brand: adSet?.plan?.supplier?.name,
      retailer: adSet?.plan?.owner?.name,
      bundleIds: adSet?.bundleIds,
      'media-type': adSet?.mediaSpace?.name,
      placement: adSet.bundleLocationNames,
      adSet: adSet,
    })) || [];

  const dataGridPaginationModel = {
    pageSize: adSetPagination.perPage ?? PerPageEnum.NUMBER_15,
    page: (adSetPagination.currentPage ?? 1) - 1,
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changeAdSetPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      {adSetsData.length === 0 && !isLoadingAdSets ? (
        <EmptyState tab={actionTypes.at(0) ?? ''} />
      ) : (
        <DataGrid
          disableColumnFilter
          disableColumnSorting
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          autoHeight
          paginationMode='server'
          getRowClassName={getRowClassName}
          getRowHeight={() => 'auto'}
          loading={isLoadingAdSets}
          rowCount={adSetPagination.total || 0}
          paginationModel={dataGridPaginationModel}
          onPaginationModelChange={handlePaginationUpdate}
          sx={styles}
        />
      )}
      {adSetToEdit && (
        <AdSetModal
          adSet={adSetToEdit}
          isOpen={!!adSetToEdit}
          isReadOnly={false}
          onClose={() => setAdSetToEdit(undefined)}
          retailerId={adSetToEdit.plan?.owner?.id || ''}
          brandId={adSetToEdit.plan?.supplier?.id || ''}
          saveHandler={saveAdSetFromModal}
        />
      )}
    </Box>
  );
};
