/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Zitcha Ad Management API
 * This is the API endpoint to manage ads.
 * OpenAPI spec version: 1.0.0
 */

export type PlanSearchStatusesItem = (typeof PlanSearchStatusesItem)[keyof typeof PlanSearchStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanSearchStatusesItem = {
  planning: 'planning',
  proposed: 'proposed',
  agreed: 'agreed',
  archived: 'archived',
} as const;
